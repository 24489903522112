import {
  FETCHED_EVENTITEM,
  FETCHED_EVENTITEMS,
  FETCH_EVENTITEM_FAILURE,
  LOADING_EVENT
} from "../Constants/EventsActionTypes";

export const eventsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_EVENTITEM:
      return {
        ...action.payload
      };
    case FETCHED_EVENTITEMS:
      return {
        ...action.payload
      };
    case LOADING_EVENT:
      return {
        loading: true
      };
    case FETCH_EVENTITEM_FAILURE:
      return {
        error: {
          message: action.payload
        }
      };

    default:
      return state;
  }
};
