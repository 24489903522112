import { ApiClientEndPointMethod } from "../EndPoint";
import { USERS_URL } from "../EndPoint/ConstantVariables";

export const resetPasswordEndPoint = payload => {
  if (payload && payload.userEmail !== undefined) {
    const passWordReset = ApiClientEndPointMethod({
      url: `${USERS_URL}Auth/ResetPassword`,
      customBodyOptions: {
        body: JSON.stringify(payload)
      },
      method: "POST"
    });

    return passWordReset.then(psd => {
      if (psd === 1 || psd === 0) {
        return psd;
      }
      return -1;
    });
  }
  return { error: "No Data to Submit" };
};
