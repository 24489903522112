import {
  UPDATE_SP_SERVICE,
  UPDATE_SP_SERVICES
} from "../Constants/ServiceProviderUpdateServicesActionTypes";

export const ServiceProviderUpdateServicesReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_SP_SERVICE:
      return [...state, ...action.payload];
    case UPDATE_SP_SERVICES:
      return [...action.payload];

    default:
      return state;
  }
};
