import React from "react";
import { Field } from "redux-form";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Components
import FormSearchBox from "../General/Form/FormSearchBox";
// styles
import { searchStyle } from "./SearchStyle";

const Search = ({ ...searchBoxProps }) => {
  const { classes, handleKeyUp } = searchBoxProps;

  return (
    <section className={classes.housesSearch}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <Field
          component={FormSearchBox}
          name="global-search"
          inputClasses={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          placeholder="Search..."
          onChange={handleKeyUp}
        />
      </div>
    </section>
  );
};

export default withStyles(searchStyle)(Search);
