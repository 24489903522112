import { emphasize } from "@material-ui/core/styles/colorManipulator";

export const formSelectBoxStyle = theme => ({
  input: {
    display: "flex",
    padding: 5,
    height: "100%"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    paddingLeft: "7px"
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    height: 24,
    borderRadius: 2,
    "& svg": {
      fontSize: 15
    }
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 10,
    fontSize: 15
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    boxShadow: "none",
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(1) * 2
  },
  wrapper: {
    "& fieldset": {
      borderRadius: "5px",
      minHeight: "52px"
    }
  }
});
