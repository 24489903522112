import { FETCH_CITY, FETCH_CITIES } from "../Constants/CitiesActionTypes";

export const fetchCity = payload => {
  return {
    type: FETCH_CITY,
    payload
  };
};
export const fetchCities = payload => {
  return {
    type: FETCH_CITIES,
    payload
  };
};
