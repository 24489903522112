import { ApiClientEndPointMethod } from "../EndPoint/index";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const serviceProviderListEndPoint = payload => {
  const serviceProvidersUri =
    payload === undefined
      ? "ServiceProviders/ServicesServiceProviders/-99"
      : `ServiceProviders/ServicesServiceProviders/${payload}`;

  const serviceProvidersData = ApiClientEndPointMethod({
    url: SERVICE_PROVIDERS_URL + serviceProvidersUri
  });

  try {
    return serviceProvidersData.then(serviceProviders => {
      if (
        serviceProviders &&
        serviceProviders.servicesServicerProviders &&
        serviceProviders.servicesServicerProviders.length > 0
      ) {
        return {
          data: serviceProviders.servicesServicerProviders.map(
            ({
              serviceId,
              serviceProviderDescription,
              serviceProviderId,
              serviceProviderIdentityId,
              serviceProviderName,
              serviceProviderStatus
            }) => {
              return {
                serviceProviderId,
                serviceId,
                serviceProviderIdentityId,
                serviceProviderName,
                serviceProviderDescription,
                serviceProviderStatus
              };
            }
          )
        };
      }
      if (
        serviceProviders &&
        serviceProviders.serviceProviders &&
        serviceProviders.serviceProviders.length > 0
      ) {
        return {
          data: serviceProviders.serviceProviders.map(
            ({
              serviceId,
              serviceProviderDescription,
              serviceProviderId,
              serviceProviderIdentityId,
              serviceProviderName,
              serviceProviderStatus
            }) => {
              return {
                serviceProviderId,
                serviceId,
                serviceProviderIdentityId,
                serviceProviderName,
                serviceProviderDescription,
                serviceProviderStatus
              };
            }
          )
        };
      }

      return { error: "No records found" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
