import { ApiClientEndPointMethod } from "../EndPoint";
import { MESSAGES_URL } from "../EndPoint/ConstantVariables";
import { messagesConversitionListEndPoint } from "./MessagesConversationList";

export const messageAddEndPoint = payload => {
  if (payload !== undefined) {
    const addMessageData = ApiClientEndPointMethod({
      url: `${MESSAGES_URL}Messages`,
      customBodyOptions: {
        body: JSON.stringify(payload)
      },
      method: "POST",
      authorize: true
    });

    try {
      return addMessageData.then(messagesData => {
        if (
          messagesData &&
          messagesData.messageDetail &&
          messagesData.messageDetail !== null
        ) {
          return messagesConversitionListEndPoint({
            targetUserId: payload.UserId,
            targetConverseeId: payload.RecipientId
          });
        }

        return { error: "Error Fetching Data" };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "Error Fetching Message Conversation" };
  }
};
