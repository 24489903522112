import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { fetchEventServices } from "../../Actions/EventServiceActions";
import { fetchCities } from "../../Actions/CitiesActions";
import { fetchUOMs } from "../../Actions/UOMsActions";
import { fetchCountries } from "../../Actions/CountriesActions";
import {
  updateServiceProviderProfile,
  fetchServiceProvider
} from "../../Actions/ServiceProviderActions";
// import { updateServices } from "../../Actions/ServiceProviderUpdateServicesActions";

// Containers
import BaseRedirectContainer from "../BaseRedirectContainer";

// Components
import Loader from "../../Components/General/Loader";
import ServiceProviderBasicDetailsUpdate from "../../Components/ServiceProviders/ServiceProviderBasicDetailsUpdate";

export const getCountryInitialValues = countries => {
  let returnItem = {};
  if (countries && countries.data) {
    countries.data.forEach(countryItem => {
      if (countryItem.countryName.toUpperCase().includes("KENYA")) {
        returnItem = {
          value: countryItem.id,
          label: countryItem.countryName,
          isSelected: true
        };
      }
    });
  }
  return returnItem;
};

export const getSPNameInitialValues = authDetails => {
  let returnName;
  if (authDetails && authDetails.userDetails) {
    returnName = `${authDetails.userDetails.firstName} ${
      authDetails.userDetails.lastName
    }`;
  }
  return returnName;
};

class ServiceProviderBasicDetailsUpdateContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.state = {
      // checkedEventServices: []
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount = () => {
    const { dispatch, authState } = this.props;
    const targetCountryId = 1;

    dispatch(fetchEventServices());
    dispatch(fetchCities({ targetCountryId }));
    dispatch(fetchUOMs());
    dispatch(fetchCountries());

    if (authState && authState.userDetails !== undefined) {
      dispatch(
        fetchServiceProvider({
          id: -99,
          serviceProviderIdentityId: authState.userDetails.userId
        })
      );
    }
  };

  handleFormSubmit = values => {
    const { dispatch, authState } = this.props;

    const {
      eventService,
      selectCity,
      selectCountry,
      serviceChargeUOM,
      serviceCharge,
      specificLocation,
      serviceProviderName,
      serviceProviderDescription
    } = values;

    dispatch(
      updateServiceProviderProfile({
        serviceProviderIdentityId: authState.userDetails.userId,
        EventServiceId: eventService.value,
        CityId: selectCity.value,
        CountryId: selectCountry.value,
        ServiceChargeUOMID: serviceChargeUOM.value,
        ServiceCharge: serviceCharge,
        SpecificLocation: specificLocation,
        ServiceProviderName: serviceProviderName,
        ServiceProviderDescription: serviceProviderDescription
      })
    );
  };

  render = () => {
    const {
      eventServices,
      uoms,
      countries,
      cities,
      authState,
      location,
      handleSubmit,
      serviceProvidersState
    } = this.props;

    const { redirect } = this.state;

    if (authState && authState.userDetails === undefined) {
      return <Redirect to={{ pathname: "/sign-in", state: location }} />;
    }

    if (
      serviceProvidersState &&
      serviceProvidersState.data &&
      serviceProvidersState.data.length === 1 &&
      serviceProvidersState.data[0].isBasicDetailsAdded &&
      redirect
    ) {
      return (
        <Redirect
          to={{
            pathname: `/service-providers/summary/${
              serviceProvidersState.data[0].id
            }`,
            state: location
          }}
        />
      );
    }

    return (
      <>
        {eventServices && countries && eventServices.data && countries.data && (
          <ServiceProviderBasicDetailsUpdate
            eventServices={eventServices.data}
            uoms={uoms}
            countries={countries}
            cities={cities}
            handleFormSubmit={handleSubmit(this.handleFormSubmit)}
            serviceProvidersState={serviceProvidersState}
          />
        )}

        {eventServices &&
          countries &&
          (!eventServices.data || !countries.data) && <Loader />}
      </>
    );
  };
}

ServiceProviderBasicDetailsUpdateContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventServices: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  countries: PropTypes.object.isRequired,
  cities: PropTypes.object.isRequired,
  uoms: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  serviceProvidersState: PropTypes.object.isRequired
};

const mapStateToProps = ({
  eventServicesState,
  countriesState,
  citiesState,
  uomsState,
  authState,
  serviceProvidersState
}) => {
  return {
    eventServices: eventServicesState,
    countries: countriesState,
    cities: citiesState,
    uoms: uomsState,
    authState,
    serviceProvidersState,
    initialValues: {
      selectCountry: getCountryInitialValues(countriesState),
      serviceProviderName: getSPNameInitialValues(authState)
    },
    itemDetail:
      serviceProvidersState && serviceProvidersState.data
        ? { updatedKey: 1 }
        : {}
  };
};

const ServiceProviderBasicDetailsUpdateFormed = reduxForm({
  form: "spDetailsUpdate",
  enableReinitialize: true
})(ServiceProviderBasicDetailsUpdateContainer);

export default connect(mapStateToProps)(
  ServiceProviderBasicDetailsUpdateFormed
);
