import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckCircle from "@material-ui/icons/CheckCircle";

// Custom Components
import GridContainer from "../General/GridContainer";

// styles
import { confirmEmailstyle } from "./AuthStyle";

const ConfirmEmail = ({ ...confirmEmailProps }) => {
  const { classes, confirmEmailStatus } = confirmEmailProps;

  const confirmEmailMessage =
    confirmEmailStatus === 0 ? (
      <Typography variant="h6">
        A verification link has been sent to your email account. Kindly check.
      </Typography>
    ) : (
      <Typography variant="h6">
        <CheckCircle className={classes.successIcon} />
        Your registration has been confirmed.
      </Typography>
    );

  return (
    <>
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.confirmEmailHeight}
      >
        <Grid item md={8} xs={12} sm={12}>
          <Card className={classes.confirmEmailCard}>
            <CardContent className={classes.confirmEmailContent}>
              <div>{confirmEmailMessage}</div>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(confirmEmailstyle)(ConfirmEmail);
