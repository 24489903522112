/* global  fetch,Request, localStorage */
import { MY_TOKEN_KEY } from "./ConstantVariables";

export const getAuthToken = () => localStorage.getItem(MY_TOKEN_KEY);

export function ApiClientEndPointMethod({
  customHeaders = {},
  url = "",
  method = "GET",
  customBodyOptions = {},
  authorize = false
}) {
  let requestHeaders = {
    Accept: "application/json"
  };

  if (authorize) {
    requestHeaders = {
      ...requestHeaders,
      ...{ Authorization: `Bearer ${getAuthToken()}` }
    };
  }

  // set request header to application/json when it is not a form
  if (
    customBodyOptions &&
    customBodyOptions.body &&
    typeof customBodyOptions.body === "string"
  ) {
    requestHeaders = {
      ...requestHeaders,
      ...{ "Content-Type": "application/json" }
    };
  }

  const requestInit = {
    ...{
      method,
      headers: { ...requestHeaders, ...customHeaders },
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      redirect: "follow",
      referrer: "no-referrer"
    },
    ...customBodyOptions
  };

  const apiClientRequest = new Request(url, requestInit);

  return fetch(apiClientRequest)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      }
      return Promise.reject(new Error(response.statusText));
    })
    .then(response => response.json())
    .then(responseData => responseData)
    .catch(error => ({ Error: error.message }));
}
