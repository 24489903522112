import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

// Custom Components
import { eventsReducer } from "./EventsReducer";
import { authReducer } from "./AuthReducer";
import {
  serviceProvidersReducer,
  serviceProviderExtendedDetailsReducer
} from "./ServiceProvidersReducer";
import { eventServicesReducer } from "./EventServicesReducer";
import { userProfileReducer } from "./UserProfileReducer";
import { ServiceProviderUpdateServicesReducer } from "./ServiceProviderUpdateServicesReducer";
import { countriesReducer } from "./CountriesReducer";
import { uomsReducer } from "./UOMsReducer";
import { citiesReducer } from "./CitiesReducer";
import { confirmEmailReducer } from "./ConfirmEmailReducer";
import { messagesSummaryReducer } from "./MessagesSummaryReducer";
import { messagesConversationReducer } from "./MessagesConversationReducer";
import { resetPasswordReducer } from "./ResetPasswordReducer";
import { updatePasswordReducer } from "./UpdatePasswordReducer";

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
  form,
  eventsState: eventsReducer,
  authState: authReducer,
  serviceProvidersState: serviceProvidersReducer,
  serviceProviderExtendedDetailsState: serviceProviderExtendedDetailsReducer,
  ServiceProviderUpdateServicesState: ServiceProviderUpdateServicesReducer,
  eventServicesState: eventServicesReducer,
  userProfileState: userProfileReducer,
  countriesState: countriesReducer,
  citiesState: citiesReducer,
  uomsState: uomsReducer,
  confirmEmailState: confirmEmailReducer,
  messagesSummaryState: messagesSummaryReducer,
  messagesConversationState: messagesConversationReducer,
  resetPasswordState: resetPasswordReducer,
  updatePasswordState: updatePasswordReducer
});

export default rootReducer;
