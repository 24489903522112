export const profileSettingsStyle = theme => ({
  cardWrapper: {
    boxShadow: "none",
    border: "1px solid #e7e5f3ed",
    paddingLeft: "15px",
    marginBottom: "10px"
  },
  gridItemCustomStyle: {
    padding: "0px 5px"
  },
  formGroup: {
    padding: "10px 0px",
    color: "#222",
    "&>fieldset": {
      [theme.breakpoints.up("sm")]: {
        width: "80%"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    " button": {
      marginTop: "10px"
    }
  }
});
