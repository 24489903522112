import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

// Custom Components
import GridContainer from "../GridContainer";
// Style
import { errorStyle } from "./ErrorStyle";

const Error = ({ ...errorProps }) => {
  const { classes, error } = errorProps;

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <Grid item md={12} xs={12} sm={12} className={classes.errorWrapper}>
        <div className={classes.errorIcon}>
          <ErrorOutline />
        </div>
        <Typography
          component="h1"
          gutterBottom
          align="center"
          className={classes.errorMessage}
        >
          {error && error.message}
        </Typography>
      </Grid>
    </GridContainer>
  );
};

export default withStyles(errorStyle)(Error);
