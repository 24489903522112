export const serviceServiceProviderStyle = {
  selectedHeader: {
    textAlign: "center",
    fontWeight: "bold"
  },
  gridItemCustomStyle: {
    padding: "0px 5px"
  },
  housesServiceProvidersList: {
    padding: "0px 0px"
  },
  addToCompareContent: {
    border: "1px solid #d9d8e073",
    borderRadius: "4px",
    borderBottomRightRadius: " 0px",
    borderBottomLeftRadius: "0px",
    padding: "0px 0px"
  },
  addToCompareHeader: {
    textAlign: "center",
    padding: "0px 0px 5px",
    "& button": {
      textTransform: "capitalize",
      backgroundColor: "#3f51b514",
      "& svg": {
        fontSize: "15px",
        marginRight: "5px"
      }
    }
  }
};
