import {
  FETCH_COUNTRIES,
  FETCH_COUNTRY
} from "../Constants/CountriesActionTypes";

export const fetchCountry = payload => {
  return {
    type: FETCH_COUNTRY,
    payload
  };
};

export const fetchCountries = payload => {
  return {
    type: FETCH_COUNTRIES,
    payload
  };
};
