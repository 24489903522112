export const FETCH_SERVICE_PROVIDER = "FETCH_SERVICE_PROVIDER";
export const FETCH_SERVICE_PROVIDERS = "FETCH_SERVICE_PROVIDERS";
export const FETCH_SERVICE_PROVIDER_WRONG_URI =
  "FETCH_SERVICE_PROVIDER_WRONG_URI";
export const FETCH_SERVICE_PROVIDER_FAILURE = "FETCH_SERVICE_PROVIDER_FAILURE";

export const FETCHED_SERVICE_PROVIDER = "FETCHED_SERVICE_PROVIDER";
export const FETCHED_SERVICE_PROVIDERS = "FETCHED_SERVICE_PROVIDERS";

export const LOADING_SERVICE_PROVIDER = "LOADING_SERVICE_PROVIDER";

export const UPDATE_SP_PROFILE_DETAILS = "UPDATE_SP_PROFILE_DETAILS";

export const UPDATED_SP_PROFILE_DETAILS = "UPDATED_SP_PROFILE_DETAILS";

export const UPDATE_SP_PROFILE_DETAILS_FAILURE =
  "UPDATE_SP_PROFILE_DETAILS_FAILURE";

export const FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS =
  "FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS";

export const FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS_FAILURE =
  "FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS_FAILURE";

export const FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS =
  "FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS";

export const UPDATTING_SP_PROFILE_DETAILS = "UPDATTING_SP_PROFILE_DETAILS";
