import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import GridContainer from "../GridContainer";

// Styles
import { accountProfileStyle } from "./AccountProfileStyle";

// image
const defaultPersonImage = require("../../../Media/images/profile/person.png");

const AccountProfile = ({ ...accountProfileProps }) => {
  const {
    classes,
    profileDetails,
    editEnabled,
    handleResetPassword,
    resetPasswordStatus
  } = accountProfileProps;

  const profileImage =
    profileDetails.profileImage !== undefined
      ? profileDetails.profileImage
      : defaultPersonImage;

  return (
    <Card className={classes.cardWrapper}>
      <CardContent className={classes.detailBody}>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h5">
              {`${profileDetails.name}`}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              Nairobi, Kenya
            </Typography>
          </div>
          <Avatar className={classes.avatar} src={profileImage} />
        </div>
      </CardContent>
      {editEnabled && (
        <CardActions>
          <GridContainer>
            <Grid item md={12}>
              <Button
                className={classes.uploadButton}
                color="primary"
                variant="text"
              >
                Upload picture
              </Button>
              <Button
                variant="text"
                onClick={() => handleResetPassword()}
                disabled={resetPasswordStatus && resetPasswordStatus.loading}
              >
                {resetPasswordStatus && resetPasswordStatus.loading ? (
                  <CircularProgress size={26} />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </Grid>

            {resetPasswordStatus && resetPasswordStatus.failed && (
              <Grid item md={12} className={classes.error}>
                An error occured!
              </Grid>
            )}
            {resetPasswordStatus && resetPasswordStatus.success && (
              <Grid item md={12} className={classes.success}>
                Kindly check your email for a reset link
              </Grid>
            )}
          </GridContainer>
        </CardActions>
      )}
    </Card>
  );
};

export default withStyles(accountProfileStyle)(AccountProfile);
