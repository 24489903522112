import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

// containers
import BaseRedirectContainer from "../BaseRedirectContainer";

// Actions
import { submitSignUp } from "../../Actions/AuthActions";
// Custom Components
import SignUp from "../../Components/Auth/SignUp";

class SignUpContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.state = {
      tNCMOdalOpen: false
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit = values => {
    const { dispatch } = this.props;
    dispatch(submitSignUp({ ...values, ...{ isSignUP: true } }));
  };

  handleModal = event => {
    event.preventDefault();
    this.setState(prevState => ({
      tNCMOdalOpen: !prevState.tNCMOdalOpen
    }));
  };

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      dirty,
      itemDetail,
      location
    } = this.props;

    const { redirect, tNCMOdalOpen } = this.state;

    // Redirect to Details after update
    if (
      itemDetail &&
      itemDetail.updatedKey &&
      itemDetail.updatedKey !== undefined &&
      redirect
    ) {
      const redirectUri = `/email-confirmation/${
        itemDetail.userDetails.userId
      }`;

      return <Redirect to={{ pathname: redirectUri, state: location }} />;
    }

    return (
      <SignUp
        pristine={pristine}
        invalid={invalid}
        formIsDirty={dirty}
        authState={itemDetail}
        handleModal={this.handleModal}
        tNCMOdalOpen={tNCMOdalOpen}
        handleFormSubmit={handleSubmit(this.handleFormSubmit)}
      />
    );
  }
}

SignUpContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemDetail: PropTypes.object.isRequired
};

const mapStateToProps = ({ authState }) => ({
  itemDetail: authState
});

// Compose to redux form
const SignUpFormed = reduxForm({ form: "sign-up" })(SignUpContainer);

export default connect(mapStateToProps)(SignUpFormed);
