import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

// Componenets
import SubHeader from "../../General/SubHeader";
import GridContainer from "../../General/GridContainer";

import { serviceServiceProvidersSubHeaderStyle } from "./ServiceServiceProvidersSubHeaderStyle";

const defaultServiceImage = require("../../../Media/images/events/default.png");

const ServiceServiceProvidersSubHeader = ({
  ...serviceServiceProvidersSubHeaderProps
}) => {
  const {
    classes,
    serviceImage,
    serviceName
  } = serviceServiceProvidersSubHeaderProps;

  const eventServiceImage =
    serviceImage !== "" ? (
      <img src={serviceImage} alt={serviceName} width="25px" />
    ) : (
      <img src={defaultServiceImage} alt={serviceName} width="25px" />
    );

  return (
    <SubHeader>
      <GridContainer
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.subHeaderWrapper}
      >
        <Grid item xs={12} sm={6} md={7}>
          <Typography
            component="h1"
            gutterBottom
            align="left"
            className={classes.headerText}
          >
            <span className={classes.eventIcon}>{eventServiceImage}</span>
            <span>{serviceName}</span>
          </Typography>
          <Typography
            variant="caption"
            gutterBottom
            align="left"
            className={classes.subHeaderText}
          >
            {`Services Providers for ${serviceName}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5} />
      </GridContainer>
    </SubHeader>
  );
};

export default withStyles(serviceServiceProvidersSubHeaderStyle)(
  ServiceServiceProvidersSubHeader
);
