import {
  FETCH_MESSAGE,
  FETCH_MESSAGES,
  FETCH_CONVERSATION,
  FETCH_CONVERSATIONS,
  ADD_MESSAGE
} from "../Constants/MessagesActionTypes";

export const fetchMessage = payload => {
  return {
    type: FETCH_MESSAGE,
    payload
  };
};

export const fetchMessages = payload => {
  return {
    type: FETCH_MESSAGES,
    payload
  };
};

export const addMessage = payload => {
  return {
    type: ADD_MESSAGE,
    payload
  };
};

export const fetchConversation = payload => {
  return {
    type: FETCH_CONVERSATION,
    payload
  };
};

export const fetchConversations = payload => {
  return {
    type: FETCH_CONVERSATIONS,
    payload
  };
};
