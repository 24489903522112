import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";

// Componets
import GridContainer from "../../General/GridContainer";
import MessageTextBox from "../MessageTextBox";

// Style
import { messageConversationStyle } from "./MessageConversationStyle";

const converseeAvatar = require("../../../Media/images/profile/person.png");

const MessageConversation = ({ ...messageConversationProps }) => {
  const {
    classes,
    activeConvoId,
    handleFormChange,
    handleFormSubmit,
    messagesConversation,
    pristine
  } = messageConversationProps;

  const conversationList = [];

  const attachClass = (side, index) => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === messagesConversation.length - 1) {
      return classes[`${side}Last`];
    }
    return "";
  };

  const convonoContent = (
    <div>
      <Typography align="center">No Message Selected</Typography>
    </div>
  );

  let previousMessageType = "";

  messagesConversation.forEach(messageElement => {
    const messageType =
      messageElement.senderId === activeConvoId ? "from" : "to";

    const side = messageType.includes("to") ? "right" : "left";

    const avatarImage =
      messageType.includes("from") && !previousMessageType.includes("from") ? (
        <Avatar className={classes.avatar} src={converseeAvatar} />
      ) : (
        ""
      );
    conversationList.push(
      <GridContainer key={messageElement.messageId}>
        <Grid item xs={1}>
          {avatarImage}{" "}
        </Grid>
        <Grid item xs={9}>
          <div className={classes[`${side}Row`]}>
            <Typography
              align="left"
              className={`${classes.msg} ${classes[side]} ${attachClass(
                side,
                messageElement.messageId - 1
              )}`}
            >
              {messageElement.message}
            </Typography>
          </div>
        </Grid>
      </GridContainer>
    );

    previousMessageType = messageType;
  });

  return (
    <Grid item md={8} sm={8} xs={12}>
      <Card className={classes.te}>
        <CardContent>
          {!activeConvoId.includes("all") && conversationList}
          {activeConvoId.includes("all") && convonoContent}
          <MessageTextBox
            handleFormChange={handleFormChange}
            handleFormSubmit={handleFormSubmit}
            pristine={pristine}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withStyles(messageConversationStyle)(MessageConversation);
