import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Star from "@material-ui/icons/Star";
import StarHalf from "@material-ui/icons/StarHalf";
import StarBorder from "@material-ui/icons/StarBorder";
import IconButton from "@material-ui/core/IconButton";
import CompareArrow from "@material-ui/icons/Compare";
import CheckIcon from "@material-ui/icons/Favorite";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

// Helpers
import { convertNumberToRating } from "../../../Helpers/General";
// Style
import { serviceProviderMiniDetailStyle } from "./ServiceProviderMiniDetailStyle";

const ServiceProviderMiniDetail = ({ ...serviceProviderMiniDetailProps }) => {
  const {
    classes,
    contentOptions,
    serviceProviderImage,
    serviceProviderName,
    serviceProviderDescription,
    serviceProviderRating,
    serviceProviderReviews,
    serviceProviderBackroundImage,
    handleAddToCompare,
    handleAddToFavorite,
    serviceProviderID,
    SelectElement,
    checkedCompareSPs,
    checkedFavoriteSPs,
    showEventService
  } = serviceProviderMiniDetailProps;

  const checkedCompareSpsClasses =
    checkedCompareSPs !== undefined &&
    checkedCompareSPs.includes(`${serviceProviderID}`)
      ? classes.selectedButton
      : classes.selectButton;

  const checkedFavoriteSpsClasses =
    checkedFavoriteSPs !== undefined &&
    checkedFavoriteSPs.includes(`${serviceProviderID}`)
      ? classes.selectedButton
      : classes.selectButton;

  const avatarContent =
    serviceProviderImage !== "" && serviceProviderImage !== undefined ? (
      <Avatar alt={serviceProviderName} src={serviceProviderImage} />
    ) : (
      <Avatar>
        {serviceProviderName
          .trim()
          .substring(0, 1)
          .toUpperCase()}
      </Avatar>
    );

  let profileDescription =
    serviceProviderDescription !== undefined
      ? serviceProviderDescription.substring(
          0,
          contentOptions.descriptionLength
        )
      : "";

  profileDescription =
    `${serviceProviderDescription}`.length > contentOptions.descriptionLength
      ? `${profileDescription}....`
      : profileDescription;

  const profileRating = convertNumberToRating(serviceProviderRating, {
    starFull: Star,
    starHalf: StarHalf,
    starEmpty: StarBorder
  });

  return (
    <Card className={classes.cardWrapper}>
      <CardMedia
        component="img"
        title="Contemplative Reptile"
        image={serviceProviderBackroundImage}
        className={classes.detailImage}
      />
      <CardContent className={classes.detailBody}>
        <div className={classes.detailAvatar}>{avatarContent}</div>
        <Typography
          component="h6"
          gutterBottom
          align="left"
          className={classes.profileName}
        >
          <Link to={`/service-providers/summary/${serviceProviderID}`}>
            {serviceProviderName}
          </Link>
        </Typography>
        <Typography variant="caption" className={classes.detailDescription}>
          <Link to={`/service-providers/summary/${serviceProviderID}`}>
            {profileDescription}
          </Link>
        </Typography>
        <Typography component="h6" gutterBottom align="left">
          <div className={classes.ratingWrapper}>
            {profileRating}
            {contentOptions.showReviews && (
              <span>{serviceProviderReviews}</span>
            )}
          </div>
        </Typography>
      </CardContent>
      {showEventService && handleAddToFavorite !== undefined && (
        <CardActions className={classes.detailActions}>
          <SelectElement
            onSelect={handleAddToFavorite({
              serviceProviderID,
              serviceProviderImage,
              serviceProviderName,
              serviceProviderDescription,
              serviceProviderRating,
              serviceProviderReviews,
              serviceProviderBackroundImage
            })}
            key={1}
          >
            <IconButton
              aria-label="Add to Favorite"
              className={checkedFavoriteSpsClasses}
            >
              <CheckIcon />
            </IconButton>
          </SelectElement>
          <SelectElement
            key={2}
            onSelect={handleAddToCompare({
              serviceProviderID,
              serviceProviderImage,
              serviceProviderName,
              serviceProviderDescription,
              serviceProviderRating,
              serviceProviderReviews,
              serviceProviderBackroundImage
            })}
          >
            <IconButton
              aria-label="Add to Compare"
              className={checkedCompareSpsClasses}
            >
              <CompareArrow />
            </IconButton>
          </SelectElement>
        </CardActions>
      )}
    </Card>
  );
};

ServiceProviderMiniDetail.defaultProps = {
  contentOptions: {
    descriptionLength: 50,
    selected: false,
    addedToCompare: false,
    showReviews: false
  }
};

export default withStyles(serviceProviderMiniDetailStyle)(
  ServiceProviderMiniDetail
);
