import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions
import { fetchUser } from "../../Actions/UserProfileActions";

// Components
import ProfileSummary from "../../Components/Profile/ProfileSummary";
import Loader from "../../Components/General/Loader";

class ProfileSummaryContainer extends Component {
  componentDidMount = () => {
    const { userProfileId, dispatch } = this.props;

    dispatch(fetchUser(userProfileId));
  };

  render = () => {
    const { userProfileState, authState } = this.props;

    return (
      <>
        {userProfileState &&
          userProfileState.data &&
          authState &&
          authState.userDetails && (
            <ProfileSummary
              userProfileDetails={userProfileState.data}
              editEnabled={
                userProfileState.data[0].userId === authState.userDetails.userId
              }
            />
          )}
        {userProfileState && !userProfileState.data && <Loader />}
      </>
    );
  };
}

ProfileSummaryContainer.propTypes = {
  userProfileId: PropTypes.string.isRequired,
  userProfileState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({ authState, userProfileState }) => ({
  authState,
  userProfileState
});

export default connect(mapStateToProps)(ProfileSummaryContainer);
