import React from "react";
import { Field } from "redux-form";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Phone from "@material-ui/icons/Phone";
import Person from "@material-ui/icons/Person";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CheckRounded from "@material-ui/icons/CheckRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

// Custom Components
import SubHeader from "../General/SubHeader";
import GridContainer from "../General/GridContainer";
import FormCheckBox from "../General/Form/FormCheckBox";
import FormInputBox from "../General/Form/FormInputBox";
import FormRadioGroup from "../General/Form/FormRadioGroup";
import ContentModal from "../General/ContentModal";
import TNC from "./TNC";

import { required, passwordsMatch } from "../General/Form/Helpers";

// Style
import { authStyle } from "./AuthStyle";

const SignUp = ({ ...signUpProps }) => {
  const {
    classes,
    handleFormSubmit,
    formIsDirty,
    authState,
    handleModal,
    tNCMOdalOpen
  } = signUpProps;

  const termsAndConditions = (
    <span>
      I have read and understood the
      <Button onClick={handleModal} className={classes.tNC}>
        terms and conditions
      </Button>
    </span>
  );

  return (
    <>
      <SubHeader className={classes.authSubHeaderWrapper}>
        <div />
      </SubHeader>
      <GridContainer direction="row" justify="center" alignItems="center">
        <Grid item md={6} xs={12} sm={12}>
          <Card className={classes.authCard}>
            <CardHeader
              title="Welcome to Evento"
              className={classes.authCardHeader}
            />
            <CardContent className={classes.authCardContent}>
              <form onSubmit={handleFormSubmit}>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="firstName"
                      type="text"
                      component={FormInputBox}
                      label="First Name *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>

                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="lastName"
                      type="text"
                      component={FormInputBox}
                      label="Last Name *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Person />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>

                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Gender *</FormLabel>
                    <Field
                      name="sex"
                      component={FormRadioGroup}
                      row
                      className={classes.radioGroup}
                      validate={[required]}
                      formIsDirty={formIsDirty}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio color="primary" />}
                        label="Female"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio color="primary" />}
                        label="Male"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio color="primary" />}
                        label="Other"
                        labelPlacement="end"
                      />
                    </Field>
                  </FormControl>
                </div>

                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="phoneNumber"
                      type="text"
                      component={FormInputBox}
                      label="Phone Number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Phone />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="userEmail"
                      type="email"
                      component={FormInputBox}
                      label="Email Address *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="userPassWord"
                      type="password"
                      component={FormInputBox}
                      label="Password *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="confirmPassword"
                      type="password"
                      component={FormInputBox}
                      label="Confirm Password *"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required, passwordsMatch]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <span className={classes.authHouseCheckBox}>
                    <Field
                      name="isServiceProvider"
                      component={FormCheckBox}
                      label={<>I am a service provider</>}
                    />
                  </span>
                </div>
                <div className={classes.authFormGroup}>
                  <span className={classes.authHouseCheckBox}>
                    <Field
                      name="readTerms"
                      component={FormCheckBox}
                      label={termsAndConditions}
                      validate={[required]}
                    />
                  </span>
                </div>

                <div className={classes.authFormGroup}>
                  {authState && !authState.isSignInModule && (
                    <Typography
                      variant="subtitle2"
                      className={classes.authCaption}
                    >
                      {authState.error && (
                        <div className={classes.authFailure}>
                          <CloseRounded />{" "}
                          {authState.error.replace("name", "email")}
                        </div>
                      )}
                      {authState &&
                        authState.userToken &&
                        authState.userToken.accessToken &&
                        authState.userToken.accessToken.token && (
                          <div className={classes.authSuccess}>
                            <CheckRounded />
                            {
                              "Sign up successful! Redirecting to profile page ..."
                            }
                          </div>
                        )}
                    </Typography>
                  )}
                </div>

                <div className={classes.authFormGroup}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    disabled={authState && authState.loading}
                    type="submit"
                    className={classes.margin}
                  >
                    {authState && authState.loading === undefined && "Submit"}
                    {authState && authState.loading && (
                      <CircularProgress size={26} />
                    )}
                  </Button>
                </div>
                <div className={classes.authFormGroup}>
                  <Typography variant="caption" className={classes.authCaption}>
                    Already registered?
                    <Link to="/sign-in"> Click here to sign in. </Link>
                  </Typography>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
      <ContentModal
        isOpen={tNCMOdalOpen}
        handleClose={handleModal}
        modalContent={<TNC />}
        modalTitle=""
      />
    </>
  );
};

export default withStyles(authStyle)(SignUp);
