import React from "react";
import Link from "react-router-dom/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import Typography from "@material-ui/core/Typography";

// Custom Components
import CustomChip from "../../General/CustomChip";

// styles
import { eventItemStyle } from "./EventItemStyle";

// image
const defaultEventImage = require("../../../Media/images/events/default.png");

const EventItem = ({ ...eventItemProps }) => {
  const {
    classes,
    eventId,
    eventName,
    eventItemServices,
    eventImage
  } = eventItemProps;

  const eventItemServicesList = [];

  eventItemServices.forEach(element => {
    eventItemServicesList.push(
      <CustomChip
        key={element.eventServiceID}
        content={element.eventServiceName}
        link={`/event-services/${element.eventServiceID}/service-providers`}
      />
    );
  });

  const eventItemImage =
    eventImage !== "" ? (
      <img src={eventImage} alt={eventName} width="50%" />
    ) : (
      <img src={defaultEventImage} alt={eventName} width="50%" />
    );

  const eventServicesLink = `/event-items/${eventId}/event-services`;

  const eventItemCard = (
    <React.Fragment>
      <div className={classes.housesEventImage}>
        <Link to={eventServicesLink}>{eventItemImage}</Link>
      </div>
      <Typography
        component="h1"
        gutterBottom
        align="center"
        className={classes.subHeaderText}
      >
        <Link to={eventServicesLink} className={classes.linkStyle}>
          {eventName}
        </Link>
      </Typography>

      <Hidden smDown implementation="css">
        <div className={classes.servicesList}>{eventItemServicesList}</div>
      </Hidden>
    </React.Fragment>
  );

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardBody}>{eventItemCard}</CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          color="primary"
          className={classes.cardActionsFarLeftIcons}
          component={Link}
          to={eventServicesLink}
        >
          View
          <ArrowForward />
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(eventItemStyle)(EventItem);
