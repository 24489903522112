import { ApiClientEndPointMethod } from "../EndPoint";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const countriesEndPoint = payload => {
  let countriesUri = "Countries";

  countriesUri =
    payload === undefined ? countriesUri : `${countriesUri}/${payload}`;

  const countries = ApiClientEndPointMethod({
    url: SERVICE_PROVIDERS_URL + countriesUri
  });

  try {
    return countries.then(countriesData => {
      if (countriesData && countriesData.countries) {
        return {
          data: countriesData.countries.map(({ id, countryName }) => ({
            id,
            countryName
          })),
          createEnabled: countriesData.createEnabled
        };
      }

      if (
        countriesData &&
        countriesData.countryDetails &&
        countriesData.requestStatus === 1
      ) {
        return { data: { ...countriesData.countryDetails } };
      }

      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
