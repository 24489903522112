import React from "react";
import Card from "@material-ui/core/Card";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Link from "react-router-dom/Link";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Check from "@material-ui/icons/Check";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import { eventServiceStyle } from "./EventServiceStyle";

const EventService = ({ ...eventServiceProps }) => {
  const {
    classes,
    imageHeight,
    serviceID,
    serviceName,
    serviceImage,
    serviceColor,
    serviceProvidersCount,
    SelectElement,
    handleSelectElement,
    selectedEventServices,
    showDescription,
    serviceDescription
  } = eventServiceProps;

  const selectButtonClasses =
    selectedEventServices !== undefined &&
    selectedEventServices.indexOf(serviceID) > -1
      ? classes.selectedButton
      : classes.selectButton;

  return (
    <Card className={classes.cardWrapper}>
      <div
        className={classes.imageWrapper}
        style={{
          background: serviceColor
        }}
      >
        <CardMedia
          component="img"
          image={serviceImage}
          className={classes.media}
          height={imageHeight === undefined ? "100" : `${imageHeight}`}
        />
      </div>
      <CardContent className={classes.customCardBody}>
        <Typography
          variant="h6"
          gutterBottom
          align="left"
          className={classes.serviceName}
        >
          <Link to={`/event-services/${serviceID}/service-providers`}>
            {serviceName}
          </Link>
        </Typography>
        {showDescription && (
          <Typography variant="caption" align="left" gutterBottom>
            {serviceDescription}
          </Typography>
        )}
      </CardContent>
      {!showDescription && (
        <CardActions className={classes.cardActions}>
          <Tooltip title="Service Providers" placement="top">
            <Button
              aria-label="Service Providers"
              size="small"
              color="primary"
              className={classes.cardActionsLeft}
              component={Link}
              to={`/event-services/${serviceID}/service-providers`}
            >
              <PeopleOutline /> {serviceProvidersCount}
            </Button>
          </Tooltip>
          <Tooltip title="Check this" placement="top">
            <div className={classes.cardActionsFarLeftIcons}>
              {handleSelectElement !== undefined && (
                <SelectElement onSelect={handleSelectElement(serviceID)}>
                  <IconButton
                    aria-label="add to cart"
                    className={selectButtonClasses}
                  >
                    <Check />
                  </IconButton>
                </SelectElement>
              )}
            </div>
          </Tooltip>
        </CardActions>
      )}
    </Card>
  );
};

export default withStyles(eventServiceStyle)(EventService);
