import { call, put, takeLatest } from "redux-saga/effects";

// Actions
import {
  FETCHED_EVENT_SERVICE,
  FETCHED_EVENT_SERVICES,
  FETCH_EVENT_SERVICE,
  FETCH_EVENT_SERVICES,
  FETCH_EVENT_SERVICE_FAILURE,
  LOADING_EVENT_SERVICE
} from "../Constants/EventServicesActionTypes";

// end Point
import { eventServiceListEndPoint } from "../ApiClient/EventServices/EventServicesList";

export function* watchEventServices() {
  yield takeLatest(
    [FETCH_EVENT_SERVICE, FETCH_EVENT_SERVICES],
    handleEventServices
  );
}

export function* handleEventServices(payload) {
  try {
    switch (payload.type) {
      case FETCH_EVENT_SERVICE: {
        yield put({ type: LOADING_EVENT_SERVICE });

        // call endpoint
        const servicesData = yield call(
          eventServiceListEndPoint,
          payload.payload
        );

        if (servicesData && servicesData.data) {
          yield put({
            type: FETCHED_EVENT_SERVICE,
            payload: servicesData
          });
        } else {
          yield put({
            type: FETCH_EVENT_SERVICE_FAILURE,
            payload: servicesData
          });
        }

        break;
      }
      case FETCH_EVENT_SERVICES: {
        // call endpoint
        const servicesData = yield call(
          eventServiceListEndPoint,
          payload.payload
        );

        if (servicesData && servicesData.data) {
          yield put({
            type: FETCHED_EVENT_SERVICES,
            payload: servicesData
          });
        } else {
          yield put({
            type: FETCH_EVENT_SERVICE_FAILURE,
            payload: servicesData
          });
        }

        break;
      }

      default:
        break;
    }
  } catch (error) {
    yield put({
      type: FETCH_EVENT_SERVICE_FAILURE,
      payload: { error: error.message }
    });
  }
}
