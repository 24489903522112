import React from "react";
import SortAlpha from "@material-ui/icons/Sort";
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Components
import DropDown from "../DropDown";

// style
import { sortButtonStyle } from "./SortButtonStyle";

const SortButton = ({ ...sortButtonProps }) => {
  const { classes } = sortButtonProps;

  return (
    <div className={classes.sortGroup}>
      <div className={classes.housesSortSelect}>
        <DropDown dropDownIcon={<SortAlpha />} />
      </div>
    </div>
  );
};

export default withStyles(sortButtonStyle)(SortButton);
