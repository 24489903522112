import {
  FETCHED_EVENT_SERVICE,
  FETCHED_EVENT_SERVICES,
  FETCH_EVENT_SERVICE_FAILURE
} from "../Constants/EventServicesActionTypes";

export const eventServicesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_EVENT_SERVICES: {
      return { ...action.payload };
    }
    case FETCHED_EVENT_SERVICE: {
      return { ...action.payload };
    }
    case FETCH_EVENT_SERVICE_FAILURE: {
      return { ...action.payload };
    }

    default:
      return state;
  }
};
