import NotFoundContainer from "../Containers/NotFound";
import LandingPageContainer from "../Containers/LandingPage";
import EventItemsListContainer from "../Containers/EventItems/EventItemsListContainer";
import EventItemServicesContainer from "../Containers/EventServices/EventItemServicesContainer";
import ServiceProviderDetailsContainer from "../Containers/ServiceProviders/ServiceProviderDetailsContainer";
import SignUpFormed from "../Containers/Auth/SignUpContainer";
import SignInFormed from "../Containers/Auth/SignInContainer";
import ServiceProvidersListContainer from "../Containers/ServiceProviders/ServiceProvidersListContainer";
import ProfileContainer from "../Containers/Profile/ProfileContainer";
import ServiceProviderBasicDetailsUpdateFormed from "../Containers/ServiceProviders/ServiceProviderBasicDetailsUpdateContainer";
import ConfirmEmailContainer from "../Containers/Auth/ConfirmEmailContainer";
import UpdatePasswordFormed from "../Containers/Auth/UpdatePasswordContainer";
import ForgotPasswordFormed from "../Containers/Auth/ForgotPasswordContainer";

import { appItems } from "./RouteItems";

export const appRoutes = [
  {
    path: "/",
    matching: true,
    pageTitle: "Home",
    component: LandingPageContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/event-items",
    matching: true,
    pageTitle: "Event Types",
    component: EventItemsListContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/event-items/event-services",
    matching: true,
    pageTitle: "Event Services",
    component: EventItemServicesContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/event-items/:eventItemId/event-services",
    matching: true,
    pageTitle: "Event Services",
    component: EventItemServicesContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/event-services/service-providers",
    matching: true,
    pageTitle: "Service Providers",
    component: ServiceProvidersListContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/event-services/:eventServiceIds/service-providers",
    matching: true,
    pageTitle: "Service Providers",
    component: ServiceProvidersListContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/profile/:profileItem/:profileItemId",
    matching: true,
    pageTitle: "Profile",
    component: ProfileContainer,
    connectedlinks: [],
    requireaAuth: true
  },
  {
    path: "/service-providers/sp-profile-update/:userId",
    matching: true,
    pageTitle: "Service Providers",
    component: ServiceProviderBasicDetailsUpdateFormed,
    connectedlinks: [],
    requireaAuth: true
  },
  {
    path: "/service-providers/:serviceProviderItem/:serviceProviderId",
    matching: true,
    pageTitle: "Service Providers",
    component: ServiceProviderDetailsContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path:
      "/service-providers/:serviceProviderItem/:serviceProviderId/:messageItemId",
    matching: true,
    pageTitle: "Service Providers",
    component: ServiceProviderDetailsContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/sign-up",
    matching: true,
    pageTitle: "Sign Up",
    component: SignUpFormed,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/sign-in",
    matching: true,
    pageTitle: "Sign In",
    component: SignInFormed,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/email-confirmation/:userId/:confirmationToken",
    matching: true,
    pageTitle: "Confirm Email",
    component: ConfirmEmailContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/email-confirmation/:userId",
    matching: true,
    pageTitle: "Confirm Email",
    component: ConfirmEmailContainer,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/password-reset/:userId/:passwordResetToken",
    matching: false,
    pageTitle: "Update Password",
    component: UpdatePasswordFormed,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: "/forgot-password",
    matching: false,
    pageTitle: "Forgot Password",
    component: ForgotPasswordFormed,
    connectedlinks: [],
    requireaAuth: false
  },
  {
    path: appItems[0].link,
    matching: true,
    pageTitle: "App Items",
    component: NotFoundContainer,
    connectedlinks: appItems,
    requireaAuth: false
  },
  {
    path: "*",
    matching: true,
    pageTitle: "Page Not Found",
    component: NotFoundContainer,
    connectedlinks: [],
    requireaAuth: false
  }
];
