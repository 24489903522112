export const dropDownStyle = theme => ({
  root: {
    display: "flex"
  },
  dropDownButton: {
    borderRadius: "2px",
    "&:hover,&:focus": {
      color: "#382f80"
    }
  },
  paper: {
    marginRight: theme.spacing() * 2
  }
});
