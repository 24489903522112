import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CompareArrow from "@material-ui/icons/Compare";
import CheckIcon from "@material-ui/icons/Favorite";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import { serviceProviderMiniProfileDetailStyle } from "./ServiceProviderMiniProfileDetailStyle";

// Default Background Image
const defaultBackgroundImage = require("../../../Media/images/profile/sp-profile-bg-image.jpg");

const ServiceProviderMiniProfileDetail = ({
  ...serviceProviderMiniProfileDetailProps
}) => {
  const {
    classes,
    serviceProviderImage,
    serviceProviderName,
    serviceProviderDescription,
    serviceProviderRating,
    serviceProviderReviews,
    serviceProviderBackroundImage,
    handleAddToCompare,
    handleAddToFavorite,
    serviceProviderID,
    SelectElement,
    checkedCompareSPs,
    checkedFavoriteSPs,
    showEventService
  } = serviceProviderMiniProfileDetailProps;

  const checkedCompareSpsClasses =
    checkedCompareSPs !== undefined &&
    checkedCompareSPs.includes(`${serviceProviderID}`)
      ? classes.selectedButton
      : classes.selectButton;

  const checkedFavoriteSpsClasses =
    checkedFavoriteSPs !== undefined &&
    checkedFavoriteSPs.includes(`${serviceProviderID}`)
      ? classes.selectedButton
      : classes.selectButton;

  const avatarContent =
    serviceProviderImage !== "" && serviceProviderImage !== undefined ? (
      <Avatar alt={serviceProviderName} src={serviceProviderImage} />
    ) : (
      <Avatar>
        {serviceProviderName
          .trim()
          .substring(0, 1)
          .toUpperCase()}
      </Avatar>
    );

  const spBackroundImage =
    serviceProviderBackroundImage === "" ||
    serviceProviderBackroundImage === undefined
      ? defaultBackgroundImage
      : serviceProviderBackroundImage;

  return (
    <Card className={classes.cardWrapper}>
      <CardMedia
        component="img"
        title="SP Cover Image"
        image={spBackroundImage}
        className={classes.detailImage}
      />
      <CardContent className={classes.detailBody}>
        <div className={classes.detailAvatar}>{avatarContent}</div>
        <Typography
          component="h6"
          gutterBottom
          align="left"
          className={classes.profileName}
        >
          {serviceProviderName}
        </Typography>
      </CardContent>
      {showEventService && handleAddToFavorite !== undefined && (
        <CardActions className={classes.detailActions}>
          <SelectElement
            onSelect={handleAddToFavorite({
              serviceProviderID,
              serviceProviderImage,
              serviceProviderName,
              serviceProviderDescription,
              serviceProviderRating,
              serviceProviderReviews,
              serviceProviderBackroundImage
            })}
            key={1}
          >
            <IconButton
              aria-label="Add to Favorite"
              className={checkedFavoriteSpsClasses}
            >
              <CheckIcon />
            </IconButton>
          </SelectElement>
          <SelectElement
            key={2}
            onSelect={handleAddToCompare({
              serviceProviderID,
              serviceProviderImage,
              serviceProviderName,
              serviceProviderDescription,
              serviceProviderRating,
              serviceProviderReviews,
              serviceProviderBackroundImage
            })}
          >
            <IconButton
              aria-label="Add to Compare"
              className={checkedCompareSpsClasses}
            >
              <CompareArrow />
            </IconButton>
          </SelectElement>
        </CardActions>
      )}
    </Card>
  );
};

export default withStyles(serviceProviderMiniProfileDetailStyle)(
  ServiceProviderMiniProfileDetail
);
