import {
  CONFIRM_EMAIL_FAILED,
  CONFIRM_EMAIL_SUCCESSFUL
} from "../Constants/AuthActionTypes";

export const confirmEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_EMAIL_FAILED:
      return { ...action.payload };

    case CONFIRM_EMAIL_SUCCESSFUL:
      return { ...action.payload };

    default:
      return state;
  }
};
