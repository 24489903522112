import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
// import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Link from "react-router-dom/Link";

// Custom Components

import { headerStyle } from "./HeaderStyle";
import { HeaderRightLinks, HeaderLeftLinks } from "./Links";

const siteLogo = require("../../../Media/images/general/logo-icon.png");

const Header = ({ ...headerProps }) => {
  const {
    classes,
    onDrawerToggle,
    pageTitle,
    authState,
    routeProps
  } = headerProps;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes.absolute]: true,
    [classes.fixed]: true,
    [classes.headerBoxShadow]: true
  });

  return (
    <AppBar color="primary" position="relative" className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smUp>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={onDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div className={classes.appTitle}>
          <Link
            className={`${classes.title} ${classes.hideLogoforMobile}`}
            to="/"
          >
            <img src={siteLogo} alt="Site Logo" height="100%" />
          </Link>
          <HeaderLeftLinks pageTitle={pageTitle} routeProps={routeProps} />
        </div>
        <div className={classes.rightActions}>
          <HeaderRightLinks authState={authState} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);
