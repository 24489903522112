import { FETCH_USER, FETCH_USERS } from "../Constants/UserProfileActionTypes";

export const fetchUser = payload => {
  return {
    type: FETCH_USER,
    payload
  };
};

export const fetchUsers = payload => {
  return {
    type: FETCH_USERS,
    payload
  };
};
