export const parallaxStyle = {
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "270px",
    background: "#382f8073",
    opacity: "9",
    "&>div": {
      position: "relative",
      top: "50%",
      left: "0%",
      transform: "translate(0%, -50%)",
      textAlign: "center"
    },
    "& .tag-line": {
      color: "#fefefe",
      fontSize: "27px",
      textAlign: "left",
      paddingBottom: "10px",
      fontFamily: '"Google Sans",Roboto,Arial,Helvetica,sans-serif',
      paddingLeft: "15px"
    },
    "&>div section.landing-page-search form>div": {
      width: "100%",
      padding: "7px 10px"
    }
  },
  parallaxImage: {
    position: "absolute",
    left: "50%",
    transform: "translate3d(-50%, -60%, 0px)",
    transformStyle: "preserve-3d",
    backfaceVisibility: "hidden",
    height: "auto",
    width: "auto",
    filter: "blur(0px)",
    background: "#FFF"
  }
};
