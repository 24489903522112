import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
// import Casino from "@material-ui/icons/Casino";

// Componenets
import SubHeader from "../../../General/SubHeader";
import GridContainer from "../../../General/GridContainer";
import SortButton from "../../../General/SortButton";
// Style
import { eventItemServicesSubheaderStyle } from "./EventItemServicesSubheaderStyle";

const defaultEventImage = require("../../../../Media/images/events/default.png");

const EventItemServicesSubheader = ({ ...eventItemServicesSubheaderProps }) => {
  const {
    classes,
    eventName,
    eventImage,
    searchComponent
  } = eventItemServicesSubheaderProps;

  const eventItemImage =
    eventImage !== "" ? (
      <img src={eventImage} alt={eventName} width="25px" />
    ) : (
      <img src={defaultEventImage} alt={eventName} width="25px" />
    );

  return (
    <SubHeader>
      <GridContainer
        direction="row"
        justify="flex-start"
        alignItems="center"
        className={classes.subHeaderWrapper}
      >
        <Grid item xs={12} sm={6} md={7}>
          <Typography
            component="h1"
            gutterBottom
            align="left"
            className={classes.headerText}
          >
            <span className={classes.eventIcon}>{eventItemImage}</span>
            <span>{eventName}</span>
          </Typography>
          <Typography
            variant="caption"
            gutterBottom
            align="left"
            className={classes.subHeaderText}
          >
            {`Services related to ${eventName}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <GridContainer direction="row" justify="flex-end" alignItems="center">
            <Grid item xs={8} sm={6} md={8}>
              <div className={classes.housesSearch}>{searchComponent}</div>
            </Grid>
            <Grid item xs={4} sm={6} md={4}>
              <SortButton />
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
    </SubHeader>
  );
};

export default withStyles(eventItemServicesSubheaderStyle)(
  EventItemServicesSubheader
);
