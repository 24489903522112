import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

// Components
import ItemDetailComponent from "../../General/ItemDetailComponent";
// Styles
import { profileSummaryStyle } from "./ProfileSummaryStyle";

const ProfileSummary = ({ ...profileSummaryProps }) => {
  const { userProfileDetails, editEnabled } = profileSummaryProps;

  const itemDetail = {
    ...userProfileDetails[0],
    ...{ userEmail: editEnabled ? userProfileDetails[0].userEmail : "XXX-XXX" }
  };

  return (
    <ItemDetailComponent
      itemDetail={itemDetail}
      itemName={`${userProfileDetails[0].firstName} ${
        userProfileDetails[0].lastName
      }`}
      housedByCard
    />
  );
};

export default withStyles(profileSummaryStyle)(ProfileSummary);
