import { call, put, takeLatest } from "redux-saga/effects";

// end Point
import { eventItemsEndPoint } from "../ApiClient/EventItem/EventItemList";
// Action types
import {
  FETCH_EVENTITEM,
  FETCH_EVENTITEMS,
  FETCHED_EVENTITEM,
  FETCHED_EVENTITEMS,
  FETCH_EVENTITEM_WRONG_URI,
  FETCH_EVENTITEM_FAILURE,
  LOADING_EVENT
} from "../Constants/EventsActionTypes";

export function* watchEvents() {
  yield takeLatest(
    [FETCH_EVENTITEM, FETCH_EVENTITEMS, FETCH_EVENTITEM_WRONG_URI],
    handleEvents
  );
}

export function* handleEvents(payload) {
  try {
    switch (payload.type) {
      case FETCH_EVENTITEM: {
        yield put({ type: LOADING_EVENT });
        const eventItemData = yield call(eventItemsEndPoint, payload.payload);
        yield put({ type: FETCHED_EVENTITEM, payload: eventItemData });
        break;
      }
      case FETCH_EVENTITEMS: {
        yield put({ type: LOADING_EVENT });
        const eventItemsData = yield call(eventItemsEndPoint, payload.payload);
        yield put({ type: FETCHED_EVENTITEMS, payload: eventItemsData });
        break;
      }
      case FETCH_EVENTITEM_WRONG_URI: {
        yield put({
          type: FETCH_EVENTITEM_FAILURE,
          payload: "Wrong link provided !"
        });
        break;
      }
      default: {
        break;
      }
    }
  } catch (error) {
    yield put({ type: FETCH_EVENTITEM_FAILURE, payload: error });
  }
}
