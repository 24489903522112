import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_COUNTRIES,
  FETCH_COUNTRY,
  FETCHED_COUNTRIES,
  FETCHED_COUNTRY,
  FETCH_COUNTRY_FAILURE
} from "../Constants/CountriesActionTypes";

import { countriesEndPoint } from "../ApiClient/Country/CountryList";

export function* watchCountries() {
  yield takeLatest([FETCH_COUNTRIES, FETCH_COUNTRY], handleCountries);
}

export function* handleCountries(payload) {
  switch (payload.type) {
    case FETCH_COUNTRIES: {
      const countries = yield call(countriesEndPoint);

      if (countries && countries.data) {
        yield put({
          type: FETCHED_COUNTRIES,
          payload: countries
        });
      }
      if (countries && countries.error) {
        yield put({
          type: FETCH_COUNTRY_FAILURE,
          payload: countries
        });
      }
      break;
    }
    case FETCH_COUNTRY: {
      const countryData = yield call(countriesEndPoint, payload.payload);

      if (countryData && countryData.countryDetails) {
        yield put({
          type: FETCHED_COUNTRY,
          payload: countryData
        });
      }

      if (countryData && countryData.error) {
        yield put({
          type: FETCH_COUNTRY_FAILURE,
          payload: countryData
        });
      }

      break;
    }

    default:
      break;
  }
}
