import { ApiClientEndPointMethod } from "../EndPoint";
import { MESSAGES_URL } from "../EndPoint/ConstantVariables";

export const messageSummaryListEndpoint = payload => {
  if (payload !== undefined) {
    const messageSummaryListUrl = `${MESSAGES_URL}Messages/UserMessagesSummary/${
      payload.targetUserId
    }`;

    const messagesSummaryList = ApiClientEndPointMethod({
      url: messageSummaryListUrl,
      authorize: true
    });

    try {
      return messagesSummaryList.then(messagesSummaryListData => {
        if (
          messagesSummaryListData &&
          messagesSummaryListData.messagesSummary
        ) {
          return {
            data: messagesSummaryListData.messagesSummary.map(
              ({ sender, receiver, messageDetail }) => {
                return {
                  sender,
                  receiver,
                  messageDetail:
                    messageDetail == null
                      ? []
                      : JSON.parse(`[${messageDetail}]`).map(
                          ({
                            Sender,
                            SenderName,
                            Receiver,
                            RecipientName,
                            CreatedDate,
                            MessageTitle,
                            MessageContent
                          }) => ({
                            Sender,
                            SenderName,
                            Receiver,
                            RecipientName,
                            CreatedDate,
                            MessageTitle,
                            MessageContent
                          })
                        )
                };
              }
            )
          };
        }
        return { error: "Error Fetching Data" };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "Error Fetching Messages Summary" };
  }
};
