import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Actions
import { fetchMessages } from "../../Actions/MessagesActions";

// Components
import MessageList from "../../Components/Messages/MessageList";

class MessageListContainer extends Component {
  componentDidMount = () => {
    const {
      authState: { userDetails },
      dispatch
    } = this.props;
    if (userDetails !== undefined) {
      dispatch(fetchMessages({ targetUserId: userDetails.userId }));
    }
  };

  render = () => {
    const {
      authState,
      messagesSummaryState,
      targetConversationId,
      targetMessageUriPrefix
    } = this.props;

    console.log(authState);

    return (
      <>
        {messagesSummaryState && messagesSummaryState.data && (
          <MessageList
            activeConvoId={targetConversationId}
            targetMessageUriPrefix={targetMessageUriPrefix}
            messagesSummary={messagesSummaryState.data}
          />
        )}
      </>
    );
  };
}

MessageListContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired,
  messagesSummaryState: PropTypes.object.isRequired,
  targetConversationId: PropTypes.string.isRequired,
  targetMessageUriPrefix: PropTypes.string.isRequired
};

const mapStateToProps = ({ authState, messagesSummaryState }) => ({
  authState,
  messagesSummaryState
});

export default connect(mapStateToProps)(MessageListContainer);
