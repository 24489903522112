import {
  FETCHED_CITIES,
  FETCHED_CITY,
  FETCH_CITY_FAILURE
} from "../Constants/CitiesActionTypes";

export const citiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_CITIES:
      return { ...action.payload };
    case FETCHED_CITY:
      return { ...action.payload };

    case FETCH_CITY_FAILURE:
      return { ...action.payload };

    default:
      return state;
  }
};
