import {
  SIGN_IN_FAILED,
  SIGN_UP_FAILED,
  SIGNING_IN,
  SIGN_IN_SUCCESSFUL,
  SIGN_UP_SUCCESSFUL,
  CLEARED_SIGNED_IN_PROFILE
} from "../Constants/AuthActionTypes";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case SIGN_IN_FAILED:
      return { ...action.payload, ...{ isSignInModule: true } };
    case SIGN_UP_FAILED:
      return { ...action.payload };
    case SIGN_IN_SUCCESSFUL:
      return {
        ...action.payload,
        ...{ isSignedIn: true, isSignInModule: true, updatedKey: true }
      };
    case SIGN_UP_SUCCESSFUL:
      return { ...action.payload, ...{ isSignedIn: true, updatedKey: true } };
    case SIGNING_IN:
      return { loading: true };
    case CLEARED_SIGNED_IN_PROFILE:
      return { isSignedOut: true };
    default:
      return state;
  }
};
