import {
  FETCHED_UOM,
  FETCHED_UOMS,
  FETCH_UOM_FAILURE
} from "../Constants/UOMsActionTypes";

export const uomsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_UOMS:
      return { ...action.payload };
    case FETCHED_UOM:
      return { ...action.payload };
    case FETCH_UOM_FAILURE:
      return { ...action.payload };
    default:
      return state;
  }
};
