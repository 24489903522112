export const serviceProviderMiniProfileDetailStyle = theme => ({
  cardWrapper: {
    // boxShadow: "rgba(0, 0, 0, 0.1) 1px 2px 4px"
    boxShadow: "none",
    border: "1px solid #e7e5f3ed",
    marginBottom: "10px"
  },
  detailImage: {
    height: "180px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  },
  detailBody: {
    padding: "10px 15px 0px 25px",
    fontSize: "12px"
  },
  detailAvatar: {
    marginTop: "-35px",
    "&>div": {
      height: "50px",
      width: "50px"
    }
  },
  profileName: {
    paddingTop: "4px",
    textTransform: "capitalize",
    "& a": {
      color: "inherit",
      textDecoration: "none"
    }
  },
  detailDescription: {
    textAlign: "left",
    "& a": {
      color: "inherit",
      textDecoration: "none"
    }
  },
  ratingWrapper: {
    paddingTop: "5px",
    "& svg": {
      fontSize: "15px"
    },
    "& span": {
      fontSize: "11px",
      color: "#888"
    }
  },

  detailActions: {
    "& button": {
      // backgroundColor: "#EEE",
      "&:hover,:focus": {
        backgroundColor: theme.palette.primary.main,
        color: "#FFF"
      },
      "& svg": {
        fontSize: "15px"
      }
    }
  },
  selectButton: {
    backgroundColor: "#EEE",
    "&:hover,:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF"
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    "&:hover,:focus": {
      backgroundColor: "#EEE",
      color: theme.palette.primary.main
    }
  }
});
