import { call, put, takeLatest } from "redux-saga/effects";

// End Point
import { resetPasswordEndPoint } from "../ApiClient/AuthEndPoint/ResetPassword";
import {
  RESET_PASSWORD,
  RESETTING_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS
} from "../Constants/AuthActionTypes";

export function* watchResetPassword() {
  yield takeLatest([RESET_PASSWORD], handleResetPassword);
}

export function* handleResetPassword(payload) {
  switch (payload.type) {
    case RESET_PASSWORD: {
      yield put({ type: RESETTING_PASSWORD });
      const resetResults = yield call(resetPasswordEndPoint, payload.payload);
      if (resetResults === 1) {
        yield put({ type: RESET_PASSWORD_SUCCESS });
      } else {
        yield put({ type: RESET_PASSWORD_FAILED });
      }
      break;
    }
    default:
      break;
  }
}
