/* eslint  "no-restricted-syntax": [0] */
import React from "react";
import Link from "react-router-dom/Link";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Components

import CustomChip from "../CustomChip";
import SubHeader from "../SubHeader";
import GridContainer from "../GridContainer";
import BreadCrumb from "../BreadCrumb";
import AlertDialog from "../AlertDialog";

// styles
import { itemDetailComponentStyle } from "./ItemDetailComponentStyle";
import { contentUtilities, mainContent } from "../../Layout/Style";

const ItemDetailComponent = ({ ...itemDetailComponentProps }) => {
  const {
    classes,
    itemDetail,
    handleClickDeleteButton,
    openDeleteDialog,
    handleCloseDialog,
    itemDetailUpdateStatus,
    itemName,
    itemEditLink,
    breadCrumbRoutes,
    housedByCard,
    postPadComponent,
    subheaderCustomstyle
  } = itemDetailComponentProps;

  let dialogContent = <p>Are you sure you want to delete this Item?</p>;

  if (itemDetailUpdateStatus === 0) {
    dialogContent = (
      <p>
        <LinearProgress color="primary" />
      </p>
    );
  } else if (itemDetailUpdateStatus === -1) {
    dialogContent = <p> Deleting Item failed ! </p>;
  } else if (itemDetailUpdateStatus === 1) {
    dialogContent = <p> Deletion Successful. Redirecting to list.... </p>;
  }

  const deleteDialogBtnDisabled = !!(
    itemDetailUpdateStatus === 0 || itemDetailUpdateStatus === 1
  );

  const itemDetails = [];

  for (const key in itemDetail) {
    if (Object.prototype.hasOwnProperty.call(itemDetail, key)) {
      if (
        !key.includes("deleteEnabled") &&
        !key.includes("editEnabled") &&
        !key.includes("id") &&
        !key.includes("ID") &&
        !key.includes("Token") &&
        !key.includes("emailConfirmed") &&
        !key.startsWith("is") &&
        itemDetail[key] !== null
      ) {
        const itemValue = [];

        if (Array.isArray(itemDetail[key])) {
          itemDetail[key].forEach(element => {
            itemValue.push(
              <Link
                to={element.link}
                key={element.id}
                className={classes.itemValueLink}
              >
                <CustomChip content={element.value} />
              </Link>
            );
          });
        } else if (`${key}`.includes("Image")) {
          itemValue.push(
            <div className={classes.itemDetailImage} key="image">
              {itemDetail[key] !== "" && (
                <img src={itemDetail[key]} alt="itemImage" />
              )}
            </div>
          );
        } else {
          itemValue.push(<div key="content">{`${itemDetail[key]}`}</div>);
        }

        itemDetails.push(
          <React.Fragment key={key}>
            <Grid item md={3} sm={3} xs={6}>
              <div className={classes.contentLabel}>
                {key.replace(/([A-Z])/g, " $1").trim()}
              </div>
            </Grid>
            <Grid item md={9} sm={9} xs={6}>
              <div className={classes.contentContent}> {itemValue}</div>
            </Grid>
          </React.Fragment>
        );
      }
    }
  }

  if (postPadComponent !== undefined) {
    itemDetails.push(
      <React.Fragment key="postpadded">
        <Grid item md={3} sm={3} xs={6}>
          <div className={classes.contentLabel}>{postPadComponent.title}</div>
        </Grid>
        <Grid item md={9} sm={9} xs={6}>
          <div
            className={`${classes.contentContent} ${
              postPadComponent.contentStyle
            }`}
          >
            {postPadComponent.content}
          </div>
        </Grid>
      </React.Fragment>
    );
  }

  const itemDetailComponetContent = (
    <>
      <SubHeader className={subheaderCustomstyle}>
        <GridContainer
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.subHeaderContentWrapper}
        >
          <Grid item md={8} sm={8} xs={12}>
            {breadCrumbRoutes !== undefined && (
              <BreadCrumb
                breadCrumbRoutes={breadCrumbRoutes}
                pageName="Details"
              />
            )}
            <Typography gutterBottom variant="h6">
              {`${itemName} Details`}
            </Typography>
          </Grid>
        </GridContainer>
      </SubHeader>

      <GridContainer
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.mainConentWrapper}
      >
        {itemDetails}
        <Grid item md={1} sm={2} xs={4} className={classes.actionButtons}>
          {itemDetail.editEnabled && (
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              component={Link}
              to={itemEditLink}
            >
              Edit
            </Button>
          )}
        </Grid>
        <Grid item md={1} sm={2} xs={4} className={classes.actionButtons}>
          {itemDetail.deleteEnabled && (
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              onClick={handleClickDeleteButton}
            >
              Delete
            </Button>
          )}
        </Grid>
        <Grid item md={10} sm={10} xs={8} />
      </GridContainer>
      {openDeleteDialog !== undefined && (
        <AlertDialog
          dialogTitle="Confirm Deletion"
          dialogContent={dialogContent}
          openDialog={openDeleteDialog}
          handleCloseDialog={handleCloseDialog}
          deleteDialogBtnDisabled={deleteDialogBtnDisabled}
        />
      )}
    </>
  );

  return (
    <>
      {housedByCard !== undefined && housedByCard && (
        <Card className={classes.cardWrapper}>
          <CardContent>{itemDetailComponetContent}</CardContent>
        </Card>
      )}

      {(housedByCard === undefined || !housedByCard) &&
        itemDetailComponetContent}
    </>
  );
};

export default withStyles({
  ...itemDetailComponentStyle,
  ...contentUtilities,
  ...mainContent
})(ItemDetailComponent);
