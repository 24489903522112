import React from "react";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

// styles
import { customChipStyle } from "./CustomChipStyle";

const CustomChip = ({ ...customChipProps }) => {
  const { classes, content, link } = customChipProps;

  const uriObject =
    link !== undefined && link !== ""
      ? {
          component: Link,
          to: link
        }
      : {};

  return (
    <>
      <Chip label={content} className={classes.chip} {...uriObject} />
    </>
  );
};

export default withStyles(customChipStyle)(CustomChip);
