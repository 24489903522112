export const breadCrumbStyle = {
  breadCrumbContainer: {
    color: "#5e6c84",
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: "10px"
  },
  breadCrumbItem: {
    display: "flex",
    flexDirection: "row",
    fontSize: "12px",
    "& a": {
      textDecoration: "none"
    }
  },
  separator: {
    color: "#7a869a",
    flexShrink: "0",
    textAlign: "center",
    width: "8px",
    padding: "0px 8px"
  }
};
