import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";

// Actions
import { clearSignIn, resetPassword } from "../../Actions/AuthActions";

import BaseRedirectContainer from "../BaseRedirectContainer";

// Components
import ForgotPassword from "../../Components/Auth/ForgotPassword";

class ForgotPasswordContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(clearSignIn());
  };

  handleForgotPassword = values => {
    const { dispatch } = this.props;

    if (values && values.userEmail !== undefined) {
      dispatch(
        resetPassword({
          ...values
        })
      );
    }
  };

  render = () => {
    const { handleSubmit, resetPasswordState, location } = this.props;

    const { redirect } = this.state;

    if (
      resetPasswordState &&
      (resetPasswordState.success || resetPasswordState.error) &&
      redirect
    ) {
      return (
        <Redirect
          to={{
            pathname: "/sign-in",
            state: location
          }}
        />
      );
    }

    return (
      <>
        <ForgotPassword
          resetPasswordState={{
            ...resetPasswordState,
            ...{ isForgotPasswordModule: true }
          }}
          handleForgotPassword={handleSubmit(this.handleForgotPassword)}
        />
      </>
    );
  };
}

ForgotPasswordContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetPasswordState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemDetail: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = ({ resetPasswordState }) => ({
  resetPasswordState,
  itemDetail:
    resetPasswordState && resetPasswordState.success ? { updatedKey: 1 } : {}
});

const ForgotPasswordFormed = reduxForm({ form: "forgot-password" })(
  ForgotPasswordContainer
);

export default connect(mapStateToProps)(ForgotPasswordFormed);
