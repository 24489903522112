export const serviceProvidersCompareStyle = {
  addToCompareContent: {
    border: "1px solid #d9d8e073",
    borderRadius: "4px",
    borderBottom: "none",
    borderBottomRightRadius: " 0px",
    borderBottomLeftRadius: "0px"
  },
  gridItemCustomStyle: {
    padding: "0px 5px"
  }
};
