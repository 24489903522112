import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

// Components
import ItemDetailComponent from "../../General/ItemDetailComponent";
import EventService from "../../EventServices/EventService";

import { serviceProviderSummaryStyle } from "./ServiceProviderSummaryStyle";

const ServiceProviderSummary = ({ ...serviceProviderSummaryProps }) => {
  const { classes, spExtendedDetails } = serviceProviderSummaryProps;

  const postPadComponent = {
    title: "Service",
    content: (
      <EventService
        serviceID={spExtendedDetails.spService.data[0].id}
        serviceName={spExtendedDetails.spService.data[0].eventServiceName}
        serviceImage={spExtendedDetails.spService.data[0].eventServiceImage}
        serviceColor={spExtendedDetails.spService.data[0].eventServiceColor}
        serviceDescription={
          spExtendedDetails.spService.data[0].eventServiceDescription
        }
        showDescription
      />
    ),
    contentStyle: classes.eventServiceWrapper
  };

  return (
    <ItemDetailComponent
      itemDetail={spExtendedDetails.spExtProfile}
      itemName="Service Provider"
      postPadComponent={postPadComponent}
      subheaderCustomstyle={classes.subheaderCustomstyle}
      housedByCard
    />
  );
};

export default withStyles(serviceProviderSummaryStyle)(ServiceProviderSummary);
