export const eventItemServicesSubheaderStyle = theme => ({
  headerText: {
    color: "#222",
    fontSize: "20px",
    marginBottom: "0px"
  },
  subHeaderWrapper: {
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px"
    }
  },
  subHeaderText: {
    paddingLeft: "20px",
    fontSize: "13px",
    marginBottom: "0px"
  },
  eventIcon: {
    width: "25px",
    height: "25px",
    verticalAlign: "sub",
    color: "#FF0000",
    marginRight: "5px"
  },
  housesSearch: {
    "&>section": {
      width: "90%",
      textAlign: "right"
    },
    "&>section>div": {
      border: "1px solid #bdbac9",
      "& svg": {
        fontSize: "20px",
        color: "#bdbac9"
      },
      "& div>input": {
        paddingBottom: "5px",
        paddingTop: "5px",
        fontSize: "15px"
      }
    }
  }
});
