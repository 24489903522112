import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const TNCStyle = theme => ({
  root: {
    width: "100%",
    maxWidth: 500,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 750
    },
    maxHeight: "60vh",
    overflowY: "scroll"
  },
  subtitle1: {
    fontWeight: "600"
  }
});

const TNC = ({ ...tNCProps }) => {
  const { classes } = tNCProps;

  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={12}>
        <Typography variant="h5" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.subtitle1}
        >
          Last updated: 30th October 2019
        </Typography>

        <Typography variant="body2" gutterBottom>
          Please read these Terms and Conditions carefully before using this
          platform operated by Upfik. Your access to and use of the Service is
          conditioned on your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users and others who access or use
          the Service. By accessing or using the Service you agree to be bound
          by these Terms.
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.subtitle1}
        >
          Subscriptions
        </Typography>

        <Typography variant="body2" gutterBottom>
          Some parts of the Service are billed on a subscription basis
          &quot;Subscription(s)&quot;. You will be billed in advance on a
          recurring ...
        </Typography>
      </Grid>
    </Grid>
  );
};
export default withStyles(TNCStyle)(TNC);
