/*  global window */
import React from "react";
import { Route, Switch } from "react-router-dom";

// Containers
import Authentication from "./Containers/Auth/Authentication";

// CUstom Components
import { appRoutes } from "./Routes";
import LayoutContainer from "./Containers/Layout";

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const App = () => (
  <>
    <Route component={ScrollToTop} />
    <Switch>
      {appRoutes.map(prop => (
        <Route
          exact={prop.matching}
          path={prop.path}
          key={prop.pageTitle.trim()}
          render={props => {
            const currentComponent = prop.requireaAuth ? (
              <Authentication>
                <prop.component {...props} />
              </Authentication>
            ) : (
              <prop.component {...props} />
            );
            return (
              <LayoutContainer
                currentComponent={currentComponent}
                routeProps={prop}
              />
            );
          }}
        />
      ))}
    </Switch>
  </>
);

export default App;
