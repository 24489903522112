import { ApiClientEndPointMethod } from "../EndPoint";
import { USERS_URL } from "../EndPoint/ConstantVariables";

export const updatePasswordEndPoint = payload => {
  if (payload && payload.newPassword !== undefined) {
    const passWordUpdate = ApiClientEndPointMethod({
      url: `${USERS_URL}Auth/UpdatePassword`,
      customBodyOptions: {
        body: JSON.stringify(payload)
      },
      method: "POST"
    });

    try {
      return passWordUpdate.then(passWDData => {
        if (passWDData && passWDData.requestStatus === 1) {
          return { data: passWDData };
        }
        if (passWDData && passWDData.requestStatus === 0) {
          return { error: "An error occured while updating password" };
        }
        return { error: "An unknown error occured" };
      });
    } catch (error) {
      return { error: error.message };
    }
  }
  return { error: "No Data to Submit" };
};
