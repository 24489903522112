import {
  FETCH_SERVICE_PROVIDER,
  FETCH_SERVICE_PROVIDERS,
  UPDATE_SP_PROFILE_DETAILS,
  FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS
} from "../Constants/ServiceProvidersActionTypes";

export const fetchServiceProvider = payload => {
  return {
    type: FETCH_SERVICE_PROVIDER,
    payload
  };
};

export const fetchServiceProviders = payload => {
  return {
    type: FETCH_SERVICE_PROVIDERS,
    payload
  };
};

export const fetchServiceProviderExtendedDetails = payload => {
  return {
    type: FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS,
    payload
  };
};

export const updateServiceProviderProfile = payload => {
  return {
    type: UPDATE_SP_PROFILE_DETAILS,
    payload
  };
};
