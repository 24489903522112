import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CheckRounded from "@material-ui/icons/CheckRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field } from "redux-form";

// Components
import GridContainer from "../../General/GridContainer";
import SubHeader from "../../General/SubHeader";
import FormSelectBox from "../../General/Form/FormSelectBox";
import FormInputBox from "../../General/Form/FormInputBox";

// Helpers
import { selectRequired, required } from "../../General/Form/Helpers";
// Styles
import { serviceProviderBasicDetailsUpdateStyle } from "./ServiceProviderBasicDetailsUpdateStyle";

const ServiceProviderBasicDetailsUpdate = ({
  ...serviceProviderSelectServiceProps
}) => {
  const {
    classes,
    eventServices,
    uoms,
    countries,
    cities,
    handleFormSubmit,
    serviceProvidersState
  } = serviceProviderSelectServiceProps;

  const selectEventServiceOptionsData = eventServices.map(
    ({ id, eventServiceName, eventServiceImage }) => ({
      value: id,
      label: (
        <span>
          <img
            src={eventServiceImage}
            alt={eventServiceName}
            className={classes.serviceImageIcon}
          />
          {eventServiceName}
        </span>
      )
    })
  );

  const selectUOMOptionsData =
    uoms && uoms.data
      ? uoms.data.map(({ id, label }) => ({ value: id, label }))
      : [];

  const selectCountriesOptionsData =
    countries && countries.data
      ? countries.data.map(({ id, countryName }) => ({
          value: id,
          label: countryName
        }))
      : [];

  const selectCitiesOptionsData =
    cities && cities.data
      ? cities.data.map(({ cityId, cityName }) => ({
          value: cityId,
          label: cityName
        }))
      : [];

  // const stepperTitles = ["Basic Details", "Pricing", "Location"];

  return (
    <>
      <SubHeader className={classes.subHeaderWrapper}>
        <div />
      </SubHeader>
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.mainContentWrapper}
      >
        <Grid item md={6} xs={12} sm={12}>
          <Card className={classes.card}>
            <CardHeader
              title="Update Service Provider Basic Details"
              className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
              <form onSubmit={handleFormSubmit} noValidate>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="serviceProviderName"
                      type="text"
                      component={FormInputBox}
                      label="Prefered Service Provider Name *"
                      validate={[required]}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="serviceProviderDescription"
                      type="text"
                      multiline
                      rows={4}
                      component={FormInputBox}
                      label="A short description of what you do *"
                      validate={[required]}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>

                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="eventService"
                      component={FormSelectBox}
                      label="Event Service *"
                      validate={[selectRequired]}
                      selectOptions={selectEventServiceOptionsData}
                    />
                  </FormControl>
                </div>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="serviceChargeUOM"
                      component={FormSelectBox}
                      label="Service Charge Unit of Measure *"
                      validate={[selectRequired]}
                      selectOptions={selectUOMOptionsData}
                    />
                  </FormControl>
                </div>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="serviceCharge"
                      type="number"
                      component={FormInputBox}
                      label="Service Charge per Unit [Selected above] *"
                      validate={[required]}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>

                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="selectCountry"
                      component={FormSelectBox}
                      label="Select Country *"
                      validate={[selectRequired]}
                      selectOptions={selectCountriesOptionsData}
                      isClearable={false}
                    />
                  </FormControl>
                </div>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="selectCity"
                      component={FormSelectBox}
                      label="Select City *"
                      validate={[selectRequired]}
                      selectOptions={selectCitiesOptionsData}
                    />
                  </FormControl>
                </div>
                <div className={classes.formGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="specificLocation"
                      type="text"
                      component={FormInputBox}
                      label="Specific Location *"
                      validate={[]}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  {serviceProvidersState &&
                    serviceProvidersState.isSPUpdateModule &&
                    (serviceProvidersState.error ||
                      serviceProvidersState.data) && (
                      <Typography
                        variant="subtitle2"
                        className={classes.authCaption}
                      >
                        {serviceProvidersState.error && (
                          <div className={classes.authFailure}>
                            <CloseRounded />
                            {serviceProvidersState.error}
                          </div>
                        )}
                        {serviceProvidersState && serviceProvidersState.data && (
                          <div className={classes.authSuccess}>
                            <CheckRounded />
                            {
                              "Update successful! Redirecting to profile page ..."
                            }
                          </div>
                        )}
                      </Typography>
                    )}
                </div>
                <div className={classes.formGroup}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    className={classes.margin}
                  >
                    {serviceProvidersState && serviceProvidersState.loading ? (
                      <CircularProgress size={26} />
                    ) : (
                      "Update Details"
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(serviceProviderBasicDetailsUpdateStyle)(
  ServiceProviderBasicDetailsUpdate
);
