import React from "react";
import { Field } from "redux-form";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CheckRounded from "@material-ui/icons/CheckRounded";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

// Custom Components
import SubHeader from "../General/SubHeader";
import GridContainer from "../General/GridContainer";
import FormInputBox from "../General/Form/FormInputBox";

import { required } from "../General/Form/Helpers";

// Style
import { authStyle } from "./AuthStyle";

const SignIn = ({ ...signInProps }) => {
  const { classes, handleFormSubmit, authState } = signInProps;

  return (
    <>
      <SubHeader className={classes.authSubHeaderWrapper}>
        <div />
      </SubHeader>
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.authWraperHeight}
      >
        <Grid item md={6} xs={12} sm={12}>
          <Card className={classes.authCard}>
            <CardHeader
              title="Sign In to Evento"
              className={classes.authCardHeader}
            />
            <CardContent className={classes.authCardContent}>
              <form onSubmit={handleFormSubmit}>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="userEmail"
                      type="email"
                      component={FormInputBox}
                      label="Email Address *"
                      placeholder="Your registered Email adrress"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="userPassword"
                      type="password"
                      component={FormInputBox}
                      label="Password *"
                      placeholder="Your account password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                    <span className={classes.housesForgotPassword}>
                      <Link to="/forgot-password">Forgot Password</Link>
                    </span>
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  {authState && authState.isSignInModule && (
                    <Typography
                      variant="subtitle2"
                      className={classes.authCaption}
                    >
                      {authState.error && (
                        <div className={classes.authFailure}>
                          <CloseRounded />
                          {authState.error.toUpperCase().includes("INVALID")
                            ? authState.error.replace("name", "email")
                            : "Kindly login to Continue"}
                        </div>
                      )}
                      {authState &&
                        authState.userToken &&
                        authState.userToken.accessToken &&
                        authState.userToken.accessToken.token && (
                          <div className={classes.authSuccess}>
                            <CheckRounded />
                            {
                              "Sign in successful! Redirecting to profile page ..."
                            }
                          </div>
                        )}
                    </Typography>
                  )}
                </div>
                <div className={classes.authFormGroup}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    disabled={authState && authState.loading}
                    type="submit"
                    className={classes.margin}
                  >
                    {authState && authState.loading === undefined && "Submit"}
                    {authState && authState.loading && (
                      <CircularProgress size={26} />
                    )}
                  </Button>
                </div>
                <div className={classes.authFormGroup}>
                  <Typography variant="caption" className={classes.authCaption}>
                    Not registered yet?
                    <Link to="/sign-up">Click here to sign up.</Link>
                  </Typography>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(authStyle)(SignIn);
