import {
  FETCHED_SERVICE_PROVIDER,
  FETCHED_SERVICE_PROVIDERS,
  FETCH_SERVICE_PROVIDER_FAILURE,
  UPDATED_SP_PROFILE_DETAILS,
  UPDATE_SP_PROFILE_DETAILS_FAILURE,
  FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS,
  UPDATTING_SP_PROFILE_DETAILS
} from "../Constants/ServiceProvidersActionTypes";

export const serviceProvidersReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATTING_SP_PROFILE_DETAILS: {
      return { loading: true };
    }
    case FETCHED_SERVICE_PROVIDERS: {
      return { ...action.payload };
    }
    case FETCHED_SERVICE_PROVIDER: {
      return { ...action.payload };
    }
    case FETCH_SERVICE_PROVIDER_FAILURE: {
      return { ...action.payload };
    }

    case UPDATED_SP_PROFILE_DETAILS: {
      return { ...action.payload };
    }

    case UPDATE_SP_PROFILE_DETAILS_FAILURE: {
      return { ...action.payload };
    }

    default:
      return state;
  }
};

export const serviceProviderExtendedDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS:
      return { ...action.payload };

    default:
      return state;
  }
};
