export const serviceProviderSummaryStyle = theme => ({
  eventServiceWrapper: {
    [theme.breakpoints.up("sm")]: {
      width: "60%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  subheaderCustomstyle: {
    paddingBottom: "0vh"
  }
});
