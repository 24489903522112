import React from "react";
import { Field } from "redux-form";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Send from "@material-ui/icons/Send";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import GridContainer from "../../General/GridContainer";
import FormInputBox from "../../General/Form/FormInputBox";
// Styles
import { messageTextBoxStyle } from "./MessageTextBoxStyle";

const MessageTextBox = ({ ...messageTextBoxProps }) => {
  const {
    classes,
    handleFormChange,
    handleFormSubmit,
    pristine
  } = messageTextBoxProps;

  return (
    <>
      <div className={classes.authFormGroup}>
        <form onSubmit={handleFormSubmit}>
          <GridContainer
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item md={10}>
              <FormControl
                component="fieldset"
                className={classes.fieldSetWrapper}
              >
                <Field
                  name="message"
                  type="text"
                  onKeyUp={handleFormChange}
                  multiline
                  rows={2}
                  component={FormInputBox}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <IconButton
                type="submit"
                className={classes.sendButton}
                color="primary"
                disabled={pristine}
              >
                <Send />
              </IconButton>
            </Grid>
          </GridContainer>
        </form>
      </div>
    </>
  );
};

export default withStyles(messageTextBoxStyle)(MessageTextBox);
