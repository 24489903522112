import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import classNames from "classnames";

// Custom Components
import { formCheckBoxStyle } from "./FormCheckBoxStyle";

class FormCheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedA: false
    };
  }

  componentDidMount = () => {
    const { isChecked } = this.props;

    if (isChecked) {
      this.setState(() => ({
        checkedA: true
      }));
    }
  };

  handleChange = name => event => {
    const { input } = this.props;
    input.onChange(event);
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const {
      classes,
      label,
      meta: { touched, error },
      disabled
    } = this.props;

    const helperTextClasses = classNames({
      [classes.helperText]: true,
      [classes.helperTextError]: error !== undefined
    });

    const uncheckedIconClasses = classNames({
      [classes.uncheckedIcon]: true,
      [classes.uncheckedIconError]: touched && error !== undefined
    });

    const checkIconComponent = <Check className={classes.checkedIcon} />;
    const unCheckIconComponent = <Check className={uncheckedIconClasses} />;

    const { checkedA } = this.state;

    const checkBoxControl = (
      <Checkbox
        checked={checkedA}
        onChange={this.handleChange("checkedA")}
        checkedIcon={checkIconComponent}
        icon={unCheckIconComponent}
        classes={{ checked: classes.checked, root: classes.root }}
        value="checkedA"
        color="primary"
        disabled={disabled}
      />
    );

    return (
      <>
        <FormControlLabel
          control={checkBoxControl}
          classes={{ label: classes.label }}
          label={label}
          className={classes.formControl}
        />
        {touched && error && (
          <FormHelperText className={helperTextClasses}>{error}</FormHelperText>
        )}
      </>
    );
  }
}

FormCheckBox.defaultProps = {
  disabled: false,
  isChecked: false
};

FormCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool
};

export default withStyles(formCheckBoxStyle)(FormCheckBox);
