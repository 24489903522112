import { ApiClientEndPointMethod } from "../EndPoint";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const UOMsEndPoint = payload => {
  let uomsUri = "UOM";

  uomsUri = payload === undefined ? uomsUri : `${uomsUri}/${payload}`;

  const uoms = ApiClientEndPointMethod({
    url: SERVICE_PROVIDERS_URL + uomsUri
  });

  try {
    return uoms.then(uomsData => {
      if (uomsData && uomsData.uoMs && uomsData.createEnabled !== undefined) {
        return {
          data: uomsData.uoMs.map(({ id, label, description }) => ({
            id,
            label,
            description
          })),
          createEnabled: uomsData.createEnabled
        };
      }

      if (
        uomsData &&
        uomsData.uomDetail &&
        uomsData.uomDetail.requestStatus === 1
      ) {
        return { data: { ...uomsData.uomDetail } };
      }

      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
