import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// actions
import { confirmEmail } from "../../Actions/AuthActions";

// Components
import BaseRedirectContainer from "../BaseRedirectContainer";
import ConfirmEmail from "../../Components/Auth/ConfirmEmail";
import Error from "../../Components/General/Error";

class ConfirmEmailContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.state = {
      uriIdentityId: ""
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const { userId, confirmationToken } = params;

    if (confirmationToken !== undefined) {
      dispatch(
        confirmEmail({
          userIdentityId: userId,
          confirmEmailToken: confirmationToken
        })
      );
    }
    if (userId !== undefined) {
      this.setState(() => ({
        uriIdentityId: userId
      }));
    }
  };

  render = () => {
    const { authDetails, confirmEmailStatus, location } = this.props;
    const { uriIdentityId, redirect } = this.state;

    let emailConfirmed = 0;

    if (
      authDetails &&
      authDetails.userDetails &&
      authDetails.userDetails.emailConfirmed
    ) {
      emailConfirmed = 1;
    }

    if (
      confirmEmailStatus &&
      confirmEmailStatus.status &&
      confirmEmailStatus.status === "1"
    ) {
      emailConfirmed = 1;
    }

    if (
      authDetails &&
      authDetails.userDetails &&
      authDetails.userDetails.userId !== uriIdentityId
    ) {
      emailConfirmed = 2;
    }

    if (
      confirmEmailStatus &&
      confirmEmailStatus.status &&
      confirmEmailStatus.status === "0"
    ) {
      emailConfirmed = 2;
    }

    if (redirect && emailConfirmed === 1) {
      return (
        <Redirect
          to={{
            pathname: `/profile/summary/${uriIdentityId}`,
            state: location
          }}
        />
      );
    }

    return (
      <>
        {emailConfirmed <= 1 && (
          <ConfirmEmail confirmEmailStatus={emailConfirmed} />
        )}

        {emailConfirmed === 2 && (
          <Error error={{ message: "An error occured. " }} />
        )}
      </>
    );
  };
}

ConfirmEmailContainer.propTypes = {
  authDetails: PropTypes.object.isRequired,
  confirmEmailStatus: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemDetail: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = ({ authState, confirmEmailState }) => ({
  authDetails: authState,
  confirmEmailStatus: confirmEmailState,
  itemDetail: { updatedKey: 1 }
});

export default connect(mapStateToProps)(ConfirmEmailContainer);
