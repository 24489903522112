export const itemDetailComponentStyle = {
  subHeaderText: {
    paddingBottom: "10px"
    // borderBottom: "1px solid #ababb999"
  },
  contentLabel: {},
  contentContent: {},
  itemValueLink: {
    textDecoration: "none"
  },
  itemDetailImage: {
    width: "100px",
    overFlow: "hidden",
    "& img": {
      width: "100%"
    }
  },
  actionButtons: {
    paddingTop: "25px"
  },
  cardWrapper: {
    boxShadow: "none",
    border: "1px solid #e7e5f3ed",
    marginBottom: "10px"
  }
};
