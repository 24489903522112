import { ApiClientEndPointMethod } from "../EndPoint";
import {
  EVENTS_URL,
  STATIC_FILE_BASE_URL,
  IMAGES_URI
} from "../EndPoint/ConstantVariables";

export const eventServiceListEndPoint = payload => {
  let eventServicesUri = "EventServices";

  eventServicesUri +=
    payload && payload.eventServiceId !== undefined
      ? `/${payload.eventServiceId}`
      : "";

  const queryEventServices = ApiClientEndPointMethod({
    url: EVENTS_URL + eventServicesUri
  });

  try {
    return queryEventServices.then(eventServices => {
      if (
        eventServices &&
        eventServices.eventServices &&
        eventServices.requestStatus === 1
      ) {
        return {
          data: eventServices.eventServices.map(
            ({
              id,
              eventServiceName,
              eventServiceDescription,
              eventServiceImage,
              eventServiceColor,
              eventItems
            }) => {
              return {
                id,
                eventServiceName,
                eventServiceDescription,
                eventServiceColor,
                eventServiceImage:
                  eventServiceImage === undefined ||
                  eventServiceImage === null ||
                  eventServiceImage === ""
                    ? ""
                    : STATIC_FILE_BASE_URL + IMAGES_URI + eventServiceImage,
                eventItems:
                  eventItems === null
                    ? []
                    : JSON.parse(`[${eventItems}]`).map(
                        ({
                          eventItemID,
                          mappingID,
                          eventItemName,
                          eventImageID,
                          eventImage
                        }) => ({
                          eventItemID,
                          mappingID,
                          eventItemName,
                          eventImageID,
                          eventImage:
                            eventImage === null ||
                            eventImage === undefined ||
                            eventImage === ""
                              ? ""
                              : STATIC_FILE_BASE_URL + IMAGES_URI + eventImage
                        })
                      )
              };
            }
          )
        };
      }
      if (
        eventServices &&
        eventServices.eventServiceDetails &&
        eventServices.requestStatus === 1
      ) {
        const eventServiceImage =
          eventServices.eventServiceDetails.eventServiceImage === null ||
          eventServices.eventServiceDetails.eventServiceImage === undefined ||
          eventServices.eventServiceDetails.eventServiceImage === ""
            ? ""
            : STATIC_FILE_BASE_URL +
              IMAGES_URI +
              eventServices.eventServiceDetails.eventServiceImage;

        return {
          data: [
            {
              ...eventServices.eventServiceDetails,
              ...{
                eventServiceImage,
                eventItems:
                  eventServices.eventServiceDetails.eventItems === null
                    ? []
                    : JSON.parse(
                        `[${eventServices.eventServiceDetails.eventItems}]`
                      ).map(
                        ({
                          eventItemID,
                          mappingID,
                          eventItemName,
                          eventImageID,
                          eventImage
                        }) => ({
                          eventItemID,
                          mappingID,
                          eventItemName,
                          eventImageID,
                          eventImage:
                            eventImage === null ||
                            eventImage === undefined ||
                            eventImage === ""
                              ? ""
                              : STATIC_FILE_BASE_URL + IMAGES_URI + eventImage
                        })
                      )
              }
            }
          ]
        };
      }
      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
