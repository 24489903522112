export const sortButtonStyle = {
  sortGroup: {
    display: "table-cell"
  },
  housesSort: {
    display: "table-cell",
    verticalAlign: "middle",
    "&>svg": {
      fontSize: "20px"
    }
  },
  housesSortSelect: {
    overflow: "hidden",
    "& div button": {
      textTransform: "capitalize",
      padding: "0px 8px",
      minHeight: "30px",
      fontSize: "13px",
      backgroundColor: "#FFF",
      "& svg": {
        fontSize: "20px",
        marginRight: "2px"
      }
    },
    "& div ul": {
      paddingTop: "0px",
      paddingBottom: "0px"
    },
    "& div ul>li": {
      fontSize: "13px",
      padding: "10px"
    }
  }
};
