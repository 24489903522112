import { ApiClientEndPointMethod } from "../EndPoint/index";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const serviceProviderDetailEndPoint = payload => {
  const uri =
    payload === "" || payload === undefined
      ? "ServiceProviders"
      : `ServiceProviders/${payload.id}/${payload.serviceProviderIdentityId}`;

  const spDetailData = ApiClientEndPointMethod({
    url: SERVICE_PROVIDERS_URL + uri
  });

  try {
    return spDetailData.then(spData => {
      if (spData && spData.serviceProviders) {
        return {
          data: spData.serviceProviders.map(
            ({
              serviceProviderName,
              serviceProviderDescription,
              serviceProviderStatus,
              serviceProviderIdentityId
            }) => {
              return {
                serviceProviderName,
                serviceProviderDescription,
                serviceProviderStatus,
                serviceProviderIdentityId
              };
            }
          ),
          createEnabled: true
        };
      }
      if (spData && spData.serviceProviderDetails) {
        return {
          data: [
            {
              ...spData.serviceProviderDetails
            }
          ]
        };
      }

      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
