import { ApiClientEndPointMethod } from "../EndPoint/index";
import {
  STATIC_FILE_BASE_URL,
  IMAGES_URI,
  EVENTS_URL
} from "../EndPoint/ConstantVariables";

export const eventItemsUri = "EventItems";

export function eventItemsEndPoint(payload) {
  const eventsUri =
    payload === undefined ? eventItemsUri : `${eventItemsUri}/${payload}`;

  const eventItemsData = ApiClientEndPointMethod({
    url: EVENTS_URL + eventsUri
  });

  try {
    return eventItemsData.then(e => {
      if (e && e.eventItems) {
        return {
          data: e.eventItems.map(
            ({
              id,
              eventName,
              eventDescription,
              eventCategoryName,
              eventItemServices,
              eventImage
            }) => {
              return {
                id,
                eventName,
                eventDescription,
                eventCategoryName,
                eventImage:
                  eventImage === null ||
                  eventImage === undefined ||
                  eventImage === ""
                    ? ""
                    : STATIC_FILE_BASE_URL + IMAGES_URI + eventImage,
                eventItemServices:
                  eventItemServices === null
                    ? []
                    : JSON.parse(`[${eventItemServices}]`)
              };
            }
          ),
          createEnabled: e.createEnabled
        };
      }
      // for single event Item
      if (e && e.eventItemDetails && e.requestStatus === 1) {
        const eventImage =
          e.eventItemDetails.eventImage === null ||
          e.eventItemDetails.eventImage === undefined ||
          e.eventItemDetails.eventImage === ""
            ? ""
            : STATIC_FILE_BASE_URL + IMAGES_URI + e.eventItemDetails.eventImage;

        return {
          data: [
            {
              ...e.eventItemDetails,
              ...{
                eventImage,
                eventItemServices: JSON.parse(
                  `[${e.eventItemDetails.eventItemServices}]`
                ).map(
                  ({
                    eventServiceID,
                    mappingID,
                    eventServiceName,
                    eventServiceColor,
                    eventServiceImage,
                    serviceProvidersCount
                  }) => ({
                    id: eventServiceID,
                    mappingID,
                    eventServiceName,
                    eventServiceColor,
                    eventServiceImage:
                      eventServiceImage === null ||
                      eventServiceImage === undefined ||
                      eventServiceImage === ""
                        ? ""
                        : STATIC_FILE_BASE_URL + IMAGES_URI + eventServiceImage,
                    serviceProvidersCount
                  })
                )
              }
            }
          ]
        };
      }
      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
}
