/* eslint-disable */
import { Component } from "react";

class BaseRedirectContainer extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false
    };
  }

  componentWillReceiveProps = nextProps => {
    const { itemDetail } = nextProps;


    if (
      itemDetail &&
      itemDetail.updatedKey &&
      itemDetail.updatedKey !== undefined 
    ) {
      //redirect after two seconds
      setTimeout(() => {
        this.setState({
          redirect: true
        });
      }, 200);
    }
  };
}

export default BaseRedirectContainer;