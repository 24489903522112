export const customChipStyle = () => ({
  chip: {
    margin: "4px",
    fontSize: "10px",
    height: "auto",
    "& span": {
      padding: "5px 10px"
    }
  }
});
