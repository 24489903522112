import { call, put, takeLatest } from "redux-saga/effects";

// Action Types
import {
  FETCH_CONVERSATION,
  FETCHED_CONVERSATION,
  FETCH_CONVERSATION_FAILURE,
  ADD_MESSAGE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE
} from "../Constants/MessagesActionTypes";

// Endpoint
import { messagesConversitionListEndPoint } from "../ApiClient/Messages/MessagesConversationList";
import { profileDetailsEndPoint } from "../ApiClient/UserProfile/ProfileDetails";
import { messageAddEndPoint } from "../ApiClient/Messages/MessagesAdd";

export function* watchMessagesConversation() {
  yield takeLatest(
    [FETCH_CONVERSATION, ADD_MESSAGE],
    handleMessagesConversation
  );
}

export function* handleMessagesConversation(payload) {
  switch (payload.type) {
    case FETCH_CONVERSATION: {
      const convoList = yield call(
        messagesConversitionListEndPoint,
        payload.payload
      );

      if (convoList && convoList.data) {
        yield put({
          type: FETCHED_CONVERSATION,
          payload: convoList
        });
      } else {
        yield put({
          type: FETCH_CONVERSATION_FAILURE,
          payload: convoList
        });
      }

      break;
    }
    case ADD_MESSAGE: {
      const targetConverseeDetails = yield call(
        profileDetailsEndPoint,
        payload.payload.RecipientId
      );

      if (targetConverseeDetails && targetConverseeDetails.data) {
        const messagePayload = {
          ...payload.payload,
          ...{
            RecipientName: `${payload.payload.firstName} ${
              payload.payload.lastName
            }`
          }
        };

        const addMessageResult = yield call(messageAddEndPoint, messagePayload);

        if (addMessageResult && addMessageResult.data) {
          yield put({
            type: ADD_MESSAGE_SUCCESS,
            payload: addMessageResult
          });
        } else {
          yield put({
            type: ADD_MESSAGE_FAILURE,
            payload: addMessageResult
          });
        }
      } else {
        yield put({
          type: ADD_MESSAGE_FAILURE,
          payload: { error: "Failed to add Message" }
        });
      }

      break;
    }

    default:
      break;
  }
}
