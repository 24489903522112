/* eslint no-use-before-define: ["error", { "functions": false }] */
/* global localStorage, btoa, atob */
import { call, put, takeLatest } from "redux-saga/effects";

// End point
import { authUserEndPoint } from "../ApiClient/AuthEndPoint/AuthUser";
import { confirmEmailEndPoint } from "../ApiClient/AuthEndPoint/ConfirmEmail";
import { serviceProviderDetailEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderDetail";
import {
  STORAGE_KEY,
  MY_TOKEN_KEY
} from "../ApiClient/EndPoint/ConstantVariables";

import {
  SUBMIT_SIGN_IN,
  SUBMIT_SIGN_UP,
  SIGN_UP_SUCCESSFUL,
  SIGN_IN_SUCCESSFUL,
  SIGNING_IN,
  SIGN_IN_FAILED,
  SIGN_UP_FAILED,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_FAILED,
  CONFIRM_EMAIL_SUCCESSFUL,
  CLEAR_SIGNED_IN_PROFILE,
  CLEARED_SIGNED_IN_PROFILE,
  REFRESH_SIGN_IN
} from "../Constants/AuthActionTypes";

import { parseJwt } from "../Helpers/General";

export function* watchAuth() {
  yield takeLatest(
    [SUBMIT_SIGN_IN, SUBMIT_SIGN_UP, CONFIRM_EMAIL, CLEAR_SIGNED_IN_PROFILE],
    handleAuth
  );
}

export function* watchRefreshSignin() {
  yield takeLatest([REFRESH_SIGN_IN], handleAuth);
}

export function* handleAuth(payload) {
  switch (payload.type) {
    case SUBMIT_SIGN_IN: {
      const authUserData = yield call(authUserEndPoint, payload.payload);

      yield put({ type: SIGNING_IN });

      if (authUserData && authUserData.data) {
        let spData;

        if (authUserData.data.userDetails.isServiceProvider) {
          spData = yield call(serviceProviderDetailEndPoint, {
            id: -99,
            serviceProviderIdentityId: authUserData.data.userDetails.userId
          });
        }

        const payloadData =
          spData && spData.data
            ? {
                ...authUserData.data,
                ...{ spDetails: spData.data[0] }
              }
            : { ...authUserData.data };

        manageTokenStorage(authUserData.data);

        yield put({ type: SIGN_IN_SUCCESSFUL, payload: payloadData });
      } else {
        yield put({ type: SIGN_IN_FAILED, payload: authUserData });
      }

      break;
    }
    case SUBMIT_SIGN_UP: {
      yield put({ type: SIGNING_IN });

      const registerUserData = yield call(authUserEndPoint, payload.payload);
      if (registerUserData && registerUserData.data) {
        manageTokenStorage(registerUserData.data);

        yield put({
          type: SIGN_UP_SUCCESSFUL,
          payload: {
            ...registerUserData.data,
            ...{ emailSent: true }
          }
        });
      } else {
        yield put({
          type: SIGN_UP_FAILED,
          payload: registerUserData
        });
      }
      break;
    }

    case CONFIRM_EMAIL: {
      const confirmEmailDetail = yield call(
        confirmEmailEndPoint,
        payload.payload
      );

      if (confirmEmailDetail && confirmEmailDetail.data) {
        yield put({
          type: CONFIRM_EMAIL_SUCCESSFUL,
          payload: { status: "1" }
        });
      } else {
        yield put({
          type: CONFIRM_EMAIL_FAILED,
          payload: { status: "0" }
        });
      }

      break;
    }

    case REFRESH_SIGN_IN: {
      yield put({ type: SIGNING_IN });
      // get user data local Storage
      const userEncodeData = localStorage.getItem(STORAGE_KEY);
      if (userEncodeData !== undefined) {
        try {
          const decodedData = JSON.parse(atob(userEncodeData));
          const { userToken } = decodedData;
          const { accessToken, refreshToken } = userToken;

          const { token } = accessToken;

          const jwtPayload = parseJwt(token);

          // eslint-disable-next-line no-bitwise
          const currentTimeStamp = (Date.now() / 1000) | 0;

          if (currentTimeStamp > jwtPayload.exp) {
            const refreshedUserData = yield call(authUserEndPoint, {
              accessToken: token,
              refreshToken
            });

            if (refreshedUserData && refreshedUserData.data) {
              manageTokenStorage(refreshedUserData.data);
              yield put({
                type: SIGN_IN_SUCCESSFUL,
                payload: refreshedUserData.data
              });
            } else {
              yield put({ type: SIGN_IN_FAILED, payload: refreshedUserData });
            }
          } else {
            yield put({
              type: SIGN_IN_SUCCESSFUL,
              payload: decodedData
            });
          }
        } catch (error) {
          yield put({
            type: SIGN_IN_FAILED,
            payload: error
          });
        }
      } else {
        yield put({
          type: SIGN_IN_FAILED,
          payload: { error: "Sign in failed" }
        });
      }
      break;
    }

    case CLEAR_SIGNED_IN_PROFILE: {
      clearTokens();
      yield put({ type: CLEARED_SIGNED_IN_PROFILE });
      break;
    }

    default:
      break;
  }
}

export function clearTokens() {
  localStorage.removeItem(STORAGE_KEY);
  localStorage.removeItem(MY_TOKEN_KEY);
}

export function manageTokenStorage(userData) {
  if (userData && userData.userToken) {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(MY_TOKEN_KEY);
    localStorage.setItem(STORAGE_KEY, btoa(JSON.stringify(userData)));
    localStorage.setItem(MY_TOKEN_KEY, userData.userToken.accessToken.token);
  }
}
