import { ApiClientEndPointMethod } from "../EndPoint";
import { USERS_URL } from "../EndPoint/ConstantVariables";

export const authUserEndPoint = payload => {
  if (payload && (payload.userEmail !== undefined || payload.refreshToken)) {
    const bodyContent =
      payload && payload.isSignIn
        ? JSON.stringify({
            userEmail: payload.userEmail,
            password: payload.userPassword
          })
        : JSON.stringify(payload);

    let uri = "";

    if (payload.isSignIn) {
      uri = "Auth/";
    } else if (payload.refreshToken) {
      uri = "Auth/RefreshToken/";
    } else {
      uri = "Users/";
    }

    const authData = ApiClientEndPointMethod({
      url: USERS_URL + uri,
      customBodyOptions: {
        body: bodyContent
      },
      method: "POST"
    });

    try {
      return authData.then(userData => {
        if (userData && userData.requestStatus === 1) {
          return { data: userData };
        }
        if (userData && userData.requestStatus === 0) {
          return { error: userData.statusMessage };
        }
        return { error: "An unknown error occured" };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "No Data to Submit" };
  }
};
