import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

// actions
import { fetchUser } from "../../Actions/UserProfileActions";
import { clearSignIn } from "../../Actions/AuthActions";

// Component
import ProfileSettings from "../../Components/Profile/ProfileSettings";

class ProfileSettingsContainer extends Component {
  constructor() {
    super();
    this.state = {
      userProfileId: ""
    };
    this.handleUpdateSettings = this.handleUpdateSettings.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount = () => {
    const { userProfileId, dispatch } = this.props;
    dispatch(fetchUser(userProfileId));
    this.setState({
      userProfileId
    });
  };

  handleUpdateSettings = values => {
    const { userProfileId } = this.state;

    console.log(userProfileId, values);
  };

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(clearSignIn());
  };

  render = () => {
    const { authState, handleSubmit } = this.props;

    return (
      <ProfileSettings
        authdetails={authState}
        handleUpdateSettings={handleSubmit(this.handleUpdateSettings)}
        handleLogout={this.handleLogout}
      />
    );
  };
}

ProfileSettingsContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired,
  userProfileId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({ authState }) => ({
  authState
});

const ProfileSettingsFormed = reduxForm({ form: "profile-settings" })(
  ProfileSettingsContainer
);

export default connect(mapStateToProps)(ProfileSettingsFormed);
