export const eventServiceStyle = theme => ({
  cardWrapper: {
    // boxShadow: "rgba(0, 0, 0, 0.1) 1px 2px 4px"
    boxShadow: "none",
    border: "1px solid #e7e5f3ed"
  },
  imageWrapper: {
    textAlign: "center"
  },
  media: {
    width: "auto",
    display: "initial",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px"
  },
  customCardBody: {
    padding: "15px 20px 0px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px"
  },
  serviceName: {
    paddingTop: "4px",
    textTransform: "capitalize",
    fontSize: "15px",
    "& a": {
      color: "inherit",
      textDecoration: "none"
    }
  },
  serviceProvidersWrapper: {
    paddingTop: "5px",
    "& svg": {
      fontSize: "15px"
    },
    "& span": {
      fontSize: "11px",
      color: "#888"
    }
  },
  cardActions: {
    display: "flex",
    padding: "10px 15px 10px 10px"
  },
  selectButton: {
    backgroundColor: "#EEE",
    "&:hover,:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF"
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    "&:hover,:focus": {
      backgroundColor: "#EEE",
      color: theme.palette.primary.main
    }
  },
  cardActionsLeft: {
    backgroundColor: "transparent",
    color: "#222",
    "&:hover,:focus": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent"
    },
    "& svg": {
      fontSize: "20px",
      marginRight: "5px"
    }
  },
  cardActionsFarLeftIcons: {
    marginLeft: "auto",
    minWidth: "25px",
    minHeight: "25px",
    fontSize: "12px",
    "& svg": {
      fontSize: "15px"
    }
  }
});
