import { all, fork } from "redux-saga/effects";

// Custom Components
import { watchEvents } from "./EventsSaga";
import { watchAuth, watchRefreshSignin } from "./AuthSaga";
import {
  watchServiceProviders,
  watchExtendedServiceProviders
} from "./ServiceProvidersSaga";
import { watchEventServices } from "./EventServicesSaga";
import { wathUserProfile } from "./UserProfileSaga";
import { watchCities } from "./CitiesSaga";
import { wacthUOMs } from "./UOMsSaga";
import { watchCountries } from "./CountriesSaga";
import { watchMessagesSummary } from "./MessagesSummarySaga";
import { watchMessagesConversation } from "./MessagesConversationSaga";
import { watchResetPassword } from "./ResetPasswordSaga";
import { watchUpdatePassword } from "./UpdatePasswordSaga";

// Here, we register our watcher saga(s) and export as a single generator
export default function* appSagas() {
  yield all([
    fork(watchEvents),
    fork(watchAuth),
    fork(watchRefreshSignin),
    fork(watchServiceProviders),
    fork(watchExtendedServiceProviders),
    fork(watchEventServices),
    fork(wathUserProfile),
    fork(watchCities),
    fork(wacthUOMs),
    fork(watchCountries),
    fork(watchMessagesSummary),
    fork(watchMessagesConversation),
    fork(watchResetPassword),
    fork(watchUpdatePassword)
  ]);
}
