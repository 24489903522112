export const fabButtonStyle = theme => ({
  buttonWrapper: {
    position: "fixed",
    zIndex: "5",
    bottom: "1.5vh",
    width: "100%",
    backgroundColor: "transparent",
    padding: "4px",
    textAlign: "center"
  },
  button: {
    textTransform: "inherit",
    backgroundColor: theme.palette.primary.main,
    fontSize: "13px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#190eb0"
    }
  }
});
