import { container } from "../Style";

export const headerStyle = theme => ({
  appBar: {
    display: "inline-block",
    border: "0",
    borderRadius: "0px",
    padding: "0.625rem 0",
    color: theme.palette.primary.white,
    width: "100%",
    boxShadow: "none",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative",
    zIndex: "unset",
    height: "62px",
    paddingTop: "0px"
  },
  headerBoxShadow: {
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  container: {
    ...container,
    minHeight: "50px",
    flex: "1",
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    paddingLeft: "0px"
  },
  absolute: {
    position: "absolute"
  },
  fixed: {
    position: "relative",
    zIndex: "1100"
  },
  flex: {
    flex: 1,
    width: "50vw"
  },

  searchFlex: {
    flex: 1,
    textAlign: "end"
  },
  appTitle: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px"
    },

    [theme.breakpoints.up("sm")]: {
      width: "26%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "80vw"
    }
  },
  searchSection: {
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "10vw"
    }
  },
  rightActions: {
    display: "flex",
    textAlign: "end",
    [theme.breakpoints.up("sm")]: {
      width: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto"
    }
  },
  title: {
    lineHeight: "30px",
    fontSize: "25px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    "& img": {
      height: "24px",
      marginTop: "18px"
    },
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  hideLogoforMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  smDownSearchButton: {
    marginLeft: "auto",
    minWidth: "25px",
    minHeight: "25px",
    fontSize: "12px",
    color: "#FFF"
  }
});
