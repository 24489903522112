// Backend Base Url
const BACKEND_BASE_URL = "https://backend.upfik.com";
// const BACKEND_BASE_URL = "http://localhost:5200";

// Static Base Url
export const STATIC_FILE_BASE_URL = "https://eventofiles.upfik.com/";
// export const STATIC_FILE_BASE_URL = "http://localhost:8083/";

// App Base Url
export const APP_BASE_URL = "https://evento.upfik.com/";
// Api Base Url
export const EVENTS_URL = `${BACKEND_BASE_URL}/events/`;

// Files base Url
export const FILES_URL = `${BACKEND_BASE_URL}/files/`;

// Auth & Users Url
export const USERS_URL = `${BACKEND_BASE_URL}/users/`;

// Service Providers Uri
export const SERVICE_PROVIDERS_URL = `${BACKEND_BASE_URL}/serviceproviders/`;

// Messages Uri
export const MESSAGES_URL = `${BACKEND_BASE_URL}/messages/`;

// Local Storage  Key
export const STORAGE_KEY = "DO-NOT-SHARE-THIS-WITH-ANYONE-WHO-ASKS";

// Token
export const MY_TOKEN_KEY = "MY_TOKEN";

export const IMAGES_URI = "Staticfiles/images/";
