export const accountProfileStyle = theme => ({
  root: {},
  details: {
    display: "flex"
  },
  cardWrapper: {
    boxShadow: "none",
    border: "1px solid #e7e5f3ed",
    marginBottom: "10px",
    minHeight: "165px"
  },
  detailBody: {
    padding: "10px 15px 0px 15px",
    fontSize: "12px"
  },
  avatar: {
    marginLeft: "auto",
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  error: {
    color: "#fff",
    fontWeight: "500",
    backgroundColor: "#f44336",
    display: "flex",
    padding: "6px 16px",
    lineHeight: "1.43",
    borderRadius: "4px",
    letterSpacing: "0.01071em"
  },
  success: {
    color: "#fff",
    fontWeight: "500",
    backgroundColor: "#4caf50",
    display: "flex",
    padding: "6px 16px",
    lineHeight: "1.43",
    borderRadius: "4px",
    letterSpacing: "0.01071em"
  }
});
