import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import withStyles from "@material-ui/core/styles/withStyles";

// Styles
import { contentModalStyle } from "./ContentModalStyle";

const ContentModal = ({ ...contentModalProps }) => {
  const {
    classes,
    isOpen,
    handleClose,
    modalTitle,
    modalContent
  } = contentModalProps;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">{modalTitle}</h2>
          <div id="transition-modal-description">{modalContent}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default withStyles(contentModalStyle)(ContentModal);
