export const eventItemStyle = theme => ({
  card: {
    marginBottom: "10px",
    marginTop: "10px",
    textAlign: "center",
    // boxShadow: "rgba(0, 0, 0, 0.1) 1px 2px 4px"
    boxShadow: "none",
    border: "1px solid #e7e5f3ed"
  },
  cardBody: {
    padding: "10px 10px",
    flex: "1 1 auto"
  },
  subHeaderText: {
    padding: "5px 0px"
  },
  servicesList: {
    minHeight: "30px",
    height: "30px",
    overflow: "hidden"
  },
  housesEventImage: {
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      height: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      height: "50%"
    }
  },
  linkStyle: {
    textDecoration: "none"
  },
  actionPartStyle: {
    padding: "0px 15px 10px",
    "&  span": {
      textTransform: "uppercase",
      fontSize: "14px"
    }
  },
  cardActions: {
    display: "flex",
    padding: "10px 15px"
  },
  cardActionsFarLeftIcons: {
    marginLeft: "auto",
    minWidth: "25px",
    minHeight: "25px",
    fontSize: "13px",
    color: "#222",
    "&:hover,:focus": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent"
    },
    "& svg": {
      fontSize: "15px",
      marginLeft: "2px"
    }
  }
});
