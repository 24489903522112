export const messageTextBoxStyle = {
  fieldSetWrapper: {
    width: "100%"
  },
  sendButton: {
    "& svg": {
      width: "35px",
      height: "35px"
    }
  }
};
