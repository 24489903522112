import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Actions
import { fetchEventItem } from "../../Actions/EventsActions";
import { fetchEventServices } from "../../Actions/EventServiceActions";

// Containers
import SelectElementContainer from "../General/SelectElementContainer";
import SearchFormed from "../Search";

// custom Components
import EventItemServices from "../../Components/EventServices/EventItemServices";
import Error from "../../Components/General/Error";
import Loader from "../../Components/General/Loader";

// Helpers
import { removeElementFromAnArray } from "../../Helpers/General";

class EventItemServicesContainer extends Component {
  constructor() {
    super();
    this.state = {
      checkedEventServices: []
    };
  }

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    const { eventItemId } = match.params;

    if (eventItemId === undefined) {
      dispatch(fetchEventServices());
    } else {
      dispatch(fetchEventItem(eventItemId));
    }
  };

  handleSelectElement = elementKey => () => {
    this.setState(prevState => {
      const allCheckedEventServices =
        prevState.checkedEventServices.indexOf(elementKey) === -1
          ? [...prevState.checkedEventServices, ...[elementKey]]
          : removeElementFromAnArray(
              prevState.checkedEventServices,
              elementKey
            );

      return {
        ...prevState,
        ...{ checkedEventServices: allCheckedEventServices }
      };
    });
  };

  render() {
    const { eventItem, eventServices } = this.props;
    const { checkedEventServices } = this.state;

    const searchComponent = <SearchFormed form="eventServicesSearch" />;

    const selectedServicesUri = `/event-services/${checkedEventServices.join(
      "-_-"
    )}/service-providers`;

    const defaultEventDetails =
      eventServices && eventServices.data
        ? {
            eventName: "All Events",
            eventImage: "",
            id: "all"
          }
        : "";

    return (
      <>
        {eventItem && eventItem.data && defaultEventDetails === "" && (
          <EventItemServices
            eventItem={eventItem.data[0]}
            eventServices={eventItem.data[0].eventItemServices}
            searchComponent={searchComponent}
            handleSelectElement={this.handleSelectElement}
            SelectElement={SelectElementContainer}
            selectedEventServices={checkedEventServices}
            selectedServicesUri={selectedServicesUri}
            showSubHeader
          />
        )}
        {eventServices && eventServices.data && defaultEventDetails !== "" && (
          <EventItemServices
            eventItem={defaultEventDetails}
            eventServices={eventServices.data}
            searchComponent={searchComponent}
            handleSelectElement={this.handleSelectElement}
            SelectElement={SelectElementContainer}
            selectedEventServices={checkedEventServices}
            selectedServicesUri={selectedServicesUri}
            showSubHeader
          />
        )}

        {eventItem && eventItem.error && (
          <Error
            error={{ message: "An error occured while loading services" }}
          />
        )}

        {eventServices && eventServices.error && (
          <Error
            error={{ message: "An error occured while loading services" }}
          />
        )}

        {(eventItem || eventServices) &&
          !(
            eventItem.error ||
            eventServices.error ||
            (eventItem.data || eventServices.data)
          ) && <Loader />}
      </>
    );
  }
}

EventItemServicesContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  eventItem: PropTypes.object.isRequired,
  eventServices: PropTypes.object.isRequired
};

const mapStateToProps = ({ eventsState, eventServicesState }) => ({
  eventItem: eventsState,
  eventServices: eventServicesState
});

export default connect(mapStateToProps)(EventItemServicesContainer);
