import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Actions
import { resetPassword } from "../../Actions/AuthActions";

// Containers
import ProfileSummaryContainer from "./ProfileSummaryContainer";
import ProfileSettingsFormed from "./ProfileSettingsContainer";

// Components
import Profile from "../../Components/Profile";
import AccountProfile from "../../Components/General/AccountProfile";
import Loader from "../../Components/General/Loader";

class ProfileContainer extends Component {
  constructor() {
    super();
    this.state = {
      userId: "",
      activeTab: ""
    };

    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentDidMount = () => {
    const { match } = this.props;
    const { profileItem, profileItemId } = match.params;

    this.setState({
      userId: profileItemId,
      activeTab: profileItem
    });
  };

  componentWillReceiveProps = nextProps => {
    const { match } = nextProps;
    const { profileItem, profileItemId } = match.params;

    const { activeTab } = this.state;

    if (activeTab !== profileItem) {
      this.setState({
        userId: profileItemId,
        activeTab: profileItem
      });
    }
  };

  handleResetPassword = () => {
    const { dispatch, authState } = this.props;

    if (authState && authState.userDetails && authState.userDetails.userEmail) {
      dispatch(resetPassword({ userEmail: authState.userDetails.userEmail }));
    }
  };

  render() {
    const { userProfileState, authState, resetPasswordState } = this.props;
    const { userId, activeTab } = this.state;

    const { userDetails } = authState;

    const { userId: targetUserId } = userDetails;

    let targetComponent = "";

    if (activeTab && activeTab.includes("summary")) {
      targetComponent = <ProfileSummaryContainer userProfileId={userId} />;
    }

    if (activeTab && activeTab.includes("settings")) {
      targetComponent = <ProfileSettingsFormed userProfileId={userId} />;
    }

    const profileComponent =
      userProfileState && userProfileState.data ? (
        <AccountProfile
          profileDetails={{
            name: `${userProfileState.data[0].firstName} ${
              userProfileState.data[0].lastName
            }`
          }}
          editEnabled={targetUserId === userProfileState.data[0].userId}
          handleResetPassword={this.handleResetPassword}
          resetPasswordStatus={resetPasswordState}
        />
      ) : (
        <Loader />
      );

    return (
      <Profile
        userProfileId={userId}
        profileComponent={profileComponent}
        targetComponent={targetComponent}
        activeTab={activeTab}
      />
    );
  }
}

ProfileContainer.propTypes = {
  authState: PropTypes.object.isRequired,
  userProfileState: PropTypes.object.isRequired,
  resetPasswordState: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({
  authState,
  userProfileState,
  resetPasswordState
}) => ({
  authState,
  userProfileState,
  resetPasswordState
});

export default connect(mapStateToProps)(ProfileContainer);
