import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Containers
import MessageListContainer from "./MessageListContainer";
import MessageConversationContainer from "./MessageConversationContainer";

// Components
import GridContainer from "../../Components/General/GridContainer";

class MessagesContainer extends Component {
  constructor() {
    super();
    this.state = {
      targetMessageItemId: "",
      userId: ""
    };
  }

  componentDidMount = () => {
    const { authState, messageItemId } = this.props;

    this.setState({
      targetMessageItemId: messageItemId,
      userId:
        authState.userDetails !== undefined
          ? authState.userDetails.userProfileId
          : ""
    });
  };

  componentWillReceiveProps = nextProps => {
    const { messageItemId, authState } = nextProps;
    const { targetMessageItemId, userId } = this.state;

    if (messageItemId !== targetMessageItemId) {
      this.setState({
        targetMessageItemId: messageItemId
      });
    }

    if (
      authState &&
      authState.userDetails &&
      authState.userDetails.userProfileId !== undefined &&
      authState.userDetails.userProfileId !== userId
    ) {
      this.setState({
        userId: authState.userDetails.userProfileId
      });
    }
  };

  render = () => {
    const { userId, targetMessageItemId } = this.state;

    const { targetMessageUriPrefix } = this.props;

    return (
      <>
        <GridContainer>
          <MessageListContainer
            userId={userId}
            targetConversationId={targetMessageItemId}
            targetMessageUriPrefix={targetMessageUriPrefix}
          />

          <MessageConversationContainer
            targetConversationId={targetMessageItemId}
          />
        </GridContainer>
      </>
    );
  };
}

MessagesContainer.propTypes = {
  authState: PropTypes.object.isRequired,
  targetMessageUriPrefix: PropTypes.string.isRequired,
  messageItemId: PropTypes.string
};

MessagesContainer.defaultProps = {
  messageItemId: "all"
};

const mapStateToProps = ({ authState }) => ({
  authState
});

export default connect(mapStateToProps)(MessagesContainer);
