/* eslint-disable react/prop-types, react/jsx-handler-names */

import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import NoSsr from "@material-ui/core/NoSsr";
import Select from "react-select";

import withStyles from "@material-ui/core/styles/withStyles";

// Styles
import { formSelectBoxStyle } from "./FormSelectBoxStyle";

// Components
const NoOptionsMessage = props => {
  const { children, innerProps, selectProps } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  const { selectProps, innerRef, children, innerProps } = props;
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children,
          ...innerProps
        }
      }}
      {...selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  const { innerRef, isFocused, isSelected, innerProps, children } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        background: isFocused ? "#150b6a" : "inherit",
        color: isFocused ? "#FFF" : "inherit"
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

const Placeholder = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
};

const ValueContainer = props => {
  const { selectProps, children } = props;
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
};

const Menu = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Paper square className={selectProps.classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const selectTheme = theme => {
  return {
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: "#f5f5f5",
      primary50: "#f1f1f1",
      primary: "#0f0937"
    }
  };
};

class FormSelectBox extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = () => value => {
    const { input } = this.props;

    input.onChange(value);
  };

  render() {
    const {
      classes,
      isMulti,
      label,
      selectOptions,
      input,
      isClearable
    } = this.props;

    const selectStyles = {
      input: base => {
        return {
          ...base,
          "& input": {
            font: "inherit"
          }
        };
      }
    };

    return (
      <NoSsr>
        <div className={classes.wrapper}>
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label,
              InputLabelProps: {
                shrink: true
              }
            }}
            theme={selectTheme}
            options={selectOptions}
            components={components}
            value={input.value}
            onChange={this.handleChange()}
            isClearable={isClearable !== false}
            placeholder="Type to search....."
            isMulti={isMulti}
          />
        </div>
      </NoSsr>
    );
  }
}

export default withStyles(formSelectBoxStyle)(FormSelectBox);
