import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// ACtions
import { fetchServiceProviderExtendedDetails } from "../../Actions/ServiceProviderActions";

// Components
import ServiceProviderSummary from "../../Components/ServiceProviders/ServiceProviderSummary";
import Loader from "../../Components/General/Loader";
import Error from "../../Components/General/Error";

class ServiceProviderSummaryContainer extends Component {
  componentDidMount = () => {
    const { serviceProviderId, dispatch } = this.props;

    dispatch(
      fetchServiceProviderExtendedDetails({
        id: serviceProviderId,
        serviceProviderIdentityId: "-99"
      })
    );
  };

  render = () => {
    const { serviceProviderExtendedDetails } = this.props;

    return (
      <>
        {serviceProviderExtendedDetails &&
          serviceProviderExtendedDetails.spExtProfile && (
            <ServiceProviderSummary
              spExtendedDetails={serviceProviderExtendedDetails}
            />
          )}
        {serviceProviderExtendedDetails &&
          serviceProviderExtendedDetails.error && (
            <Error
              error={{
                message: "An error occured while service provider data "
              }}
            />
          )}
        {serviceProviderExtendedDetails &&
          !serviceProviderExtendedDetails.data &&
          !serviceProviderExtendedDetails.spExtProfile && <Loader />}
      </>
    );
  };
}

ServiceProviderSummaryContainer.propTypes = {
  serviceProviderId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  serviceProviderExtendedDetails: PropTypes.object.isRequired
};

const mapStateToProps = ({ serviceProviderExtendedDetailsState }) => ({
  serviceProviderExtendedDetails: serviceProviderExtendedDetailsState
});

export default connect(mapStateToProps)(ServiceProviderSummaryContainer);
