// Messages
export const FETCH_MESSAGE = "FETCH_MESSAGE";
export const FETCH_MESSAGES = "FETCH_MESSAGES";
export const FETCH_MESSAGE_WRONG_URI = "FETCH_MESSAGE_WRONG_URI";
export const FETCH_MESSAGE_FAILURE = "FETCH_MESSAGE_FAILURE";

export const FETCHED_MESSAGE = "FETCHED_MESSAGE";
export const FETCHED_MESSAGES = "FETCHED_MESSAGES";

export const LOADING_MESSAGE = "LOADING_MESSAGE";

export const ADD_MESSAGE = "ADD_MESSAGE";
export const ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS";

export const ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE";

// Conversation
export const FETCH_CONVERSATION = "FETCH_CONVERSATION";
export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";
export const FETCH_CONVERSATION_WRONG_URI = "FETCH_CONVERSATION_WRONG_URI";
export const FETCH_CONVERSATION_FAILURE = "FETCH_CONVERSATION_FAILURE";

export const FETCHED_CONVERSATION = "FETCHED_CONVERSATION";
export const FETCHED_CONVERSATIONS = "FETCHED_CONVERSATIONS";

export const LOADING_CONVERSATION = "LOADING_CONVERSATION";
