import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Containers
import ServiceProviderSummaryContainer from "./ServiceProviderSummaryContainer";
import MessagesContainer from "../Messages";
import SelectElementContainer from "../General/SelectElementContainer";

// Components
import ServiceProviderDetail from "../../Components/ServiceProviders/ServiceProviderDetail";

import ServiceProviderMiniProfileDetail from "../../Components/ServiceProviders/ServiceProviderMiniProfileDetail";

// Actions
import { fetchServiceProvider } from "../../Actions/ServiceProviderActions";
import Loader from "../../Components/General/Loader";

class ServiceProviderDetailsContainer extends Component {
  componentDidMount = () => {
    const { match, dispatch } = this.props;

    const { serviceProviderId } = match.params;

    if (serviceProviderId !== undefined) {
      dispatch(
        fetchServiceProvider({
          id: serviceProviderId,
          serviceProviderIdentityId: "-99"
        })
      );
    }
  };

  render = () => {
    const { authState, serviceProviderDetails, match } = this.props;
    const {
      serviceProviderItem,
      serviceProviderId,
      messageItemId
    } = match.params;

    let activeTab = "";
    let targetComponent = "";

    if (serviceProviderItem.toUpperCase().includes("SUMMARY")) {
      activeTab = "summary";
      targetComponent = (
        <ServiceProviderSummaryContainer
          serviceProviderId={serviceProviderId}
        />
      );
    } else if (serviceProviderItem.toUpperCase().includes("MESSAGES")) {
      activeTab = "messages";
      const targetMessageUriPrefix = `/service-providers/messages/${serviceProviderId}`;
      targetComponent = (
        <MessagesContainer
          serviceProviderId={serviceProviderId}
          targetMessageUriPrefix={targetMessageUriPrefix}
          messageItemId={messageItemId === undefined ? "all" : messageItemId}
        />
      );
    }

    const editEnabled = !!(
      authState &&
      authState.userDetails &&
      serviceProviderDetails &&
      serviceProviderDetails.data &&
      authState.userDetails.userId ===
        serviceProviderDetails.data[0].serviceProviderIdentityId
    );

    const profileComponent =
      serviceProviderDetails && serviceProviderDetails.data ? (
        <ServiceProviderMiniProfileDetail
          serviceProviderName={
            serviceProviderDetails.data[0].serviceProviderName
          }
          serviceProviderDescription=""
          serviceProviderRating={3}
          serviceProviderReviews={3}
          serviceProviderID={serviceProviderDetails.data[0].serviceProviderName}
          SelectElement={SelectElementContainer}
          showEventService
          editEnabled={editEnabled}
        />
      ) : (
        <Loader />
      );

    return (
      <>
        <ServiceProviderDetail
          spProfileId={serviceProviderId}
          profileComponent={profileComponent}
          activeTab={activeTab}
          targetComponent={targetComponent}
        />
      </>
    );
  };
}

ServiceProviderDetailsContainer.propTypes = {
  authState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eventServices: PropTypes.object.isRequired,
  serviceProviderDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = ({
  authState,
  eventServicesState,
  serviceProvidersState
}) => ({
  authState,
  eventServices: eventServicesState,
  serviceProviderDetails: serviceProvidersState
});

export default connect(mapStateToProps)(ServiceProviderDetailsContainer);
