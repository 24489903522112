import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Check from "@material-ui/icons/Check";
import Sort from "@material-ui/icons/Sort";
import { withStyles } from "@material-ui/core/styles";

// Custom Components
import { dropDownStyle } from "./DropDownStyle";

class DropDown extends Component {
  state = {
    open: false,
    selectedOption: 0
  };

  handleToggle = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleClose = itemKey => event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false, selectedOption: itemKey });
  };

  render() {
    const { classes, dropDownOptions, dropDownIcon } = this.props;
    const { open, selectedOption } = this.state;

    const menuItems = [];

    let targetItemKey = 0;

    dropDownOptions.forEach(menuItemElement => {
      menuItems.push(
        <MenuItem
          onClick={this.handleClose(targetItemKey)}
          key={menuItemElement.key}
        >
          {menuItemElement.text}
        </MenuItem>
      );
      targetItemKey += 1;
    });

    return (
      <div className={classes.root}>
        <div>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.dropDownButton}
          >
            {dropDownIcon}
            {dropDownOptions[selectedOption].text}
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            style={{
              zIndex: 3
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                  borderRadius: "2px"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList>{menuItems}</MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  classes: PropTypes.object.isRequired,
  dropDownOptions: PropTypes.array,
  dropDownIcon: PropTypes.element
};

DropDown.defaultProps = {
  dropDownOptions: [
    {
      text: "Ascending",
      icon: <Check />,
      key: 1
    },
    {
      text: "Descending",
      icon: "",
      key: 2
    },
    {
      text: "Popular",
      icon: "",
      key: 3
    },
    {
      text: "Newest",
      icon: "",
      key: 4
    },
    {
      text: "Oldest",
      icon: "",
      key: 5
    }
  ],
  dropDownIcon: <Sort />
};

export default withStyles(dropDownStyle)(DropDown);
