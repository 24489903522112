import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { refreshSignIn } from "../../Actions/AuthActions";

// Components
import Loader from "../../Components/General/Loader";

class Authentication extends Component {
  componentWillMount = () => {
    const { dispatch } = this.props;
    dispatch(refreshSignIn());
  };

  componentWillUpdate = nextProps => {
    const { dispatch, children: oldChildren } = this.props;
    const { children: newChildren } = nextProps;

    const { props: oldProps } = oldChildren;
    const { props: newProps } = newChildren;

    const { location: oldLocation } = oldProps;
    const { location: newLocation } = newProps;

    if (oldLocation.pathname !== newLocation.pathname) {
      dispatch(refreshSignIn());
    }
  };

  render() {
    const { children, authState } = this.props;

    const { props: componentProps } = children;
    const { location } = componentProps;

    // Redirect to  Sign In
    if (
      authState &&
      !authState.loading &&
      (authState.userDetails === undefined || authState.error)
    ) {
      return <Redirect to={{ pathname: "/sign-in", state: location }} />;
    }

    return (
      <>
        {authState === undefined ||
          authState === {} ||
          (authState && authState.loading && <Loader />)}

        {authState &&
          !authState.loading &&
          authState.userDetails !== undefined && <>{children}</>}
      </>
    );
  }
}

Authentication.propTypes = {
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired
};

const mapStateToProps = ({ authState }) => {
  return { authState };
};

export default connect(mapStateToProps)(Authentication);
