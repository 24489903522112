import {
  UPDATTING_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS
} from "../Constants/AuthActionTypes";

export const updatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATTING_PASSWORD:
      return { loading: true };
    case UPDATE_PASSWORD_FAILED:
      return { error: true };
    case UPDATE_PASSWORD_SUCCESS:
      return { success: true };

    default:
      return state;
  }
};
