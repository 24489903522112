import React, { Component } from "react";

// Components
import EventItemsListContainer from "../EventItems/EventItemsListContainer";
import LandingPage from "../../Components/LandingPage";

class LandingPageContainer extends Component {
  componentDidMount = () => {};

  render() {
    const eventListComponent = <EventItemsListContainer />;
    return (
      <div>
        <LandingPage eventListComponent={eventListComponent} />
      </div>
    );
  }
}

export default LandingPageContainer;
