import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom Components
import GridContainer from "../GridContainer";
// Style
import { loaderStyle } from "./LoaderStyle";

const Loader = ({ ...loaderProps }) => {
  const { classes } = loaderProps;

  return (
    <GridContainer direction="row" justify="center" alignItems="center">
      <Grid item md={12} xs={12} sm={12} className={classes.loaderWrapper}>
        <CircularProgress disableShrink />
      </Grid>
    </GridContainer>
  );
};

export default withStyles(loaderStyle)(Loader);
