// global window, document
import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  animateDiv: {
    "&>div:hover": {
      transform: "scale(1.015)"
    }
  }
};

class SelectElementContainer extends Component {
  constructor(props) {
    super(props);
    this.targetElement = "";
  }

  getref = target => {
    this.targetElement = target;
  };

  selectElement = e => {
    const { onSelect } = this.props;
    e.preventDefault();

    if (onSelect) onSelect(this.targetElement);
  };

  render() {
    const {
      ContainerElement,
      children,
      classes,
      animateContainer
    } = this.props;

    const animateClasses = animateContainer ? classes.animateDiv : "";

    return (
      <ContainerElement
        ref={this.getref}
        onClick={this.selectElement}
        className={animateClasses}
      >
        {children}
      </ContainerElement>
    );
  }
}

SelectElementContainer.propTypes = {
  children: PropTypes.any.isRequired,
  ContainerElement: PropTypes.oneOf(["span", "div"]),
  onSelect: PropTypes.func.isRequired,
  animateContainer: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

SelectElementContainer.defaultProps = {
  ContainerElement: "div",
  animateContainer: true
};

export default withStyles(style)(SelectElementContainer);
