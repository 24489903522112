export const confirmEmailstyle = {
  confirmEmailCard: {
    background: "transparent",
    border: "1px solid #e7e5f3ed"
  },
  confirmEmailHeight: {
    height: "40vh"
  },
  confirmEmailContent: {
    padding: "25px 30px",
    "& span": {
      fontSize: "20px",
      textAlign: "center"
    }
  },
  signInLink: {
    textDecoration: "none"
  },
  successIcon: {
    color: "#0d5225ed",
    width: "30px",
    height: "30px",
    verticalAlign: "top",
    marginRright: "2px"
  }
};

export const authStyle = theme => ({
  authSubHeaderWrapper: {
    background: "transparent"
  },
  authWraperHeight: {
    height: "65vh"
  },

  authCard: {
    background: "transparent",
    border: "1px solid #e7e5f3ed"
  },
  authCardHeader: {
    padding: "25px 15px 15px",
    "& span": {
      fontSize: "20px",
      textAlign: "center"
    }
  },
  authCardContent: {
    padding: "10px 30px 24px",
    "& a": {
      textDecoration: "none"
    }
  },
  authFormGroup: {
    padding: "10px 0px",
    color: "#222",
    "&>fieldset": {
      width: "100%"
    }
  },
  nameGroup: {
    padding: "0px"
  },
  authHouseCheckBox: {
    lineHeight: "1.42857",
    color: "#AAAAAA",
    fontWeight: "400",
    paddingTop: "4px",
    cursor: "pointer",
    alignItems: "center",
    "-webkit-tap-highlight-color": "transparent"
  },
  authCheckboxLabel: {
    color: "#000000c4",
    cursor: "pointer",
    verticalAlign: "middle",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    paddingLeft: "0px",
    paddingTop: "5px"
  },
  authCaption: {
    fontSize: "13px"
  },
  radioGroup: {
    paddingLeft: "5px",
    "& svg": {
      fontSize: "30px",
      width: "0.8em",
      height: "0.8em"
    }
  },
  authFailure: {
    color: "red",
    "& svg": {
      fontSize: "15px",
      verticalAlign: "middle"
    }
  },
  authSuccess: {
    color: "green",
    "& svg": {
      fontSize: "15px",
      verticalAlign: "middle"
    }
  },
  margin: {
    margin: "8px 0px"
  },
  menu: {
    width: "200px",
    border: "10px solid yellow"
  },
  tNC: {
    color: theme.palette.primary.main,
    fontStyle: "italic",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  housesForgotPassword: {
    padding: "7px 5px",
    "& a": {
      color: "gray",
      fontSize: "13.5px"
    }
  }
});
