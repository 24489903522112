export const messageListStyle = theme => ({
  root: {
    width: "100%",
    paddingTop: "0px",
    backgroundColor: theme.palette.background.paper,
    "& a": {
      padding: "0px 5px 0px 5px",
      borderBottom: "1px solid #f5f5f5"
    },
    "& li:last-child": {
      borderBottom: "none"
    }
  },
  activemessage: {
    borderLeft: "2px solid",
    background: "#f5f5f5"
  },
  inline: {
    display: "inline",
    fontSize: "14px"
  },
  cardWrapper: {
    boxShadow: "none",
    border: "none",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      borderLeft: "1px solid #e7e5f3ed",
      borderRight: "1px solid #e7e5f3ed",
      borderBottom: "none",
      borderTop: "none",
      borderRadius: "0px",
      minHeight: "70vh"
    }
  },
  cardbody: {
    padding: "2px"
  },
  listText: {
    borderBottom: "none",
    paddingBottom: "0px",
    marginBottom: "5px",
    marginTop: "0px",
    "& span": {
      fontSize: "14px"
    },
    "& p": {
      fontSize: "13px"
    }
  }
});
