import React from "react";
import { Field } from "redux-form";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Lock from "@material-ui/icons/Lock";
import CloseRounded from "@material-ui/icons/CloseRounded";
import CheckRounded from "@material-ui/icons/CheckRounded";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom Components
import SubHeader from "../General/SubHeader";
import GridContainer from "../General/GridContainer";
import FormInputBox from "../General/Form/FormInputBox";

import { required, resetPasswordsMatch } from "../General/Form/Helpers";

// Style
import { authStyle } from "./AuthStyle";

const UpdatePassword = ({ ...signInProps }) => {
  const { classes, handleUpdatePassword, updatePasswordState } = signInProps;

  console.log(updatePasswordState);

  return (
    <>
      <SubHeader className={classes.authSubHeaderWrapper}>
        <div />
      </SubHeader>
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.authWraperHeight}
      >
        <Grid item md={6} xs={12} sm={12}>
          <Card className={classes.authCard}>
            <CardHeader
              title="Update Password"
              className={classes.authCardHeader}
            />
            <CardContent className={classes.authCardContent}>
              <form onSubmit={handleUpdatePassword}>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="newpassWord"
                      type="password"
                      component={FormInputBox}
                      label="New Password *"
                      placeholder="Enter your new password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="confirmPassword"
                      type="password"
                      component={FormInputBox}
                      label="Confirm Password *"
                      placeholder="Confirm New Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required, resetPasswordsMatch]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  {updatePasswordState &&
                    updatePasswordState.isUpdatePasswordModule && (
                      <Typography
                        variant="subtitle2"
                        className={classes.authCaption}
                      >
                        {updatePasswordState.error && (
                          <div className={classes.authFailure}>
                            <CloseRounded />
                            An error occured while updating password. Please try
                            again
                          </div>
                        )}
                        {updatePasswordState && updatePasswordState.success && (
                          <div className={classes.authSuccess}>
                            <CheckRounded />
                            {
                              "Update password successful! Redirecting to Sign In Page ..."
                            }
                          </div>
                        )}
                      </Typography>
                    )}
                </div>
                <div className={classes.authFormGroup}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    disabled={
                      updatePasswordState && updatePasswordState.loading
                    }
                    type="submit"
                    className={classes.margin}
                  >
                    {updatePasswordState &&
                      updatePasswordState.loading === undefined &&
                      "Submit"}
                    {updatePasswordState && updatePasswordState.loading && (
                      <CircularProgress size={26} />
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(authStyle)(UpdatePassword);
