import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// containers
import SelectElementContainer from "../General/SelectElementContainer";

// actions
import { fetchServiceProviders } from "../../Actions/ServiceProviderActions";
import { fetchEventService } from "../../Actions/EventServiceActions";

// Components
import ServiceServiceProvider from "../../Components/ServiceServiceProviders";
import Error from "../../Components/General/Error";
import Loader from "../../Components/General/Loader";

// Helpers
import { removeElementFromAnArray } from "../../Helpers/General";

class ServiceProvidersListContainer extends Component {
  constructor() {
    super();
    this.state = {
      services: [],
      targetIndex: 0,
      buttonText: "Next Service",
      serviceProvidersCompare: [],
      serviceProvidersFavorite: [],
      checkedCompareSPs: [],
      checkedFavoriteSPs: [],
      compareModalOpen: false
    };

    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleAddToCompare = this.handleAddToCompare.bind(this);
    this.handleAddToFavorite = this.handleAddToFavorite.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    const { eventServiceIds } = match.params;
    const { targetIndex } = this.state;

    if (eventServiceIds === undefined) {
      dispatch(fetchServiceProviders());
    } else {
      const eventServices = eventServiceIds.split("-_-");

      this.setState({ services: eventServices });

      dispatch(fetchServiceProviders(eventServices[targetIndex]));
      dispatch(
        fetchEventService({ eventServiceId: eventServices[targetIndex] })
      );
    }
  };

  handleClickNext = currentIndex => {
    const { services } = this.state;
    const { dispatch } = this.props;

    const nextTargetIndex =
      currentIndex === services.length - 1
        ? currentIndex - 1
        : currentIndex + 1;

    if (currentIndex < services.length && currentIndex >= 0) {
      this.setState(prevState => {
        return {
          targetIndex: nextTargetIndex,
          buttonText:
            prevState.targetIndex > nextTargetIndex
              ? "Previous Service"
              : "Next Service"
        };
      });

      dispatch(fetchServiceProviders(services[nextTargetIndex]));
      dispatch(
        fetchEventService({ eventServiceId: services[nextTargetIndex] })
      );
    }
  };

  handleAddToCompare = payload => () => {
    this.setState(prevState => {
      if (prevState.serviceProvidersCompare.length === 1) {
        if (
          prevState.serviceProvidersCompare[0].serviceProviderID ===
          payload.serviceProviderID
        ) {
          prevState.serviceProvidersCompare.pop();
        } else {
          prevState.serviceProvidersCompare.push(payload);
        }
        return {
          serviceProvidersCompare: prevState.serviceProvidersCompare,
          checkedCompareSPs: this.manageArray(
            prevState.checkedCompareSPs,
            payload.serviceProviderID,
            2
          )
        };
      }

      if (prevState.serviceProvidersCompare.length === 2) {
        if (
          prevState.serviceProvidersCompare[0].serviceProviderID ===
          payload.serviceProviderID
        ) {
          prevState.serviceProvidersCompare.shift();
        } else if (
          prevState.serviceProvidersCompare[1].serviceProviderID ===
          payload.serviceProviderID
        ) {
          prevState.serviceProvidersCompare.pop();
        } else {
          prevState.serviceProvidersCompare.shift();
          prevState.serviceProvidersCompare.push(payload);
        }

        return {
          serviceProvidersCompare: prevState.serviceProvidersCompare,
          checkedCompareSPs: this.manageArray(
            prevState.checkedCompareSPs,
            payload.serviceProviderID,
            2
          )
        };
      }

      if (prevState.serviceProvidersCompare.length === 0) {
        prevState.serviceProvidersCompare.push(payload);
        return {
          serviceProvidersCompare: prevState.serviceProvidersCompare,
          checkedCompareSPs: this.manageArray(
            prevState.checkedCompareSPs,
            payload.serviceProviderID,
            2
          )
        };
      }

      return {
        serviceProvidersCompare: prevState.serviceProvidersCompare,
        checkedCompareSPs: prevState.checkedCompareSPs
      };
    });
  };

  handleAddToFavorite = payload => () => {
    this.setState(prevState => {
      if (
        prevState.serviceProvidersFavorite.length > 0 &&
        prevState.serviceProvidersFavorite[0].serviceProviderID ===
          payload.serviceProviderID
      ) {
        prevState.serviceProvidersFavorite.pop();
      } else {
        prevState.serviceProvidersFavorite.pop();
        prevState.serviceProvidersFavorite.push(payload);
      }

      return {
        serviceProvidersFavorite: prevState.serviceProvidersFavorite,
        checkedFavoriteSPs: this.manageArray(
          prevState.checkedFavoriteSPs,
          payload.serviceProviderID,
          1
        )
      };
    });
  };

  manageArray = (arrayPayload, elementKey, maximumLength) => {
    // remove if the element exists , add if it does not
    const resultingPaylod =
      arrayPayload.indexOf(elementKey) === -1
        ? [...arrayPayload, ...[elementKey]]
        : removeElementFromAnArray(arrayPayload, elementKey);

    // remove first element if the length is greater than
    if (resultingPaylod.length > maximumLength) {
      resultingPaylod.shift();
    }

    return resultingPaylod;
  };

  handleModal = () => {
    this.setState(prevState => ({
      compareModalOpen: !prevState.compareModalOpen
    }));
  };

  render = () => {
    const { serviceProvidersState, eventServicesState } = this.props;

    const {
      targetIndex,
      buttonText,
      serviceProvidersCompare,
      serviceProvidersFavorite,
      services,
      checkedCompareSPs,
      checkedFavoriteSPs,
      compareModalOpen
    } = this.state;

    const { match } = this.props;
    const { eventServiceIds } = match.params;

    let showComponent = false;

    if (eventServiceIds === undefined) {
      showComponent = serviceProvidersState && serviceProvidersState.data;
    } else {
      showComponent =
        serviceProvidersState &&
        serviceProvidersState.data &&
        (eventServicesState && eventServicesState.data);
    }

    const showEventService = !!(
      eventServiceIds !== undefined &&
      eventServicesState &&
      eventServicesState.data
    );

    const targetEventService =
      showComponent && showEventService ? eventServicesState.data[0] : {};

    return (
      <>
        {serviceProvidersState && eventServicesState && showComponent && (
          <ServiceServiceProvider
            serviceProviders={serviceProvidersState}
            eventServices={targetEventService}
            showEventService={showEventService}
            servicesCount={services.length}
            handleClickNext={this.handleClickNext}
            currentTargetIndex={targetIndex}
            SelectElement={SelectElementContainer}
            buttonText={buttonText}
            handleAddToCompare={this.handleAddToCompare}
            handleAddToFavorite={this.handleAddToFavorite}
            serviceProvidersCompare={serviceProvidersCompare}
            serviceProvidersFavorite={serviceProvidersFavorite}
            checkedCompareSPs={checkedCompareSPs.join(",")}
            checkedFavoriteSPs={checkedFavoriteSPs.join(",")}
            handleModal={this.handleModal}
            compareModalOpen={compareModalOpen}
          />
        )}

        {serviceProvidersState && serviceProvidersState.error && (
          <Error
            error={{
              message: "An error occured while loading service providers"
            }}
          />
        )}

        {eventServicesState && eventServicesState.error && (
          <Error
            error={{
              message: "An error occured while loading service providers"
            }}
          />
        )}

        {(serviceProvidersState || eventServicesState) &&
          !(
            serviceProvidersState.error ||
            eventServicesState.error ||
            (serviceProvidersState.data || eventServicesState.data)
          ) && <Loader />}
      </>
    );
  };
}

ServiceProvidersListContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  serviceProvidersState: PropTypes.object.isRequired,
  eventServicesState: PropTypes.object.isRequired
};

const mapStateToProps = ({ serviceProvidersState, eventServicesState }) => ({
  serviceProvidersState,
  eventServicesState
});

export default connect(mapStateToProps)(ServiceProvidersListContainer);
