import { call, put, takeLatest } from "redux-saga/effects";

// End Point
import { updatePasswordEndPoint } from "../ApiClient/AuthEndPoint/UpdatePassword";
import { serviceProviderDetailEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderDetail";

import {
  UPDATE_PASSWORD,
  UPDATTING_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESSFUL
} from "../Constants/AuthActionTypes";

// utilities
import { manageTokenStorage } from "./AuthSaga";

export function* watchUpdatePassword() {
  yield takeLatest([UPDATE_PASSWORD], handleUpdatePassword);
}

export function* handleUpdatePassword(payload) {
  switch (payload.type) {
    case UPDATE_PASSWORD: {
      yield put({ type: UPDATTING_PASSWORD });

      const authUserData = yield call(updatePasswordEndPoint, payload.payload);
      yield put({ type: UPDATTING_PASSWORD });

      if (authUserData && authUserData.data) {
        let spData;

        if (authUserData.data.userDetails.isServiceProvider) {
          spData = yield call(serviceProviderDetailEndPoint, {
            id: -99,
            serviceProviderIdentityId: authUserData.data.userDetails.userId
          });
        }

        const payloadData =
          spData && spData.data
            ? {
                ...authUserData.data,
                ...{ spDetails: spData.data[0] }
              }
            : { ...authUserData.data };

        manageTokenStorage(authUserData.data);

        yield put({ type: SIGN_IN_SUCCESSFUL, payload: payloadData });
        yield put({ type: UPDATE_PASSWORD_SUCCESS });
      } else {
        yield put({ type: SIGN_IN_FAILED, payload: authUserData });
        yield put({ type: UPDATE_PASSWORD_FAILED });
      }
      break;
    }
    default:
      break;
  }
}
