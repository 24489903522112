import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import GridContainer from "../../General/GridContainer";
import CustomTabs from "../../General/CustomTabs";

// Styles
import { serviceProviderDetailStyle } from "./ServiceProviderDetailStyle";

const tabItems = [
  {
    key: 0,
    title: "Service Provider Summary",
    rootUri: "/service-providers/summary/"
  },
  { key: 1, title: "Messages", rootUri: "/service-providers/messages/" }
];

const ServiceProviderDetail = ({ ...serviceProviderDetailProps }) => {
  const {
    classes,
    activeTab,
    spProfileId,
    profileComponent,
    targetComponent
  } = serviceProviderDetailProps;

  return (
    <>
      <GridContainer
        justify="flex-start"
        alignItems="flex-start"
        className={classes.profileWrapper}
      >
        <Grid item xs={12} sm={12} md={12}>
          <CustomTabs
            activeTab={activeTab}
            profileId={spProfileId}
            tabItems={tabItems}
          />
        </Grid>
      </GridContainer>
      <GridContainer
        justify="flex-start"
        alignItems="flex-start"
        className={classes.profileWrapper}
      >
        <Grid item xs={12} sm={12} md={12}>
          <GridContainer
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className={classes.gridItemCustomStyle}
            >
              {profileComponent}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              className={classes.gridItemCustomStyle}
            >
              {targetComponent}
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(serviceProviderDetailStyle)(ServiceProviderDetail);
