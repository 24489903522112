export const linksStyle = theme => ({
  list: {
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: theme.palette.primary.white
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0"
  },

  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "capitalize",
    borderRadius: "3px",
    paddingTop: "20px",
    lineHeight: "24px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "& div": {
      "&:hover,&:focus": {
        color: "#9fa9d6"
      }
    }
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "25px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    wordSpacing: "-2px"
  },
  navLinkActive: {
    // backgroundColor: "rgba(255, 255, 255, 0.1)"
    color: theme.palette.primary.whiteActive
  },
  icons: {
    width: "24px",
    height: "24px",
    margin: "0px 0px 0px",
    color: "#bebbd6"
  },
  personIcon: {
    background: "#FFF",
    borderRadius: "20px",
    color: " #150b6a"
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px"
    }
  },
  marginRight5: {
    marginRight: "5px"
  }
});
