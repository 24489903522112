export const serviceProviderBasicDetailsUpdateStyle = {
  subHeaderWrapper: {
    background: "transparent"
  },
  formGroup: {
    padding: "15px 0px",
    color: "#222",
    // display: "flex",
    "&>fieldset": {
      width: "100%"
    }
  },
  card: {
    background: "transparent",
    border: "1px solid #e7e5f3ed"
  },
  cardHeader: {
    padding: "20px 15px 15px",
    "& span": {
      fontSize: "20px",
      textAlign: "center"
    }
  },
  cardContent: {
    padding: "10px 30px 0px",
    "& a": {
      textDecoration: "none"
    }
  },
  serviceImageIcon: {
    height: "22px",
    verticalAlign: "sub",
    marginRight: "4px"
  },
  authFailure: {
    color: "red",
    "& svg": {
      fontSize: "15px",
      verticalAlign: "middle"
    }
  },
  authSuccess: {
    color: "green",
    "& svg": {
      fontSize: "15px",
      verticalAlign: "middle"
    }
  }
};
