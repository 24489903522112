import { takeLatest, call, put } from "redux-saga/effects";

// Endpoint
import { serviceProviderListEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderList";
import { serviceProviderDetailEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderDetail";
import { serviceProviderProfileUpdateEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderProfileUpdate";

// Action Types
import {
  FETCH_SERVICE_PROVIDER,
  FETCH_SERVICE_PROVIDERS,
  FETCHED_SERVICE_PROVIDERS,
  FETCHED_SERVICE_PROVIDER,
  FETCH_SERVICE_PROVIDER_FAILURE,
  UPDATE_SP_PROFILE_DETAILS,
  UPDATED_SP_PROFILE_DETAILS,
  UPDATE_SP_PROFILE_DETAILS_FAILURE,
  FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS,
  FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS,
  FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS_FAILURE,
  UPDATTING_SP_PROFILE_DETAILS
} from "../Constants/ServiceProvidersActionTypes";

import { serviceProviderExtendedDetailsEndPoint } from "../ApiClient/ServiceProvider/ServiceProviderExtendedDetails";

import { eventServiceListEndPoint } from "../ApiClient/EventServices/EventServicesList";

export function* watchServiceProviders() {
  yield takeLatest(
    [
      FETCH_SERVICE_PROVIDER,
      FETCH_SERVICE_PROVIDERS,
      UPDATE_SP_PROFILE_DETAILS
    ],
    handleServiceProviders
  );
}

export function* watchExtendedServiceProviders() {
  yield takeLatest(
    [FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS],
    handleServiceProviders
  );
}

export function* handleServiceProviders(payload) {
  switch (payload.type) {
    case FETCH_SERVICE_PROVIDERS: {
      const serviceProvidersData = yield call(
        serviceProviderListEndPoint,
        payload.payload
      );

      if (serviceProvidersData && serviceProvidersData.data) {
        yield put({
          type: FETCHED_SERVICE_PROVIDERS,
          payload: serviceProvidersData
        });
      } else {
        yield put({
          type: FETCH_SERVICE_PROVIDER_FAILURE,
          payload: serviceProvidersData
        });
      }

      break;
    }
    case FETCH_SERVICE_PROVIDER: {
      const serviceProviderDetailData = yield call(
        serviceProviderDetailEndPoint,
        payload.payload
      );

      if (serviceProviderDetailData && serviceProviderDetailData.data) {
        yield put({
          type: FETCHED_SERVICE_PROVIDER,
          payload: serviceProviderDetailData
        });
      } else {
        yield put({
          type: FETCH_SERVICE_PROVIDER_FAILURE,
          payload: serviceProviderDetailData
        });
      }

      break;
    }
    case FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS: {
      const spExtendedData = yield call(
        serviceProviderExtendedDetailsEndPoint,
        payload.payload
      );

      if (spExtendedData && spExtendedData.data) {
        const spService = yield call(eventServiceListEndPoint, {
          eventServiceId: spExtendedData.data[0].serviceProviderServiceId
        });

        yield put({
          type: FETCHED_SERVICE_PROVIDER_EXTENDED_DETAILS,
          payload: {
            ...{ spExtProfile: spExtendedData.data[0] },
            ...{ spService }
          }
        });
      } else {
        yield put({
          type: FETCH_SERVICE_PROVIDER_EXTENDED_DETAILS_FAILURE,
          payload: spExtendedData
        });
      }
      break;
    }
    case UPDATE_SP_PROFILE_DETAILS: {
      yield put({
        type: UPDATTING_SP_PROFILE_DETAILS
      });
      const spProfileUpdateData = yield call(
        serviceProviderProfileUpdateEndPoint,
        payload.payload
      );

      if (spProfileUpdateData && spProfileUpdateData.data) {
        yield put({
          type: UPDATED_SP_PROFILE_DETAILS,
          payload: {
            ...spProfileUpdateData,
            ...{
              isSPUpdateModule: true
            }
          }
        });
      } else {
        yield put({
          type: UPDATE_SP_PROFILE_DETAILS_FAILURE,
          payload: { ...spProfileUpdateData, ...{ isSPUpdateModule: true } }
        });
      }

      break;
    }
    default:
      break;
  }
}
