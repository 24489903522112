import React from "react";
import InputBase from "@material-ui/core/InputBase";
import withStyles from "@material-ui/core/styles/withStyles";

// Styles
import { formSearchBoxStyle } from "./FormSearchBoxStyle";

const FormSearchBox = ({ ...formSearchBoxProps }) => {
  const { input, inputClasses, custom } = formSearchBoxProps;

  return <InputBase classes={inputClasses} {...input} {...custom} />;
};

export default withStyles(formSearchBoxStyle)(FormSearchBox);
