import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";

// Actions
import { clearSignIn, updatePassword } from "../../Actions/AuthActions";

import BaseRedirectContainer from "../BaseRedirectContainer";

// Components
import UpdatePassword from "../../Components/Auth/UpdatePassword";

class UpdatePasswordContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(clearSignIn());
  };

  handleUpdatePassword = values => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const { userId, passwordResetToken } = params;

    if (values && values.newpassWord !== undefined) {
      dispatch(
        updatePassword({
          newPassword: values.newpassWord,
          passWordResetToken: passwordResetToken,
          userId
        })
      );
    }
  };

  render = () => {
    const {
      handleSubmit,
      updatePasswordState,
      authState,
      location
    } = this.props;

    console.log(updatePasswordState);

    const { redirect } = this.state;

    if (updatePasswordState && updatePasswordState.success && redirect) {
      let redirectUri = "";

      if (
        authState.userDetails.emailConfirmed &&
        authState.userDetails.isServiceProvider &&
        authState.spDetails &&
        authState.spDetails.isBasicDetailsAdded
      ) {
        redirectUri = `/service-providers/summary/${authState.spDetails.id}`;
      }

      if (
        authState.userDetails.emailConfirmed &&
        authState.userDetails.isServiceProvider &&
        authState.spDetails &&
        !authState.spDetails.isBasicDetailsAdded
      ) {
        redirectUri = `/service-providers/sp-profile-update/${
          authState.userDetails.userId
        }`;
      }

      if (
        authState.userDetails.emailConfirmed &&
        !authState.userDetails.isServiceProvider
      ) {
        redirectUri = `/profile/summary/${authState.userDetails.userId}`;
      }

      if (!authState.userDetails.emailConfirmed) {
        redirectUri = `/email-confirmation/${authState.userDetails.userId}`;
      }

      return (
        <Redirect
          to={{
            pathname: redirectUri,
            state: location
          }}
        />
      );
    }

    return (
      <>
        <UpdatePassword
          updatePasswordState={{
            ...updatePasswordState,
            ...{ isUpdatePasswordModule: true }
          }}
          handleUpdatePassword={handleSubmit(this.handleUpdatePassword)}
        />
      </>
    );
  };
}

UpdatePasswordContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired,
  updatePasswordState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemDetail: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = ({ authState, updatePasswordState }) => ({
  authState,
  updatePasswordState,
  itemDetail:
    updatePasswordState && updatePasswordState.success ? { updatedKey: 1 } : {}
});

const UpdatePasswordFormed = reduxForm({ form: "update-password" })(
  UpdatePasswordContainer
);

export default connect(mapStateToProps)(UpdatePasswordFormed);
