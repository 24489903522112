export const eventItemServicesStyle = {
  serviceListWrapper: {
    width: "100%",
    marginTop: "10px",
    padding: "0px 10px"
  },
  serviceItemWrapper: {
    padding: "10px"
  }
};
