import React from "react";
import Link from "react-router-dom/Link";
import withStyles from "@material-ui/core/styles/withStyles";
// Style
import { breadCrumbStyle } from "./BreadCrumbStyle";

const BreadCrumb = ({ ...breadCrumbProps }) => {
  const { classes, breadCrumbRoutes, pageName } = breadCrumbProps;

  const bRoutes = [];
  let countItems = 1;

  breadCrumbRoutes.forEach(element => {
    bRoutes.push(
      <div className={classes.breadCrumbItem} key={countItems}>
        <Link to={element.link}>{element.linkTitle}</Link>
        <div className={classes.separator}>/</div>
      </div>
    );
    countItems += countItems;
  });

  bRoutes.push(
    <div className={classes.breadCrumbItem} key={countItems}>
      {pageName}
    </div>
  );

  return <div className={classes.breadCrumbContainer}>{bRoutes}</div>;
};

export default withStyles(breadCrumbStyle)(BreadCrumb);
