import { ApiClientEndPointMethod } from "../EndPoint";
import { USERS_URL } from "../EndPoint/ConstantVariables";

export const confirmEmailEndPoint = payload => {
  if (payload && payload.confirmEmailToken) {
    const bodyContent = JSON.stringify(payload);
    const uri = "Users/ConfirmEmail/";

    const confirmEmailData = ApiClientEndPointMethod({
      url: USERS_URL + uri,
      customBodyOptions: {
        body: bodyContent
      },
      method: "POST"
    });

    try {
      return confirmEmailData.then(confirmEmailInfo => {
        if (confirmEmailInfo === 1) {
          return { data: confirmEmailInfo };
        }
        return { error: "Email Confirmation failed" };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "No Data to Submit" };
  }
};
