import {
  FETCH_EVENT_SERVICE,
  FETCH_EVENT_SERVICES
} from "../Constants/EventServicesActionTypes";

export const fetchEventService = payload => {
  return {
    type: FETCH_EVENT_SERVICE,
    payload
  };
};

export const fetchEventServices = payload => {
  return {
    type: FETCH_EVENT_SERVICES,
    payload
  };
};
