import { ApiClientEndPointMethod } from "../EndPoint";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const serviceProviderExtendedDetailsEndPoint = payload => {
  if (payload !== undefined) {
    const url = `${SERVICE_PROVIDERS_URL}ServiceProviders/ExtendedDetails/${
      payload.id
    }/${payload.serviceProviderIdentityId}`;

    const spExtendedDetails = ApiClientEndPointMethod({
      url
    });

    try {
      return spExtendedDetails.then(spData => {
        if (spData && spData.serviceProviderExtendedDetails) {
          return {
            data: [{ ...spData.serviceProviderExtendedDetails }]
          };
        }
        return { error: "Error Fetching Data" };
      });
    } catch (error) {
      return { error: error.message };
    }
  }
  return { error: "Invalid request. Please try again" };
};
