import React from "react";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Work from "@material-ui/icons/Work";
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";

export const navigatorMenu = [
  {
    id: "Menu",
    children: [
      { id: "Event Types", link: "/event-items", icon: <CalendarToday /> },
      {
        id: "Event Services",
        link: "/event-items/event-services",
        icon: <Work />
      },
      {
        id: "Service Providers",
        link: "/event-services/service-providers",
        icon: <Person />
      }
    ]
  },
  {
    id: "Settings",
    children: [
      { id: "Reset Password", link: "/forgot-password", icon: <Lock /> }
    ]
  }
];
