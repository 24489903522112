export const SUBMIT_SIGN_IN = "SUBMIT_SIGN_IN";
export const SUBMIT_SIGN_UP = "SUBMIT_SIGN_UP";
export const SIGN_IN_SUCCESSFUL = "SIGN_IN_SUCCESSFUL";
export const SIGN_UP_SUCCESSFUL = "SIGN_UP_SUCCESSFUL";
export const SIGN_IN_FAILED = "SIGN_IN_FAILED";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";

export const SIGNING_IN = "SIGNING_IN";

export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const CONFIRM_EMAIL_SUCCESSFUL = "CONFIRM_EMAIL_SUCCESSFUL";
export const CONFIRM_EMAIL_FAILED = "CONFIRM_EMAIL_FAILED";

export const CLEAR_SIGNED_IN_PROFILE = "CLEAR_SIGNED_IN_PROFILE";

export const CLEARED_SIGNED_IN_PROFILE = "CLEARED_SIGNED_IN_PROFILE";

export const REFRESH_SIGN_IN = "REFRESH_SIGN_IN";

// Reset Password
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// Update Password
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATTING_PASSWORD = "UPDATTING_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED";
