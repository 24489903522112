export const landingPageStyle = theme => ({
  parallaxContainer: {
    paddingRight: "20px",
    paddingLeft: "0px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    },
    zIndex: "12",
    color: "#FFFFFF",
    "&>div": {
      [theme.breakpoints.up("sm")]: {
        padding: "0px 0px"
      }
    }
  },
  landingPageFavorites: {
    position: "relative",
    overflow: "hidden",
    padding: "10px 30px"
  },
  eventlistWrapper: {
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 225px"
    }
  }
});
