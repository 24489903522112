import { ApiClientEndPointMethod } from "../EndPoint";
import { USERS_URL } from "../EndPoint/ConstantVariables";

export const profileDetailsEndPoint = payload => {
  let usersUri = "Users/";

  usersUri += payload !== undefined ? payload : "";

  const userProfile = ApiClientEndPointMethod({
    url: USERS_URL + usersUri,
    authorize: true
  });

  try {
    return userProfile.then(userProfileData => {
      if (userProfileData && userProfileData.userDetails) {
        return {
          data: [{ ...userProfileData.userDetails }]
        };
      }

      return {};
    });
  } catch (error) {
    return { error: error.message };
  }
};
