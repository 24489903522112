export const messageConversationStyle = theme => {
  const radius = theme.spacing(2.5);
  const size = theme.spacing(4);
  const rightBgColor = theme.palette.primary.main;

  return {
    cardWrapper: {
      boxShadow: "none",
      border: "1px solid #e7e5f3ed",
      marginBottom: "10px"
    },
    avatar: {
      width: size,
      height: size
    },
    msg: {
      padding: theme.spacing(1, 2),
      borderRadius: 4,
      marginBottom: 4,
      display: "inline-block",
      wordBreak: "break-all",
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    leftRow: {
      textAlign: "left"
    },
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: theme.palette.grey[100]
    },
    leftFirst: {
      borderTopLeftRadius: radius
    },
    leftLast: {
      borderBottomLeftRadius: radius
    },
    rightRow: {
      textAlign: "right"
    },
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: rightBgColor,
      color: theme.palette.common.white
    },
    rightFirst: {
      borderTopRightRadius: radius
    },
    rightLast: {
      borderBottomRightRadius: radius
    }
  };
};
