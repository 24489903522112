export const subHeaderStyle = {
  wrapper: {
    color: "currentColor",
    padding: "0vh 0vw 1.5vh",
    background: "#FFFFFF",
    "&>div": {
      // paddingRight: '30px'
    }
  }
};
