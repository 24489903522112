import { ApiClientEndPointMethod } from "../EndPoint";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const serviceProviderProfileUpdateEndPoint = payload => {
  if (payload && payload.serviceProviderIdentityId) {
    const spProfileUpdateUri = `ServiceProviders/UpdateServiceProviderBasicDetails/${
      payload.serviceProviderIdentityId
    }`;

    const bodyContent = JSON.stringify({
      ...payload,
      ...{ serviceProviderStatus: 1 }
    });

    const spProfileUPdateData = ApiClientEndPointMethod({
      url: SERVICE_PROVIDERS_URL + spProfileUpdateUri,
      customBodyOptions: {
        body: bodyContent
      },
      method: "PUT",
      authorize: true
    });

    try {
      return spProfileUPdateData.then(updatedData => {
        if (
          updatedData &&
          updatedData.serviceProviderDetails &&
          updatedData.serviceProviderDetails !== null
        ) {
          return {
            data: [
              {
                ...updatedData.serviceProviderDetails
              }
            ]
          };
        }

        if (
          updatedData &&
          updatedData.statusMessage &&
          updatedData.statusMessage
            .toUpperCase()
            .includes("UPDATING IS NOT ALLOWED")
        ) {
          return {
            error: "Updating is not allowed. Details already updated"
          };
        }

        return {
          error:
            "An error occured while updating Service provider. Please retry"
        };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "No data to submit!" };
  }
};
