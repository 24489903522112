import React from "react";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import GridContainer from "../../General/GridContainer";
import ServiceProviderMiniDetail from "../ServiceProviderMiniDetail";
import ContentModal from "../../General/ContentModal";

// Style
import { serviceProvidersCompareStyle } from "./ServiceProvidersCompareStyle";

const ServiceProvidersCompare = ({ ...serviceProvidersCompareProps }) => {
  const {
    classes,
    serviceProvidersCompare,
    handleModal,
    compareModalOpen
  } = serviceProvidersCompareProps;

  const spsCompare = [];

  // Headers
  const tableHeaders = serviceProvidersCompare.map(
    ({ serviceProviderName }) => (
      <TableCell key={serviceProviderName} align="right">
        {serviceProviderName}
      </TableCell>
    )
  );

  const compareTableHeader = (
    <TableHead>
      <TableRow>
        <TableCell />
        {tableHeaders}
      </TableRow>
    </TableHead>
  );

  const compareTableBody = [];

  // Rating
  compareTableBody.push(
    <TableRow key="Rating">
      <TableCell component="th" scope="row">
        Rating
      </TableCell>
      {serviceProvidersCompare.map(
        ({ serviceProviderRating, serviceProviderID }) => (
          <TableCell key={serviceProviderID} align="right">
            {serviceProviderRating}
          </TableCell>
        )
      )}
    </TableRow>
  );

  // Reviews
  compareTableBody.push(
    <TableRow key="Reviews">
      <TableCell component="th" scope="row">
        Reviews
      </TableCell>
      {serviceProvidersCompare.map(
        ({ serviceProviderReviews, serviceProviderID }) => (
          <TableCell key={serviceProviderID} align="right">
            {serviceProviderReviews}
          </TableCell>
        )
      )}
    </TableRow>
  );

  const resultingTable = (
    <Table className={classes.table}>
      {compareTableHeader}
      <TableBody>{compareTableBody}</TableBody>
    </Table>
  );

  serviceProvidersCompare.forEach(spElement => {
    spsCompare.push(
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        className={classes.gridItemCustomStyle}
        key={spElement.serviceProviderID}
      >
        <ServiceProviderMiniDetail {...spElement} />
      </Grid>
    );
  });

  return (
    <>
      <GridContainer
        justify="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        {spsCompare}
      </GridContainer>
      <ContentModal
        isOpen={compareModalOpen}
        handleClose={handleModal}
        modalContent={resultingTable}
        modalTitle="Compare Service Providers"
      />
    </>
  );
};

export default withStyles(serviceProvidersCompareStyle)(
  ServiceProvidersCompare
);
