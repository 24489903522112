import { FETCH_UOM, FETCH_UOMS } from "../Constants/UOMsActionTypes";

export const fetchUOM = payload => {
  return {
    type: FETCH_UOM,
    payload
  };
};
export const fetchUOMs = payload => {
  return {
    type: FETCH_UOMS,
    payload
  };
};
