import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, reset } from "redux-form";
import { connect } from "react-redux";

// Actions
import { fetchConversation, addMessage } from "../../Actions/MessagesActions";

// Components
import MessageConversation from "../../Components/Messages/MessageConversation";

class MessageConversationContainer extends Component {
  constructor() {
    super();
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount = () => {
    const {
      authState: { userDetails },
      targetConversationId,
      dispatch
    } = this.props;

    console.log(userDetails, targetConversationId);

    if (userDetails !== undefined && targetConversationId !== "all") {
      dispatch(
        fetchConversation({
          targetUserId: userDetails.userId,
          targetConverseeId: targetConversationId
        })
      );
    }
  };

  componentDidUpdate = prevProps => {
    const {
      authState: { userDetails },
      targetConversationId,
      dispatch
    } = this.props;

    if (targetConversationId !== prevProps.targetConversationId) {
      if (userDetails !== undefined && targetConversationId !== "all") {
        dispatch(
          fetchConversation({
            targetUserId: userDetails.userId,
            targetConverseeId: targetConversationId
          })
        );
      }
    }
  };

  handleFormChange = e => {
    const { dispatch } = this.props;
    if (e.keyCode === 13) {
      this.submitFormDetails({ message: e.target.value });
      dispatch(reset("messages"));
    }
  };

  handleFormSubmit = values => {
    const { dispatch } = this.props;
    this.submitFormDetails(values);
    dispatch(reset("messages"));
  };

  submitFormDetails = formValues => {
    const {
      dispatch,
      targetConversationId,
      authState: { userDetails }
    } = this.props;

    // Add Message
    dispatch(
      addMessage({
        UserId: userDetails.userId,
        SenderName: `${userDetails.firstName} ${userDetails.lastName}`,
        RecipientId: targetConversationId,
        MessageTitle: formValues.message.trim(),
        MessageContent: formValues.message.trim(),
        MessageStatus: 1
      })
    );
  };

  render = () => {
    const {
      pristine,
      targetConversationId,
      handleSubmit,
      messagesConversationState
    } = this.props;

    return (
      <>
        {messagesConversationState && messagesConversationState.data && (
          <MessageConversation
            activeConvoId={targetConversationId}
            handleFormChange={this.handleFormChange}
            handleFormSubmit={handleSubmit(this.handleFormSubmit)}
            messagesConversation={messagesConversationState.data}
            pristine={pristine}
          />
        )}
      </>
    );
  };
}

MessageConversationContainer.propTypes = {
  authState: PropTypes.object.isRequired,
  messagesConversationState: PropTypes.object.isRequired,
  targetConversationId: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const mapStateToProps = ({ authState, messagesConversationState }) => ({
  authState,
  messagesConversationState
});

const MessageConversationFormed = reduxForm({ form: "messages" })(
  MessageConversationContainer
);

export default connect(mapStateToProps)(MessageConversationFormed);
