import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

// containers
import BaseRedirectContainer from "../BaseRedirectContainer";

// Custom Components
import SignIn from "../../Components/Auth/SignIn";

// actions
import { submitSignIn } from "../../Actions/AuthActions";

class SignInContainer extends BaseRedirectContainer {
  constructor() {
    super();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit(values) {
    const { dispatch } = this.props;
    dispatch(submitSignIn({ ...values, ...{ isSignIn: true } }));
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      dirty,
      itemDetail,
      location
    } = this.props;

    const { redirect } = this.state;
    if (
      itemDetail &&
      itemDetail.updatedKey &&
      itemDetail.updatedKey !== undefined &&
      redirect
    ) {
      let redirectUri = "";

      if (
        itemDetail.userDetails.emailConfirmed &&
        itemDetail.userDetails.isServiceProvider &&
        itemDetail.spDetails &&
        itemDetail.spDetails.isBasicDetailsAdded
      ) {
        redirectUri = `/service-providers/summary/${itemDetail.spDetails.id}`;
      }

      if (
        itemDetail.userDetails.emailConfirmed &&
        itemDetail.userDetails.isServiceProvider &&
        !itemDetail.spDetails
      ) {
        redirectUri = `/service-providers/sp-profile-update/${
          itemDetail.userDetails.userId
        }`;
      }

      if (
        itemDetail.userDetails.emailConfirmed &&
        itemDetail.userDetails.isServiceProvider &&
        itemDetail.spDetails &&
        !itemDetail.spDetails.isBasicDetailsAdded
      ) {
        redirectUri = `/service-providers/sp-profile-update/${
          itemDetail.userDetails.userId
        }`;
      }

      if (
        itemDetail.userDetails.emailConfirmed &&
        !itemDetail.userDetails.isServiceProvider
      ) {
        redirectUri = `/profile/summary/${itemDetail.userDetails.userId}`;
      }

      if (!itemDetail.userDetails.emailConfirmed) {
        redirectUri = `/email-confirmation/${itemDetail.userDetails.userId}`;
      }

      return (
        <Redirect
          to={{
            pathname: redirectUri,
            state: location
          }}
        />
      );
    }

    return (
      <SignIn
        pristine={pristine}
        invalid={invalid}
        formIsDirty={dirty}
        authState={itemDetail}
        handleFormSubmit={handleSubmit(this.handleFormSubmit)}
      />
    );
  }
}

SignInContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemDetail: PropTypes.object.isRequired
};

const mapStateToProps = ({ authState, serviceProvidersState }) => ({
  itemDetail: authState,
  serviceProviderProfile: serviceProvidersState
});

// Compose to redux form
const SignInFormed = reduxForm({ form: "sign-in" })(SignInContainer);

export default connect(mapStateToProps)(SignInFormed);
