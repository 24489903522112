import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withStyles from "@material-ui/core/styles/withStyles";

// Styles
import { alertDialogStyle } from "./AlertDialogStyle";

class AlertDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = confirmStatus => {
    const { handleCloseDialog } = this.props;
    this.setState({ open: false });
    handleCloseDialog(confirmStatus);
  };

  render() {
    const { open } = this.state;
    const {
      openDialog,
      dialogTitle,
      dialogContent,
      classes,
      deleteDialogBtnDisabled
    } = this.props;

    return (
      <Dialog
        open={open || openDialog}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper
        }}
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            className={classes.dialogContent}
          >
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.handleClose(0)}
            color="primary"
            disabled={deleteDialogBtnDisabled}
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.handleClose(1)}
            color="primary"
            autoFocus
            disabled={deleteDialogBtnDisabled}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  deleteDialogBtnDisabled: PropTypes.bool.isRequired
};

export default withStyles(alertDialogStyle)(AlertDialog);
