import {
  FETCH_EVENTITEM,
  FETCH_EVENTITEMS,
  FETCH_EVENTITEM_WRONG_URI
} from "../Constants/EventsActionTypes";

export const fetchEventItem = payload => {
  if (Number.isInteger(parseInt(payload, 10))) {
    return {
      type: FETCH_EVENTITEM,
      payload
    };
  }
  return {
    type: FETCH_EVENTITEM_WRONG_URI,
    payload
  };
};

export const fetchEventItems = payload => {
  return {
    type: FETCH_EVENTITEMS,
    payload
  };
};
