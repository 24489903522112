export const errorStyle = theme => ({
  errorWrapper: {
    padding: "100px 0px 0px",
    textAlign: "center",
    color: theme.palette.secondary.main
  },
  errorIcon: {
    color: "inherit",
    "& svg": {
      fontSize: "35px"
    }
  },
  errorMessage: {
    color: "inherit",
    fontSize: "15px"
  }
});
