import { takeLatest, put, call } from "redux-saga/effects";

// Action Types
import {
  FETCH_USER,
  FETCH_USERS,
  FETCHED_USER,
  FETCHED_USERS
} from "../Constants/UserProfileActionTypes";

// EndPoint
import { profileDetailsEndPoint } from "../ApiClient/UserProfile/ProfileDetails";

export function* wathUserProfile() {
  yield takeLatest([FETCH_USER, FETCH_USERS], handleUserProfile);
}

export function* handleUserProfile(payload) {
  switch (payload.type) {
    case FETCH_USER: {
      const userProfileData = yield call(
        profileDetailsEndPoint,
        payload.payload
      );

      if (userProfileData && userProfileData.data) {
        yield put({ type: FETCHED_USER, payload: userProfileData });
      }
      break;
    }
    case FETCH_USERS: {
      const usersData = yield call(profileDetailsEndPoint);
      if (usersData && usersData.data) {
        yield call({
          type: FETCHED_USERS,
          payload: usersData
        });
      }
      break;
    }

    default:
      break;
  }
}
