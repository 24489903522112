import {
  FETCHED_CONVERSATION,
  FETCH_CONVERSATION_FAILURE,
  ADD_MESSAGE_SUCCESS,
  ADD_MESSAGE_FAILURE
} from "../Constants/MessagesActionTypes";

export const messagesConversationReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_CONVERSATION:
      return { ...action.payload };
    case FETCH_CONVERSATION_FAILURE:
      return { ...action.payload };
    case ADD_MESSAGE_SUCCESS:
      return { ...action.payload };
    case ADD_MESSAGE_FAILURE:
      return { ...action.payload };
    default:
      return state;
  }
};
