import React from "react";
import { Field } from "redux-form";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
import Email from "@material-ui/icons/Email";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom Components
import SubHeader from "../General/SubHeader";
import GridContainer from "../General/GridContainer";
import FormInputBox from "../General/Form/FormInputBox";

import { required } from "../General/Form/Helpers";

// Style
import { authStyle } from "./AuthStyle";

const ForgotPassword = ({ ...forgotPasswordProps }) => {
  const {
    classes,
    handleForgotPassword,
    resetPasswordState
  } = forgotPasswordProps;

  return (
    <>
      <SubHeader className={classes.authSubHeaderWrapper}>
        <div />
      </SubHeader>
      <GridContainer
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.authWraperHeight}
      >
        <Grid item md={6} xs={12} sm={12}>
          <Card className={classes.authCard}>
            <CardHeader
              title="Forgot Password"
              className={classes.authCardHeader}
            />
            <CardContent className={classes.authCardContent}>
              <form onSubmit={handleForgotPassword}>
                <div className={classes.authFormGroup}>
                  <FormControl component="fieldset">
                    <Field
                      name="userEmail"
                      type="email"
                      component={FormInputBox}
                      label="Enter your  registered Email *"
                      placeholder="Enter your registered Email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email />
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      validate={[required]}
                    />
                  </FormControl>
                </div>
                <div className={classes.authFormGroup}>
                  {resetPasswordState &&
                    resetPasswordState.isForgotPasswordModule &&
                    (resetPasswordState.success ||
                      resetPasswordState.error) && (
                      <Typography
                        variant="subtitle2"
                        className={classes.authCaption}
                      >
                        <div className={classes.authSuccess}>
                          Kindly check your email for a Password reset link
                        </div>
                      </Typography>
                    )}
                </div>
                <div className={classes.authFormGroup}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    disabled={resetPasswordState && resetPasswordState.loading}
                    type="submit"
                    className={classes.margin}
                  >
                    {resetPasswordState &&
                      resetPasswordState.loading === undefined &&
                      "Submit"}
                    {resetPasswordState && resetPasswordState.loading && (
                      <CircularProgress size={26} />
                    )}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </GridContainer>
    </>
  );
};

export default withStyles(authStyle)(ForgotPassword);
