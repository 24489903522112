import { fade } from "@material-ui/core/styles/colorManipulator";

export const searchStyle = theme => ({
  housesSearch: {
    paddingTop: "0px",
    [theme.breakpoints.up("md")]: {
      width: "60%"
    }
  },
  search: {
    position: "relative",
    borderRadius: "25px",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    // marginRight: theme.spacing() * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing() * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: "50px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: "10px",
    paddingRight: "15px",
    paddingBottom: "10px",
    paddingLeft: "45px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    }
  }
});
