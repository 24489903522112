import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

// Styles
import { customTabsStyle } from "./CustomTabsStyle";

const CustomTabs = ({ ...profileTabsProps }) => {
  const { classes, activeTab, profileId, tabItems } = profileTabsProps;

  const tabList = [];
  let activeTabKey = 0;

  tabItems.forEach(tabElement => {
    if (tabElement.rootUri.includes(activeTab)) activeTabKey = tabElement.key;
    tabList.push(
      <Tab
        key={tabElement.key}
        label={tabElement.title}
        component={Link}
        to={`${tabElement.rootUri}${profileId}`}
      />
    );
  });

  return (
    <>
      <Tabs
        value={activeTabKey}
        indicatorColor="primary"
        textColor="primary"
        aria-label="User Profile"
        elevation={0}
        className={classes.profileTabs}
        TabIndicatorProps={{ children: <div /> }}
      >
        {tabList}
      </Tabs>
    </>
  );
};

export default withStyles(customTabsStyle)(CustomTabs);
