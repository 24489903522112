import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_CITIES,
  FETCH_CITY,
  FETCHED_CITIES
} from "../Constants/CitiesActionTypes";

import { citiesEndPoint } from "../ApiClient/City/CitieList";

export function* watchCities() {
  yield takeLatest([FETCH_CITIES, FETCH_CITY], handleCities);
}

export function* handleCities(payload) {
  switch (payload.type) {
    case FETCH_CITIES: {
      const citiesData = yield call(citiesEndPoint, payload.payload);

      yield put({
        type: FETCHED_CITIES,
        payload: citiesData
      });

      break;
    }

    default:
      break;
  }
}
