import React from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Link from "react-router-dom/Link";
import Avatar from "@material-ui/core/Avatar";
import Help from "@material-ui/icons/Help";
import Button from "@material-ui/core/Button";
// import Message from "@material-ui/icons/Message";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";

// Custom Componenets
import { linksStyle } from "./LinksStyle";

const defaultPersonImage = require("../../../Media/images/profile/person.png");

const navLinks = [
  { title: "Event Types", link: "/event-items/" },
  { title: "Event Services", link: "/event-items/event-services" },
  { title: "Service Providers", link: "/event-services/service-providers" }
];

const LeftLinks = ({ ...leftLinksProps }) => {
  const { classes, customHeaderClasses, pageTitle } = leftLinksProps;

  const checkIfBigScreen = useMediaQuery(theme => theme.breakpoints.up("sm"));

  const linkItemsSMUp = [];

  navLinks.forEach(linkElement => {
    const linkClasses = classNames({
      [classes.navLink]: true,
      [classes.navLinkActive]: linkElement.title
        .toUpperCase()
        .includes(pageTitle.toUpperCase())
    });

    linkItemsSMUp.push(
      <Button
        component={Link}
        className={linkClasses}
        to={linkElement.link}
        key={linkElement.title}
      >
        {linkElement.title}
      </Button>
    );
  });

  const linkItemsSMDown =
    pageTitle.includes("Home") || pageTitle.includes("Sign") ? (
      <Link to="/" className={classes.navLink}>
        <div>Evento</div>
      </Link>
    ) : (
      <div className={classes.navLink}>{pageTitle}</div>
    );

  return (
    <List className={`${classes.list} ${customHeaderClasses}`}>
      <ListItem className={classes.listItem}>
        {checkIfBigScreen && linkItemsSMUp}
        {!checkIfBigScreen && linkItemsSMDown}
      </ListItem>
    </List>
  );
};

const RightLinks = ({ ...rightLinksProps }) => {
  const { classes, customHeaderClasses, authState } = rightLinksProps;

  const profileImage =
    authState &&
    authState.userDetails &&
    authState.userDetails.profileImage !== undefined
      ? authState.userDetails.profileImage
      : defaultPersonImage;

  const signInIconOrButton = authState.isSignedIn ? (
    <Link
      to={`/profile/summary/${authState.userDetails.userId}`}
      className={classes.navLink}
      key="profile"
    >
      <Avatar
        src={profileImage}
        className={`${classes.icons} ${classes.personIcon}`}
      />
    </Link>
  ) : (
    <Link to="/sign-in" className={classes.navLink} key="sign-in">
      {"Sign In"}
    </Link>
  );

  const messagesorHelpLink = (
    <ListItem className={classes.listItem}>
      <Link className={classes.navLink} to="/help">
        <Help className={classes.icons} />
      </Link>
    </ListItem>
  );

  return (
    <List className={`${classes.list} ${customHeaderClasses}`}>
      {messagesorHelpLink}
      <ListItem className={classes.listItem}>{signInIconOrButton}</ListItem>
    </List>
  );
};

const HeaderRightLinks = withStyles(linksStyle)(RightLinks);
const HeaderLeftLinks = withStyles(linksStyle)(LeftLinks);

export { HeaderRightLinks, HeaderLeftLinks };
