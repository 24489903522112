/* eslint no-bitwise: ["error", { "allow": ["~"] }] */
/* global atob */
import React from "react";

export const convertNumberToRating = (rating, { ...stars }) => {
  const starValue = [];
  let ratingParts = [];
  const ratingValue = `${rating}`;

  if (ratingValue.includes(".")) {
    ratingParts = ratingValue.split(".");
  } else {
    ratingParts = [ratingValue, 0];
  }

  const intValue0 = parseInt(ratingParts[0], 10);
  const intValue1 = parseInt(ratingParts[1], 10);

  let countStar = 0;

  while (countStar < 5) {
    if (countStar < intValue0) {
      starValue.push(<stars.starFull key={countStar} />);
    } else if (countStar === intValue0 && intValue1 !== 0) {
      starValue.push(<stars.starHalf key={countStar} />);
    } else {
      starValue.push(<stars.starEmpty key={countStar} />);
    }

    countStar += 1;
  }

  return starValue;
};

export const removeElementFromAnArray = (
  arrayToManipulate,
  elementToRemove
) => {
  const elementIndexToRemove = arrayToManipulate.indexOf(elementToRemove);

  if (elementIndexToRemove > -1) {
    arrayToManipulate.splice(elementIndexToRemove, 1);
  }

  return arrayToManipulate;
};

export const parseJwt = token => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const rainbow = (numOfSteps, step) => {
  let r;
  let g;
  let b;
  const h = step / numOfSteps;
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = f;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
    default:
      break;
  }
  const c = `#${`00${(~~(r * 255)).toString(16)}`.slice(-2)}${`00${(~~(
    g * 255
  )).toString(16)}`.slice(-2)}${`00${(~~(b * 255)).toString(16)}`.slice(-2)}`;
  return c;
};
