import { takeLatest, call, put } from "redux-saga/effects";
import {
  FETCH_UOM,
  FETCH_UOMS,
  FETCHED_UOM,
  FETCHED_UOMS,
  FETCH_UOM_FAILURE
} from "../Constants/UOMsActionTypes";
import { UOMsEndPoint } from "../ApiClient/UOM/UOMList";

export function* wacthUOMs() {
  yield takeLatest([FETCH_UOMS, FETCH_UOM], handleUOMs);
}

export function* handleUOMs(payload) {
  switch (payload.type) {
    case FETCH_UOMS: {
      const uoms = yield call(UOMsEndPoint);

      if (uoms && uoms.data) {
        yield put({ type: FETCHED_UOMS, payload: uoms });
      }

      if (uoms && uoms.error) {
        yield put({ type: FETCH_UOM_FAILURE, payload: uoms });
      }
      break;
    }

    case FETCH_UOM: {
      const uomDetail = yield call(UOMsEndPoint, payload.payload);

      if (uomDetail && uomDetail.data) {
        yield put({ type: FETCHED_UOM, payload: uomDetail });
      }

      if (uomDetail && uomDetail.error) {
        yield put({ type: FETCH_UOM_FAILURE, payload: uomDetail });
      }
      break;
    }

    default:
      break;
  }
}
