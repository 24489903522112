import React, { Component } from "react";
import { reduxForm } from "redux-form";

// custom components
import Search from "../../Components/Search";

class SearchContainer extends Component {
  constructor() {
    super();
    this.handleSearchFieldChange = this.handleSearchFieldChange.bind(this);
  }

  handleSearchFieldChange = (event, newValue, previousValue, name) => {
    console.log(event, newValue, previousValue, name);
  };

  componentDidMount = () => {
    // console.log("search Mounted");
  };

  render() {
    return <Search handleKeyUp={this.handleSearchFieldChange} />;
  }
}

const SearchFormed = reduxForm()(SearchContainer);
export default SearchFormed;
