import { ApiClientEndPointMethod } from "../EndPoint";
import { SERVICE_PROVIDERS_URL } from "../EndPoint/ConstantVariables";

export const citiesEndPoint = payload => {
  let citiesUri = "cities";

  citiesUri =
    payload && payload.cityId === undefined
      ? `${citiesUri}/Country/${payload.targetCountryId}`
      : `${citiesUri}/${payload.cityId}`;

  const cities = ApiClientEndPointMethod({
    url: SERVICE_PROVIDERS_URL + citiesUri
  });

  try {
    return cities.then(citiesData => {
      if (citiesData && citiesData.cities) {
        return {
          data: citiesData.cities.map(
            ({ cityId, cityName, cityDescription }) => ({
              cityId,
              cityName,
              cityDescription
            })
          ),
          createEnabled: citiesData.createEnabled
        };
      }
      if (citiesData && citiesData.cityDetails) {
        return {
          data: { ...citiesData.cityDetails }
        };
      }

      return { error: "Error Fetching Data" };
    });
  } catch (error) {
    return { error: error.message };
  }
};
