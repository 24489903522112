import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

// Custom Components
import EventItem from "../EventItem";
import GridContainer from "../../General/GridContainer";

// style
import { eventItemListStyle } from "./EventItemListStyle";

const EventItemList = ({ ...eventItemListProps }) => {
  const { classes, eventItemsListData } = eventItemListProps;

  const eventItemList = [];

  eventItemsListData.forEach(element => {
    eventItemList.push(
      <Grid
        item
        md={3}
        xs={6}
        sm={3}
        key={element.id}
        className={classes.gridItem}
      >
        <EventItem
          eventId={element.id}
          eventName={element.eventName}
          eventDescription={element.eventDescription}
          eventItemServices={element.eventItemServices}
          eventImage={element.eventImage}
        />
      </Grid>
    );
  });

  return (
    <React.Fragment>
      <GridContainer
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {eventItemList}
      </GridContainer>
    </React.Fragment>
  );
};

export default withStyles(eventItemListStyle)(EventItemList);
