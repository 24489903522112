import {
  SUBMIT_SIGN_IN,
  SUBMIT_SIGN_UP,
  CONFIRM_EMAIL,
  CLEAR_SIGNED_IN_PROFILE,
  REFRESH_SIGN_IN,
  RESET_PASSWORD,
  UPDATE_PASSWORD
} from "../Constants/AuthActionTypes";

export const submitSignIn = payload => {
  return {
    type: SUBMIT_SIGN_IN,
    payload
  };
};

export const submitSignUp = payload => {
  return {
    type: SUBMIT_SIGN_UP,
    payload
  };
};

export const confirmEmail = payload => {
  return {
    type: CONFIRM_EMAIL,
    payload
  };
};

export const refreshSignIn = payload => {
  return {
    type: REFRESH_SIGN_IN,
    payload
  };
};

export const clearSignIn = payload => {
  return {
    type: CLEAR_SIGNED_IN_PROFILE,
    payload
  };
};

export const resetPassword = payload => {
  return {
    type: RESET_PASSWORD,
    payload
  };
};

export const updatePassword = payload => {
  return {
    type: UPDATE_PASSWORD,
    payload
  };
};
