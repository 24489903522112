import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import CompareArrow from "@material-ui/icons/Compare";
import classNames from "classnames";
import { Link } from "react-router-dom";

// Componets
import FabButton from "../General/FabButton";
import GridContainer from "../General/GridContainer";
import ServiceServiceProvidersSubHeader from "./ServiceServiceProvidersSubHeader";
import EventService from "../EventServices/EventService";
import ServiceProvidersList from "../ServiceProviders/ServiceProvidersList";
import ServiceProviderMiniDetail from "../ServiceProviders/ServiceProviderMiniDetail";
import ServiceProvidersCompare from "../ServiceProviders/ServiceProvidersCompare";

// Style
import { serviceServiceProviderStyle } from "./ServiceServiceProvidersStyle";

const ServiceServiceProvider = ({ ...serviceServiceProviderProps }) => {
  const {
    classes,
    serviceProviders,
    showEventService,
    eventServices,
    handleClickNext,
    currentTargetIndex,
    buttonText,
    serviceProvidersCompare,
    serviceProvidersFavorite,
    handleAddToCompare,
    handleAddToFavorite,
    servicesCount,
    SelectElement,
    checkedCompareSPs,
    checkedFavoriteSPs,
    handleModal,
    compareModalOpen
  } = serviceServiceProviderProps;

  const addToCompareContentClasses = classNames({
    [classes.addToCompareContent]:
      serviceProvidersCompare !== undefined &&
      serviceProvidersCompare.length > 0,
    [classes.gridItemCustomStyle]: true
  });
  const addToCompareHeaderClasses = classNames({
    [classes.addToCompareHeader]: true,
    [classes.gridItemCustomStyle]: true
  });

  // Favorite
  const favSP =
    showEventService &&
    serviceProvidersFavorite &&
    serviceProvidersFavorite.length > 0 ? (
      <>
        <ServiceProviderMiniDetail {...serviceProvidersFavorite[0]} />
        <Typography
          variant="subtitle1"
          gutterBottom
          align="left"
          className={classes.selectedHeader}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            to={`/service-providers/summary/${
              serviceProvidersFavorite[0].serviceProviderID
            }`}
          >
            View Selected
          </Button>
        </Typography>
      </>
    ) : (
      <Typography
        variant="subtitle1"
        gutterBottom
        align="left"
        className={classes.selectedHeader}
      >
        Selected goes here
      </Typography>
    );

  // Compare
  const compareSPs =
    showEventService &&
    serviceProvidersCompare &&
    serviceProvidersCompare.length > 0 ? (
      <>
        <ServiceProvidersCompare
          serviceProvidersCompare={serviceProvidersCompare}
          handleModal={handleModal}
          compareModalOpen={compareModalOpen}
        />
        <div className={addToCompareHeaderClasses}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleModal}
          >
            <CompareArrow /> Compare
          </Button>
        </div>
      </>
    ) : (
      <Typography
        variant="subtitle1"
        gutterBottom
        align="left"
        className={classes.selectedHeader}
      >
        Added to compare goes here
      </Typography>
    );

  const minSPsMediaWidth = showEventService ? 7 : 12;

  return (
    <>
      {showEventService && (
        <ServiceServiceProvidersSubHeader
          serviceName={eventServices.eventServiceName}
          serviceImage={eventServices.eventServiceImage}
        />
      )}

      <GridContainer justify="flex-start" alignItems="flex-start">
        {showEventService && (
          <Grid item xs={12} sm={5} md={5} style={{ paddingTop: "10px" }}>
            <EventService
              serviceID={eventServices.id}
              serviceName={eventServices.eventServiceName}
              serviceImage={eventServices.eventServiceImage}
              serviceColor={eventServices.eventServiceColor}
              serviceProvidersCount={eventServices.serviceProvidersCount}
              showDescription
              serviceDescription={eventServices.eventServiceDescription}
            />

            <GridContainer
              justify="flex-start"
              alignItems="flex-start"
              direction="row"
            >
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                className={classes.gridItemCustomStyle}
              >
                {favSP}
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                md={8}
                className={addToCompareContentClasses}
              >
                {compareSPs}
              </Grid>
            </GridContainer>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={minSPsMediaWidth}
          md={minSPsMediaWidth}
          className={classes.housesServiceProvidersList}
        >
          <ServiceProvidersList
            serviceProviders={serviceProviders}
            handleAddToCompare={handleAddToCompare}
            handleAddToFavorite={handleAddToFavorite}
            SelectElement={SelectElement}
            checkedCompareSPs={checkedCompareSPs}
            checkedFavoriteSPs={checkedFavoriteSPs}
            showEventService={showEventService}
          />
        </Grid>
      </GridContainer>
      {servicesCount > 1 && (
        <FabButton
          buttonText={buttonText}
          hasClick
          clickFunction={handleClickNext}
          clickParams={currentTargetIndex}
        />
      )}
    </>
  );
};

export default withStyles(serviceServiceProviderStyle)(ServiceServiceProvider);
