import React from "react";
import Grid from "@material-ui/core/Grid";
// import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Components
import Parallax from "../General/Parallax";
import GridContainer from "../General/GridContainer";
// import LandingPageFavorites from "./LandingPageFavorites";

// Style
import { landingPageStyle } from "./LandingPageStyle";

const parallaxImage = require("../../Media/images/concertEvent.jpg");

const LandingPage = ({ ...landingPageProps }) => {
  const { classes, eventListComponent } = landingPageProps;

  return (
    <React.Fragment>
      <Parallax
        filter
        image={parallaxImage}
        style={{
          position: "relative",
          overflow: "hidden"
        }}
      >
        <div className={classes.parallaxContainer}>
          <GridContainer direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12}>
              <section>
                <section className="tag-line">Towards a perfect event</section>
              </section>
            </Grid>
          </GridContainer>
        </div>
      </Parallax>
      {/* <Hidden smDown implementation="css">
        <LandingPageFavorites className={classes.landingPageFavorites} />
      </Hidden> */}

      <div className={classes.eventlistWrapper}>{eventListComponent}</div>
    </React.Fragment>
  );
};

export default withStyles(landingPageStyle)(LandingPage);
