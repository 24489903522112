import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import classNames from "classnames";

// styles
import { messageListStyle } from "./MessageListStyle";

// Consts
const defaultPersonImage = require("../../../Media/images/profile/person.png");

const MessageList = ({ ...messageListProps }) => {
  const {
    classes,
    activeConvoId,
    targetMessageUriPrefix,
    messagesSummary
  } = messageListProps;

  const messageList = [];

  messagesSummary.forEach(messageElement => {
    const secondaryText = (
      <>
        <Typography
          component="span"
          variant="body2"
          className={classes.inline}
          color="textPrimary"
        >
          {messageElement.messageDetail[0].RecipientName}
        </Typography>
        {messageElement.messageDetail[0].MessageContent}
      </>
    );

    const activeMessageClasses = classNames({
      [classes.activemessage]:
        `${messageElement.messageDetail[0].Receiver}` === activeConvoId
    });

    messageList.push(
      <ListItem
        alignItems="flex-start"
        key={`${messageElement.Receiver} ${
          messageElement.messageDetail[0].Sender
        }`}
        component={Link}
        to={`${targetMessageUriPrefix}/${
          messageElement.messageDetail[0].Receiver
        }`}
        className={activeMessageClasses}
      >
        <ListItemAvatar>
          <Avatar
            alt={messageElement.messageDetail[0].RecipientName}
            src={defaultPersonImage}
          />
        </ListItemAvatar>
        <ListItemText
          primary={messageElement.messageDetail[0].MessageTitle}
          secondary={secondaryText}
          className={classes.listText}
        />
      </ListItem>
    );
  });

  return (
    <Grid item md={4} sm={4} xs={12}>
      <Card className={classes.cardWrapper}>
        <CardContent className={classes.cardbody}>
          <List className={classes.root}>{messageList}</List>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withStyles(messageListStyle)(MessageList);
