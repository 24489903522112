import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import ServiceProviderMiniDetail from "../ServiceProviderMiniDetail";
import GridContainer from "../../General/GridContainer";

import { serviceProvidersListStyle } from "./ServiceProvidersListStyle";

const defaultBackgroundImage = require("../../../Media/images/paella.jpg");

const ServiceProvidersList = ({ ...serviceProvidersListProps }) => {
  const {
    classes,
    serviceProviders,
    handleAddToCompare,
    handleAddToFavorite,
    SelectElement,
    checkedCompareSPs,
    checkedFavoriteSPs,
    showEventService
  } = serviceProvidersListProps;

  const serviceProviderList = [];

  const contentOptions = {
    descriptionLength: 50,
    selected: false,
    addedToCompare: false,
    showReviews: false
  };

  const spMinWidth = showEventService ? 4 : 3;

  if (serviceProviders && serviceProviders.data) {
    serviceProviders.data.forEach(listElement => {
      const itemKey = showEventService
        ? listElement.serviceProviderId
        : `${listElement.serviceProviderId} - ${listElement.serviceId}`;
      serviceProviderList.push(
        <Grid
          key={itemKey}
          item
          xs={6}
          sm={spMinWidth}
          md={spMinWidth}
          className={classes.gridItemCustomStyle}
        >
          <ServiceProviderMiniDetail
            contentOptions={contentOptions}
            serviceProviderImage=""
            serviceProviderName={listElement.serviceProviderName}
            serviceProviderDescription={listElement.serviceProviderDescription}
            serviceProviderRating={3}
            serviceProviderReviews={3}
            serviceProviderBackroundImage={defaultBackgroundImage}
            handleAddToCompare={handleAddToCompare}
            handleAddToFavorite={handleAddToFavorite}
            serviceProviderID={listElement.serviceProviderId}
            SelectElement={SelectElement}
            checkedCompareSPs={checkedCompareSPs}
            checkedFavoriteSPs={checkedFavoriteSPs}
            showEventService={showEventService}
          />
        </Grid>
      );
    });
  } else {
    serviceProviderList.push(
      <Grid
        key="none"
        item
        xs={12}
        sm={12}
        md={12}
        className={classes.gridItemCustomStyle}
      >
        {serviceProviders.error}
      </Grid>
    );
  }

  return (
    <>
      <GridContainer justify="flex-start" alignItems="center">
        {serviceProviderList}
      </GridContainer>
    </>
  );
};

export default withStyles(serviceProvidersListStyle)(ServiceProvidersList);
