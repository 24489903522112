import { call, put, takeLatest } from "redux-saga/effects";

// Action Types
import {
  FETCH_MESSAGES,
  FETCH_MESSAGE_FAILURE,
  FETCHED_MESSAGES
} from "../Constants/MessagesActionTypes";

// Endpoint
import { messageSummaryListEndpoint } from "../ApiClient/Messages/MessagesSummaryList";

export function* watchMessagesSummary() {
  yield takeLatest([FETCH_MESSAGES], handleMessagesSummary);
}

export function* handleMessagesSummary(payload) {
  switch (payload.type) {
    case FETCH_MESSAGES: {
      const messagesSummaryList = yield call(
        messageSummaryListEndpoint,
        payload.payload
      );

      if (messagesSummaryList && messagesSummaryList.data) {
        yield put({
          type: FETCHED_MESSAGES,
          payload: messagesSummaryList
        });
      } else {
        yield put({
          type: FETCH_MESSAGE_FAILURE,
          payload: messagesSummaryList
        });
      }

      break;
    }

    default:
      break;
  }
}
