export const conatinerFluid = {
  marginRight: "auto",
  marginLeft: "auto",
  padding: "0px 10px",
  width: "100%"
};
export const container = {
  ...conatinerFluid,
  "@media (min-width: 576px)": {
    maxWidth: "540px"
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px"
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px"
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px"
  }
};
export const contentUtilities = {
  contentLabel: {
    textAlign: "left",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    padding: "10px 2px",
    color: "#000000"
  },
  contentContent: {
    textAlign: "left",
    fontSize: "14px",
    padding: "10px 2px",
    color: "#00000099"
  }
};

export const mainContent = {
  subHeaderContentWrapper: {
    paddingLeft: "25px"
  },
  mainConentWrapper: {
    paddingLeft: "30px"
  }
};

export const formUtilities = {
  formGroup: {
    padding: "10px 0px",
    color: "#222",
    "&>fieldset": {
      width: "80%"
    }
  },
  houseCheckBox: {
    lineHeight: "1.42857",
    color: "#AAAAAA",
    fontWeight: "400",
    paddingTop: "4px",
    cursor: "pointer",
    alignItems: "center",
    "-webkit-tap-highlight-color": "transparent"
  },
  checkboxLabel: {
    color: "#000000c4",
    cursor: "pointer",
    verticalAlign: "middle",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    paddingLeft: "0px",
    paddingTop: "5px"
  },
  radioGroup: {
    paddingLeft: "5px",
    "& svg": {
      fontSize: "30px",
      width: "0.8em",
      height: "0.8em"
    }
  },
  menu: {
    width: "200px",
    border: "10px solid yellow"
  }
};
