import {
  RESETTING_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS
} from "../Constants/AuthActionTypes";

export const resetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case RESETTING_PASSWORD:
      return { loading: true };
    case RESET_PASSWORD_FAILED:
      return { error: true };
    case RESET_PASSWORD_SUCCESS:
      return { success: true };
    default:
      return state;
  }
};
