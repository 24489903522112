import React from "react";
import { Field } from "redux-form";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import GridContainer from "../../General/GridContainer";
import SubHeader from "../../General/SubHeader";
import FormCheckBox from "../../General/Form/FormCheckBox";

// Helpers
import { required } from "../../General/Form/Helpers";

// Styles
import { profileSettingsStyle } from "./ProfileSettingsStyle";

const ProfileSettings = ({ ...profileSettingsProps }) => {
  const { classes, handleUpdateSettings, handleLogout } = profileSettingsProps;

  return (
    <Card className={classes.cardWrapper}>
      <CardContent>
        <SubHeader>
          <GridContainer
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.subHeaderContentWrapper}
          >
            <Grid item md={8} sm={8} xs={12}>
              <Typography gutterBottom variant="h6">
                Update Settings
              </Typography>
            </Grid>
          </GridContainer>
        </SubHeader>

        <GridContainer
          direction="row"
          ustify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={8} sm={8} md={8}>
            <div>
              <form onSubmit={handleUpdateSettings}>
                <div className={classes.authFormGroup}>
                  <span className={classes.authHouseCheckBox}>
                    <Field
                      name="ReceiveMail"
                      component={FormCheckBox}
                      label={<>Recieve Email Notifications</>}
                      disabled
                      isChecked
                      validate={[required]}
                    />
                  </span>
                </div>
                <div className={classes.authFormGroup}>
                  <span className={classes.authHouseCheckBox}>
                    <Field
                      name="DonotshareProfile"
                      component={FormCheckBox}
                      label={<>Do not share my profile</>}
                      validate={[required]}
                      disabled
                      isChecked
                    />
                  </span>
                </div>
                <div className={classes.formGroup}>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    disabled
                    type="submit"
                    className={classes.margin}
                  >
                    Update Settings
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <div className={classes.authFormGroup}>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                onClick={() => handleLogout()}
                className={classes.margin}
              >
                Log me out
              </Button>
            </div>
          </Grid>
        </GridContainer>
      </CardContent>
    </Card>
  );
};

export default withStyles(profileSettingsStyle)(ProfileSettings);
