import {
  FETCHED_USER,
  FETCHED_USERS
} from "../Constants/UserProfileActionTypes";

export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_USER:
      return {
        ...action.payload
      };

    case FETCHED_USERS:
      return {
        ...action.payload
      };

    default:
      return state;
  }
};
