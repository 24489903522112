import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Components
import GridContainer from "../../General/GridContainer";
import EventService from "../EventService";
import FabButton from "../../General/FabButton";
import EventItemServicesSubheader from "./EventItemServicesSubheader";
// Style
import { eventItemServicesStyle } from "./EventItemServicesStyle";

const EventItemServices = ({ ...eventItemServicesProps }) => {
  const {
    classes,
    eventItem,
    eventServices,
    searchComponent,
    SelectElement,
    handleSelectElement,
    selectedEventServices,
    selectedServicesUri,
    showSubHeader
  } = eventItemServicesProps;

  const eventServicesList = [];

  const fabButtonText =
    selectedEventServices.length === 1
      ? "Service Providers for Selected Service"
      : `Service Providers for ${
          selectedEventServices.length
        } Selected Services`;

  eventServices.forEach(element => {
    eventServicesList.push(
      <Grid
        item
        md={3}
        sm={4}
        xs={6}
        key={`${element.id} ${eventItem.id}`}
        className={classes.serviceItemWrapper}
      >
        <EventService
          serviceID={element.id}
          eventItemId={eventItem.id}
          serviceName={element.eventServiceName}
          serviceImage={element.eventServiceImage}
          serviceColor={element.eventServiceColor}
          serviceProvidersCount={element.serviceProvidersCount}
          SelectElement={SelectElement}
          handleSelectElement={handleSelectElement}
          selectedEventServices={selectedEventServices}
        />
      </Grid>
    );
  });

  return (
    <>
      {showSubHeader && (
        <EventItemServicesSubheader
          eventName={eventItem.eventName}
          eventImage={eventItem.eventImage}
          searchComponent={searchComponent}
        />
      )}
      <GridContainer
        justify="flex-start"
        alignItems="flex-start"
        className={classes.serviceListWrapper}
      >
        {eventServicesList}
      </GridContainer>
      {selectedEventServices.length > 0 && (
        <>
          <FabButton
            buttonText={fabButtonText}
            uriParam={selectedServicesUri}
          />
        </>
      )}
    </>
  );
};

export default withStyles(eventItemServicesStyle)(EventItemServices);
