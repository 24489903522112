import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Custom Components
import EventItemList from "../../Components/EventItems/EventItemList";
import Loader from "../../Components/General/Loader";

// Actions
import { fetchEventItems } from "../../Actions/EventsActions";

class EventItemsListContainer extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(fetchEventItems());
  };

  render() {
    const { eventsItems } = this.props;

    return (
      <React.Fragment>
        {eventsItems && eventsItems.data && (
          <EventItemList eventItemsListData={eventsItems.data} />
        )}
        {eventsItems && !eventsItems.data && <Loader />}
      </React.Fragment>
    );
  }
}

EventItemsListContainer.propTypes = {
  eventsItems: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({ eventsState }) => ({
  eventsItems: eventsState
});

export default connect(mapStateToProps)(EventItemsListContainer);
