import {
  FETCHED_MESSAGES,
  FETCH_MESSAGE_FAILURE
} from "../Constants/MessagesActionTypes";

export const messagesSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHED_MESSAGES:
      return { ...action.payload };
    case FETCH_MESSAGE_FAILURE:
      return { ...action.payload };
    default:
      return state;
  }
};
