import React from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from "@material-ui/core/Fab";

// Custom components
import { fabButtonStyle } from "./FabButtonStyle";

const FabButton = ({ ...fabButtonProps }) => {
  const {
    classes,
    uriParam,
    buttonText,
    hasClick,
    clickFunction,
    clickParams
  } = fabButtonProps;

  const uriObject =
    uriParam === undefined
      ? {}
      : {
          component: Link,
          to: uriParam
        };

  const clickObject = hasClick
    ? { onClick: () => clickFunction(clickParams) }
    : {};

  return (
    <div className={classes.buttonWrapper}>
      <Fab
        variant="extended"
        size="medium"
        aria-label="Delete"
        focusRipple={false}
        className={classes.button}
        {...uriObject}
        {...clickObject}
      >
        {buttonText}
      </Fab>
    </div>
  );
};

export default withStyles(fabButtonStyle)(FabButton);
