import { ApiClientEndPointMethod } from "../EndPoint";
import { MESSAGES_URL } from "../EndPoint/ConstantVariables";

export const messagesConversitionListEndPoint = payload => {
  if (payload !== undefined) {
    const messageConvoListUrl = `${MESSAGES_URL}Messages/UserConversation/${
      payload.targetUserId
    }/${payload.targetConverseeId}`;

    const convoList = ApiClientEndPointMethod({
      url: messageConvoListUrl,
      authorize: true
    });

    try {
      return convoList.then(convoListData => {
        if (convoListData && convoListData.messages) {
          return {
            data: convoListData.messages.map(
              ({ messageId, message, senderId, recipientId }) => {
                return {
                  messageId,
                  message,
                  senderId,
                  recipientId
                };
              }
            )
          };
        }

        return { error: "Error Fetching Data" };
      });
    } catch (error) {
      return { error: error.message };
    }
  } else {
    return { error: "Error Fetching Message Conversation" };
  }
};
