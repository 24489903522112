import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import { parallaxStyle } from "./ParallaxStyle";

const Parallax = ({ ...parallaxProps }) => {
  const { classes, image, style, children } = parallaxProps;

  return (
    <div
      style={{
        ...style
      }}
    >
      <img src={image} className={classes.parallaxImage} alt="parallaxImage" />
      <section style={{ position: "relative" }}>
        <section className={classes.small}>{children}</section>
      </section>
    </div>
  );
};

export default withStyles(parallaxStyle)(Parallax);
