import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    },
    fontFamily: '"Google Sans",Roboto,Arial,Helvetica,sans-serif'
  },
  palette: {
    primary: {
      light: "#271c86",
      main: "#150b6a",
      dark: "#0f0937",
      white: "#bebbd6",
      whiteActive: "#f6f6f6"
    },
    background: {
      default: "#f6f6f6"
    }
  },
  shape: {
    borderRadius: 8
  }
});

export const appTheme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#150b6a"
      }
    },
    MuiCard: {
      root: {
        borderRadius: "4px",
        boxShadow: "none",
        backgroundColor: "#ffffff"
      }
    },
    MuiButton: {
      label: {
        textTransform: "initial"
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none"
        }
      },
      root: {
        borderRadius: "4px"
      }
    },
    MuiTabs: {
      indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > div": {
          maxWidth: 40,
          width: "100%",
          backgroundColor: theme.palette.primary.main
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: "15px",
        marginRight: theme.spacing(0),
        "&:focus": {
          opacity: 1
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: "#7f7f86"
      }
    },
    // MuiTabs: {
    //   root: {
    //     width: "100%"
    //   },
    //   indicator: {
    //     display: "none"
    //   },
    //   centered: {
    //     alignItems: "center",
    //     justifyContent: "center"
    //   }
    // },
    // MuiTab: {
    //   root: {
    //     position: "relative",
    //     display: "block",
    //     borderRadius: "30px",
    //     textAlign: "center",
    //     transition: "all .5s",
    //     padding: "10px 15px",
    //     color: "#555555",
    //     minHeight: "none",
    //     opacity: "1",
    //     margin: "10px 0",
    //     float: "none",
    //     width: "auto",
    //     height: "auto",
    //     textTransform: "capitalize",
    //     fotnSize: "15px",
    //     fontWeight: "600",
    //     "& + button": {
    //       margin: "10px 0"
    //     }
    //   },
    //   selected: {
    //     color: theme.palette.primary.main,
    //     backgroundColor: "transparent",
    //     border: `1px solid ${theme.palette.primary.main}`,
    //     "& span": {
    //       color: theme.palette.primary.main
    //     }
    //   }
    // },

    MuiIconButton: {
      root: {
        padding: theme.spacing()
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};
